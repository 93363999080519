import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import RestManager from '@util/RestManager';
import { detect } from 'detect-browser';

const browser = detect();

/**
 * @description Action cell for edit and delete functionality
 *
 * @param {string} getCsvUrl
 * @param {string} intl
 * @param {number} timeout
 *
 * @returns {ReactComponent|null}
 */
const ExportCsv = ({ getCsvUrl, intl, timeout }) => {
  let url;

  try {
    url = new URL(getCsvUrl, window.location.origin);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('"Invalid CSV export URL: ', err);
    NotificationManager.error(
      err.message,
      timeout * 1000,
    );
    return null;
  }

  /**
   * @description Exports CSV File && Shows Notifications
   * @param {Object} event
   * @returns {void}
   */
  const clickHandler = (event) => {
    event.preventDefault();
    RestManager.exportCsvFile(getCsvUrl, 'GET', [], true, intl);
  };

  return (
    <Link
      className="page-icons page-icon-export"
      to={url}
      shape="circle"
      download
      target="_blank"
      onClick={clickHandler}
      title={intl.formatMessage({ id: 'common.export' })}
      longdesc={intl.formatMessage({ id: 'common.exportInfo' })}
    >

      <DownloadOutlined />
      {intl.formatMessage({ id: 'common.export' })}
      <NotificationContainer />
    </Link>
  );
};

ExportCsv.defaultProps = {
  timeout: 10,
};

ExportCsv.propTypes = {
  getCsvUrl: PropTypes.string.isRequired,
  intl: PropTypes.shape().isRequired,
  timeout: PropTypes.number,
};

export default injectIntl(ExportCsv);
