import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card, Switch, Form, Button, Popover, Modal,
} from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  fetchDeviceAction,
  saveUheBedCart,
  fetchCallAction,
  fetchCommandsStatus,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import {
  soft_reboot,
  hard_reboot,
  factory_reset,
  hang_up_call,
  alert,
  reboot_os,
} from '@constants/UHEDeviceActions';
import {
  HARD_REBOOT,
  SOFT_REBOOT,
  REBOOT,
  REBOOT_OS
} from '@constants/UHESettings';
import { shouldRenderTeamviewerCredentials, canDoAction } from '@util/UheRoleChecker';

const { confirm } = Modal;

/**
 * Renders Action Card in ManageUheBedCart file
 * @returns {void}
 */
class ActionsCard extends React.PureComponent {
  /**
   * ActionsCard Component Constructor
   * @param {object} props Programs Component Props
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      command: '',
    };

    this.sendDeviceCommand = this.sendDeviceCommand.bind(this);
    this.call = this.call.bind(this);
  }

  /**
  * Confirms Action command
  * @returns {void}
  */
  confirmAction = () => {
    this.sendDeviceCommand();

    this.setState({
      command: '',
    });
  };

  /**
  * Clears State when Action Modal is Closed
  * @returns {void}
  */
  resetCommandState = () => {
    this.setState({
      command: '',
    });
  };

  /**
   * Show Confirmation Modal When Action Button Being Clicked
   * @param {string} commandToSend string
   * @returns {void}
   */
  showConfirm = (commandToSend) => {
    const { intl } = this.props;
    this.setState({
      command: commandToSend,
    });
    confirm({
      title: intl.formatMessage({ id: `configuration.technician.${commandToSend}` }),
      onOk: this.confirmAction,
      onCancel: this.resetCommandState,
      autoFocusButton: 'cancel',
    });
  }

  /**
  * Sends Device command and may turn off TeamViewer
  * * @param {string} bedCart.machine_name
    * @param {string} command string
    * @param {object} params_p object
  * @returns {void}
  */
  sendDeviceCommand() {
    const { command } = this.state;
    const {
      bedCart, doFetchDeviceAction, match, saveUheBedCartEdit, doFetchCommandsStatus,
    } = this.props;

    let newCommand;
    if (command === HARD_REBOOT) {
      newCommand = REBOOT;
    } else {
      newCommand = command;
    }
    switch (command) {
      case soft_reboot:
      case reboot_os:
      case hard_reboot:
        const fetchCommands = setInterval(() => {
          const { commandsStatus } = this.props;
          doFetchCommandsStatus(match.params.id);
          if (commandsStatus[newCommand] === 'success' || commandsStatus[newCommand] === 'fail' || commandsStatus[newCommand] === 'incall') {
            clearInterval(fetchCommands);
          }
        }, 15000);
        break;
      case factory_reset:
        break;
      default:
        break;
    }

    doFetchDeviceAction({
      command,
      id: match.params.id,
    });
    if (command === 'factory_reset') {
      saveUheBedCartEdit({
        id: match.params.id,
        bedCart,
      });
    }
  }

  /**
    * Sends Call Room command
    * @param {string} bedCart.machine_name string
    * @returns {void}
  */
  call() {
    const {
      bedCart, fetchCallAction, match,
    } = this.props;

    fetchCallAction({
      machine_name: bedCart.machine_name, id: match.params.id,
    });
  }

  /**
 * Renders ActionsCard Component
 * @returns {JSX} ActionsCard Component
 */
  render() {
    const {
      bedCart, intl, onChangeAction, loading, loggedUser, canHardReboot,
    } = this.props;
    return (
      <div>
        <Card
          loading={loading}
          title={<IntlMessages id="configuration.bedsCarts.actions.title" />}
          className="gx-card"
        >
          <Form.Item
            className="gx-align-items-center"
            name="factoryReset"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.callRoom" />
            }
          >
            <Popover content={<IntlMessages id="configuration.bedsCarts.actions.callRoom" />}>
              <Button onClick={this.call} disabled={bedCart.under_maintenance === 1} className="tech-button call-room-btn">
                <PhoneOutlined className="call-room-icon" />
              </Button>
            </Popover>
          </Form.Item>
          <Form.Item
            className="gx-align-items-center"
            name="hangUpCall"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.hangUp" />
            }
          >
            <Popover content={<IntlMessages id="configuration.bedsCarts.actions.hangUp" />}>
              <Button onClick={() => this.showConfirm(hang_up_call)} className="tech-button">
                <IntlMessages id="configuration.bedsCarts.actions.endCall" />
              </Button>
            </Popover>
          </Form.Item>
          <Form.Item
            className="gx-align-items-center"
            name="factoryReset"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.testAlert" />
            }
          >
            <Popover content={<IntlMessages id="configuration.bedsCarts.actions.alert" />}>
              <Button onClick={() => this.showConfirm(alert)} className="tech-button">
                <IntlMessages id="configuration.bedsCarts.actions.alert" />
              </Button>
            </Popover>
          </Form.Item>
          <Form.Item
            className="gx-align-items-center"
            name="softReboot"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.softReboot" />
            }
          >
            <Popover content={<IntlMessages id="configuration.bedsCarts.actions.softRebootDescription" />}>
              <Button onClick={() => this.showConfirm(soft_reboot)} className="tech-button">
                <IntlMessages id="configuration.bedsCarts.actions.reboot" />
              </Button>
            </Popover>
          </Form.Item>
          <Form.Item
            className="gx-align-items-center"
            name="restartOs"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.restartOs" />
            }
          >
            <Popover content={<IntlMessages id="configuration.bedsCarts.actions.restartOsDescription" />}>
              <Button onClick={() => this.showConfirm(reboot_os)} className="tech-button">
                <IntlMessages id="configuration.bedsCarts.actions.reboot" />
              </Button>
            </Popover>
          </Form.Item>
          <Form.Item
            className="gx-align-items-center"
            name="hardReboot"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.hardReboot" />
            }
          >
            <Popover content={<IntlMessages id="configuration.bedsCarts.actions.hardRebootDescription" />}>
              <Button disabled={canDoAction(loggedUser) || canHardReboot} onClick={() => this.showConfirm(hard_reboot)} className="tech-button">
                <IntlMessages id="configuration.bedsCarts.actions.reboot" />
              </Button>
            </Popover>
          </Form.Item>
          <Form.Item
            className="gx-align-items-center"
            name="factoryReset"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.factoryReset" />
            }
          >
            <Popover content={<IntlMessages id="configuration.bedsCarts.actions.factoryResetDescription" />}>
              <Button disabled={canDoAction(loggedUser)} onClick={() => this.showConfirm(factory_reset)} className="tech-button">
                <IntlMessages id="configuration.bedsCarts.actions.reset" />
              </Button>
            </Popover>
          </Form.Item>
          <Form.Item
            className="gx-align-items-center"
            name="teamViewer"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.teamviewer" />
            }
          >
            <Switch
              onChange={(e) => {
                onChangeAction(e, 'teamviewer_on');
              }}
              checked={!!bedCart.teamviewer_on}
              longdesc={intl.formatMessage({
                id: 'configuration.bedsCarts.descriptions.teamViewer',
              })}
            />
          </Form.Item>
          {bedCart.teamviewer_on
            ? (
              <div className="teamviewer-credentials">
                {shouldRenderTeamviewerCredentials(loggedUser)
                  && <p>{`${bedCart.teamviewer_id}  ${bedCart.machine_name}`}</p>}
              </div>
            ) : ''}
          <Form.Item
            className="gx-align-items-center"
            name="iobserver"
            label={
              <IntlMessages id="configuration.bedsCarts.actions.iobserver" />
            }
          >
            <Switch
              onChange={(e) => {
                onChangeAction(e, 'esitter_on');
              }}
              checked={!!bedCart.esitter_on}
              longdesc={(
                <IntlMessages
                  id="configuration.bedsCarts.descriptions.iobserver"
                />
              )}
            />
          </Form.Item>
        </Card>
      </div>
    );
  }
}

ActionsCard.defaultProps = {
  callData: {},
  canHardReboot: () => false,
};

ActionsCard.propTypes = {
  callData: PropTypes.object,
  bedCart: PropTypes.object,
  doFetchDeviceAction: PropTypes.func,
  saveUheBedCartEdit: PropTypes.func,
  fetchCallAction: PropTypes.func,
  onChangeAction: PropTypes.func,
  match: PropTypes.object,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  loggedUser: PropTypes.object,
  canHardReboot: PropTypes.func,
};

/**
 * Maps State to Props
 * @returns {object} Object with States
 */
const mapStateToProps = ({
  bedsCarts,
}) => ({
  callData: bedsCarts.callInfo,
  commandsStatus: bedsCarts.commandsStatus,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  doFetchDeviceAction:
  (command, machine, param)=> dispatch(fetchDeviceAction(command, machine, param)),
  saveUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
  fetchCallAction: (data) => dispatch(fetchCallAction(data)),
  doFetchCommandsStatus: (id) => dispatch(fetchCommandsStatus(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(ActionsCard)));
