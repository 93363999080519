import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Popconfirm,
  Modal,
  notification,
  Radio,
  Popover,
  Input,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import lodash from 'lodash';
import moment from 'moment-timezone';

import IntlMessages from 'util/IntlMessages';
import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';

import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import UheUnitCell from '@components/tables/cells/UheUnitCell';
import CommandStatusCell from '@components/tables/cells/CommandStatusCell';
import EditHeader from '@components/uhe/configuration/EditHeader';
import { bindActionCreators } from 'redux';
import { convertTimestampToDateTime } from 'util/UheHelper';

import {
  customersOnFetchDevicesData,
  fetchCustomer,
  pendingActionsData,
  cleanUpSelectedCustomer,
  deletePendingAction,
  getNetworkCertificateReport,
  moveDeviceRequest,
} from '@uhe_actions/configuration/customers/CustomersActions';
import { fetchDeviceTypes } from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import * as bulkActions from '@uhe_actions/configuration/customers/CustomersActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
  MAP_FILTER_PREFIX,
  UPDATE_CERTIFICATE,
  SUCCESS,
  FAIL,
} from '@constants/UHESettings';
import { goBackTo, checkInputSymbols } from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import UpdateNetworkCertificateModal from '@components/uhe/configuration/customer/UpdateNetworkCertificateModal';
import {
  shouldDoHardReboot,
  shouldshouldDisableActionRadio,
} from '@util/UheRoleChecker';

const { confirm } = Modal;

/**
 * Renders Bulk Actions page
 */
class BulkActions extends React.Component {
  /**
   * Adapt data returned from the server
   * @param  {Array<Object>} data list
   * @return {Array<Object>} array
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization_id,
          name: value.organization_name,
        },
        customer: {
          id: value.customer_id,
          organizationId: value.organization_id,
          name: value.customer_name,
        },
        facility: {
          id: value.facility_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          name: value.facility_name,
        },
        unit: {
          id: value.unit_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          facilityId: value.facility_id,
          name: value.unit_name,
        },
        deviceId: value.device_id,
        bedCart: {
          id: value.device_id,
          name: value.cart_name,
          customerId: value.customer_id,
        },
        type: value.type,
        status: value.status,
      });
    });
    return adaptedData;
  }

  /**
   * Adapt data returned from the server
   * @param  {Array<Object>} pendingActionData list
   * @return {Array<Object>} array
   */
  static pendingActionsDataAdapter(pendingActionData = []) {
    const adaptedData = [];
    if (pendingActionData) {
      pendingActionData.forEach((value, index) => {
        adaptedData.push({
          key: index,
          device: value.name,
          command: value.command_name,
          actions: {
            id: value.id,
          },
        });
      });
    }
    return adaptedData;
  }

  /**
   * Adapts Data to Network Certificate Update Log Table
   * @param {array} data Array
   * @returns {array} Table Data
   */
  static reportDataAdapter(data = []) {
    const adaptedData = [];

    if (data) {
      data.forEach((value, index) => {
        adaptedData.push({
          key: index,
          status: {
            status: value.status,
            statusColor: value.status_color,
          },
          commandType: value.command_type,
          initiatedBy: value.initiated_by,
          timestamp: value.time_cmd_sent,
          actions: value.bulk_id,
        });
      });
    }

    return adaptedData;
  }

  /**
   * BulkActions Constructor
   * @param {object} props Props
   */
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPendingActionsChange = this.onPendingActionsChange.bind(this);
    const { intl, history, setSubtitle } = this.props;

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'organization_id',
    };

    this.tableFilterMap = {
      facility: 'facility_name',
      unit: 'unit_name',
      bedCart: 'cart_name',
      deviceId: 'device_id',
      type: 'type',
    };

    this.tablePendingActions = {
      device: 'device',
      command: 'command',
      actions: 'actions',
    };

    this.tableNetworkReport = {
      status: 'status',
      commandType: 'command_type',
      initiatedBy: 'initiated_by',
      timestamp: 'time_cmd_sent',
      actions: 'bulk_id',
    };

    this.state = {
      selectedRowKeys: [],
      filteredSelectedRowKeys: [],
      customer: {
        name: '',
        id: null,
      },
      customerName: '',
      data: [],
      topFilters: [],
      crtContent: '',
      pemContent: '',
      showModal: false,
      actions: {
        restartApp: 'soft_reboot',
        restartOs: 'reboot_os',
        restartHardware: 'hard_reboot',
        updateVersion: 'update_version',
        updateNetworkCertificate: 'update_network',
        moveSystemDevices: 'move_system',
      },
      selectedRadio: 'soft_reboot',
      newSelectedRadio: null,
      oldSelectedRadio: null,
      alteredData: [],
      selectedRows: [],
      selectedRowsAll: [],
      filterCriteria: {
        facilityFilter: '',
        unitFilter: '',
        bedCartFilter: '',
        deviceIdFilter: '',
        typeFilter: '',
      },
      isClearedInputs: false,
    };

    this.data = [];
    this.pendingActionData = [];
    this.columns = [];
    this.actionColumns = [];
    this.networkReportColumns = [];
    this.selectedTableColumns = [];
    this.tableKeys = Object.keys(this.tableFilterMap);
    this.tableActionKeys = Object.keys(this.tablePendingActions);
    this.tableUNetworkReportKeys = Object.keys(this.tableNetworkReport);
    this.intl = intl;

    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => {
          const { isClearedInputs } = this.state;

          return (
            <ListingsTableInputFilter
              cellData={cellData}
              title={`uhe.table.${value}`}
              dataKey={value}
              triggerCharsNum={value === 'deviceId' || value === 'version' ? 0 : undefined}
              example
              isClearedInputs={isClearedInputs}
            />
          );
        },
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
      this.selectedTableColumns.push({
        title: () => (
          <div className="table-filter-holder">
            <span className="uhe-table-head">
              <FormattedMessage id={`uhe.table.${value}`} />
            </span>
            <div>
              <Input
                suffix={<i className="icon icon-search-new" />}
                placeholder={intl.messages['uhe.table.tableInputFilter']}
                onChange={this.handleSelectedDeviceFilter}
                id={intl.formatMessage({ id: `uhe.table.${value}` })}
                type={value !== 'deviceId' ? 'string' : 'number'}
                onKeyDown={(evt) => checkInputSymbols(value, evt)}
              />
            </div>
          </div>
        ),
        dataKey: value,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        selectedColumns: true,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.tableActionKeys.forEach((value, index) => {
      this.actionColumns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'deviceId' || value === 'version' ? 0 : undefined}
            showFilter={
              !(
                value === 'actions'
                || value === 'device'
                || value === 'command'
              )
            }
          />
        ),
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.tableUNetworkReportKeys.forEach((value, index) => {
      this.networkReportColumns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'deviceId' || value === 'version' ? 0 : undefined}
            showFilter={(value !== 'status' && value !== 'actions')}
          />
        ),
        sorter: value === 'actions' ? false : { multiple: index },
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);

    setSubtitle('common.bulk');

    this.onPageChange(1);
    this.onPendingActionsChange(1);
    this.onNetworkReportPaginationChange(1);
  }

  /**
   * Handles subscriptions
   * @returns {void}
   */
  componentDidMount() {
    const { fetchCustomer, match, fetchDeviceTypes } = this.props;
    const urlId = match.params.id;

    fetchCustomer({ id: urlId });
    fetchDeviceTypes();
  }

  /**
   * Updates the component on location change
   * @param {Object} prevProps object
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      customer,
      location,
      data,
    } = this.props;
    const { selectedRows } = this.state;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
      this.onNetworkReportPaginationChange(1);
    }

    if (data !== prevProps.data) {
      let alteredData = BulkActions.dataAdapter(data);

      alteredData = alteredData.map((element) => {
        const exists = selectedRows.find((el) => el.deviceId === element.deviceId);
        if (exists) {
          return { ...element, selected: true };
        }
        return { ...element, selected: false };
      });

      this.setState({ alteredData, isClearedInputs: false });
    }

    if (prevProps.customer && customer) {
      if ((prevProps.customer !== customer || prevProps.customer.id !== customer.id)) {
        this.setState({ customer });
      }

      if (!prevProps.customer.id && customer.id && customer.organization.id) {
        this.buldTopFilters();
      }
    }
  }

  /**
   * On Component Unmount
   * @returns {void}
   */
  componentWillUnmount() {
    const { cleanUpSelectedCustomer } = this.props;

    cleanUpSelectedCustomer();
  }

  /**
   * Handle event in current page
   * @param  {number} page number
   * @return {void}
   */
  onPageChange(page) {
    const { customersOnFetchDevicesData, match } = this.props;
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const sort = [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      const sorter = currSort.find((element) => element === `${key},asc` || element === `${key},desc`);

      if (filterParam) {
        if (!isNaN(filterParam)) {
          filter.push(`${value}=${encodeURIComponent(Number(filterParam))}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }

      if (sorter) {
        sort.push(sorter.replace(key, value));
      }
    });

    lodash.forOwn(this.locationFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${MAP_FILTER_PREFIX}${key}`);

      if (filterParam) {
        filter.push(`${value}=${encodeURIComponent(filterParam)}`);
      }
    });

    this.filter = filter;
    customersOnFetchDevicesData(page - 1, sort, filter, match.params.id);
  }

  /**
   * Show pending actions data
   * @param {Number} page number
   * @returns {Object} data
   */
  onPendingActionsChange(page) {
    const { pendingActionsData, match } = this.props;
    this.currentActionPage = page - 1;
    pendingActionsData(this.currentActionPage, null, null, match.params.id);
  }

  /**
   * Triggers On Network Report Pagination Change
   * @param {number} page Page Number
   * @returns {void}
   */
  onNetworkReportPaginationChange = (page) => {
    const { getNetworkCertificateReport, match } = this.props;
    const urlId = match.params.id;

    const currSort = this.qParams.getAll('sort') || [];

    const sort = [];
    const filter = [];

    lodash.forOwn(this.tableNetworkReport, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableNetworkReport, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      const sorter = currSort.find((element) => element === `${key},asc` || element === `${key},desc`);

      if (filterParam) {
        if (!isNaN(filterParam)) {
          filter.push(`${value}=${encodeURIComponent(Number(filterParam))}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }

      if (sorter) {
        sort.push(sorter.replace(key, value));
      }
    });

    lodash.forOwn(this.locationFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${MAP_FILTER_PREFIX}${key}`);
      if (filterParam) {
        filter.push(`${value}=${encodeURIComponent(filterParam)}`);
      }
    });

    this.filter = filter;

    getNetworkCertificateReport(urlId, page - 1, sort, filter);
  }

  /**
   * Invokes Pending/Bulk Actions on Action Button Click
   * @param {string} action Command Action
   * @param {number} page Page Number
   * @param {string} sort Sort
   * @param {string} filter Filter
   * @returns {void}
   */
  onActionClick(action) {
    const { crtContent, pemContent, selectedRows } = this.state;
    const {
      actions, customer,
    } = this.props;

    this.setState({ isClearedInputs: true });

    const deviceIdsArr = [];

    selectedRows.forEach((row) => {
      deviceIdsArr.push(row.deviceId);
    });

    if (action === UPDATE_CERTIFICATE) {
      actions.addBulkAction({
        command: action,
        key: 'endpoints',
        value: deviceIdsArr,
        id: customer.id,
        cert: crtContent,
        pem: pemContent,
        isUpdateNetworkCert: true,
      });
    } else {
      actions.addBulkAction({
        command: action,
        key: 'endpoints',
        value: deviceIdsArr,
        id: customer.id,
      });
    }

    this.clearOnSubmit();
  }

  /**
   * On Select Change
   * @param {object} record Record To Select
   * @returns {void}
   */
  onSelectChange = (record) => {
    const { selectedRows } = this.state;

    const newAlteredData = this.setAlteredDataTo(record, true);

    const newSelectedRows = [...selectedRows];

    newSelectedRows.forEach((element) => {
      if (record.key === element.key) {
        record.key = newSelectedRows.length;
      }
    });

    newSelectedRows.push(record);

    this.setState({
      alteredData: newAlteredData,
      selectedRows: newSelectedRows,
      selectedRowsAll: newSelectedRows,
    });
  };

  /**
   * On Unselect Change
   * @param {object} record Record To Unselect
   * @returns {void}
   */
  onUnselectChange = (record) => {
    const { selectedRows } = this.state;

    const newAlteredData = this.setAlteredDataTo(record, false);

    const newSelectedRows = [...selectedRows];
    const index = newSelectedRows.indexOf(record);
    newSelectedRows.splice(index, 1);

    this.setState({
      alteredData: newAlteredData,
      selectedRows: newSelectedRows,
      selectedRowsAll: newSelectedRows,
    });
  };

  /**
   * On Select Change All
   * @param {object} selected Selected Boolean
   * @param {object} selectedRows All Selected Rows
   * @param {object} changeRows Rows To Be Changed
   * @returns {void}
   */
  onSelectAllChange = (selected, selectedRows, changeRows) => {
    const { alteredData, selectedRows: currentlySelectedRows } = this.state;

    const newAlteredData = alteredData.map((element) => (element.status !== 'not_configured'
      ? ({ ...element, selected: true })
      : ({ ...element, selected: false })));

    const newSelectedRows = [...currentlySelectedRows];
    let counter = newSelectedRows.length;

    newSelectedRows.forEach((element) => {
      changeRows.forEach((changedRow) => {
        if (changedRow.key === element.key) {
          changedRow.key = counter;
          counter += 1;
        }
      });
    });

    this.setState({
      alteredData: newAlteredData,
      selectedRows: [...newSelectedRows, ...changeRows],
      selectedRowsAll: [...newSelectedRows, ...changeRows],
    });
  }

  /**
   * On Unselect Change All
   * @param {object} selected Selected Boolean
   * @param {object} selectedRows All Selected Rows
   * @param {object} changeRows Rows To Be Changed
   * @returns {void}
   */
  onUnselectAllChange = (selected, selectedRows, changeRows) => {
    const { alteredData, selectedRows: currentlySelectedRows } = this.state;

    const newAlteredData = alteredData.map((element) => {
      const exists = changeRows.find((el) => el.deviceId === element.deviceId);
      if (exists) {
        return { ...element, selected: false };
      }
      return element;
    });

    const newSelectedRows = [...currentlySelectedRows];
    changeRows.forEach((element) => {
      const index = newSelectedRows.indexOf(element);
      newSelectedRows.splice(index, 1);
    });

    this.setState({
      alteredData: newAlteredData,
      selectedRows: newSelectedRows,
      selectedRowsAll: newSelectedRows,
    });
  }

  /**
   * Refresh pending actions
   * @returns {void}
   */
  onButtonRefresh() {
    const { pendingActionsData, match } = this.props;

    pendingActionsData(this.currentActionPage === undefined
      ? 0
      : this.currentActionPage, null, null, match.params.id);
  }

  /**
   * Changes filter criteria in state
   * @param {String} field fieldValue
   * @param {String} value searchValue
   * @return {void} Returns filtered criteria
   */
  addSelectedDevicesFilters = (field, value) => {
    const { intl } = this.props;
    const { filterCriteria } = this.state;

    switch (field) {
      case intl.formatMessage({ id: 'uhe.table.facility' }):
        this.setState({ filterCriteria: { ...filterCriteria, facilityFilter: value } });
        break;
      case intl.formatMessage({ id: 'uhe.table.unit' }):
        this.setState({ filterCriteria: { ...filterCriteria, unitFilter: value } });
        break;
      case intl.formatMessage({ id: 'uhe.table.bedCart' }):
        this.setState({ filterCriteria: { ...filterCriteria, bedCartFilter: value } });
        break;
      case intl.formatMessage({ id: 'uhe.table.deviceId' }):
        this.setState({ filterCriteria: { ...filterCriteria, deviceIdFilter: value } });
        break;
      case intl.formatMessage({ id: 'uhe.table.type' }):
        this.setState({ filterCriteria: { ...filterCriteria, typeFilter: value } });
        break;
      default: break;
    }
  }

  /**
   * Filters selected devices by table header search
   * @param {Object} e Event
   * @return {void} Returns array based on search values
   */
  handleSelectedDeviceFilter = (e) => {
    const { value, id } = e.target;
    this.addSelectedDevicesFilters(id, value);

    setTimeout(() => {
      const { selectedRowsAll, filterCriteria } = this.state;
      let filtered = selectedRowsAll;
      filtered = selectedRowsAll.filter(
        (row) => row.facility.name.toLowerCase()
          .includes(filterCriteria.facilityFilter.toLowerCase())
          && row.unit.name.toLowerCase().includes(filterCriteria.unitFilter.toLowerCase())
          && row.bedCart.name.toLowerCase().includes(filterCriteria.bedCartFilter.toLowerCase())
          && Number(row.deviceId) === Number(filterCriteria.deviceIdFilter)
          && row.type.toLowerCase().includes(filterCriteria.typeFilter.toLowerCase()),
      );

      this.setState({ selectedRows: filtered });
    }, 1000);
  }

  /**
   * Returns An Array Of Altered Data
   * @param {object} record Record Object
   * @param {boolean} selectedValue Boolean
   * @returns {array} Array Of Altered DAta
   */
  setAlteredDataTo = (record, selectedValue) => {
    const { alteredData } = this.state;

    return alteredData.map((element) => {
      if (element.deviceId === record.deviceId) {
        return { ...element, selected: selectedValue };
      }
      return element;
    });
  }

  /**
    Build top filters
    @returns {array} of filters
  */
  buldTopFilters = () => {
    const { history, customer } = this.props;
    this.setState({
      topFilters: [
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
          fieldNames: { label: 'name', value: 'id' },
          showSearch: true,
          key: 'organization',
          disabled: true,
        },
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
          fieldNames: { label: 'name', value: 'id' },
          showSearch: true,
          key: 'customer',
        },
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
          fieldNames: { label: 'name', value: 'id' },
          showSearch: true,
          key: 'facility',
        },
        {
          placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
          fieldNames: { label: 'name', value: 'id' },
          showSearch: true,
          key: 'unit',
        },
      ],
    }, () => {
      const params = new URLSearchParams();
      params.set(`${TOP_FILTER_PREFIX}organization`, customer.organization.id);
      const search = params.toString();
      history.push({ search });
    });
  }

  /**
   * Gets Certificate File Content
   * @param {string} key Certificate
   * @param {object} file File Data
   * @returns {boolean} Prevents File Upload
   */
  getCertContent = (key) => (file) => {
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onload = (event) => this.setState({ [key]: event.target.result });

    return false;
  }

  /**
 * Shows Update Network Confirmation Modal
 * @returns {void}
 */
  showConfirmationModal = () => {
    confirm({
      title: this.intl.formatMessage({ id: 'configuration.bedsCarts.actions.updateCertificateConfirmation' }),
      onOk: () => {
        this.onActionClick('update_wifi_cert');
        this.setState({
          crtContent: '',
          pemContent: '',
        });
      },
      okText: this.intl.formatMessage({ id: 'common.confirm' }),
      autoFocusButton: 'cancel',
    });
  }

  /**
 * Validates Update Network Modal
 * @returns {void}
 */
  validateUpdateNetworkCert = () => {
    const { pemContent, crtContent } = this.state;

    if (!!pemContent && !!crtContent) {
      this.setState({ showModal: false });
      this.showConfirmationModal();
    }
  }

  /**
 * Checks if Update Network Certificate Button Should be Enabled or Disabled
 * @returns {boolean} true || false
 */
  isUpdateWifiBtnDisabled = () => {
    const { customer } = this.props;
    const remoteWifiCertificate = customer?.attributes?.filter((item) => item.name === 'remote_update_network_certificate_enabled');

    if (remoteWifiCertificate) {
      if (remoteWifiCertificate[0]?.value === 'TRUE') {
        return false;
      }
      return true;
    }
  }

  /**
 * Shows Update Network Certificate Modal Only if Devices are Selected
 * Shows Warning Message If no Dеvices are Selected
 * @returns {void}
 */
  shouldShowUpdateWifiModal = () => {
    const { selectedRows } = this.state;

    if (selectedRows.length > 0) {
      this.setState({ showModal: true });
    } else {
      notification.warning({
        message: this.intl.formatMessage({ id: 'notification.warning' }),
        description: this.intl.formatMessage({ id: 'configuration.customer.wifiWarningMsg' }),
      });
    }
  }

  /**
 * Renders #x Commands Waiting To Run Text
 * @returns {JSX.Element} div Element with Text
 */
  pendingTableTitle() {
    const { actionPagination } = this.props;
    const { total } = actionPagination;
    const startMsg = this.intl.formatMessage({ id: 'common.waitingCommands' });

    return (
      <div className="commands-number-title">
        {startMsg}
        {' '}
        <span className="commands-number">{total}</span>
      </div>
    );
  }

  /**
 * Fires off fetchCSVReport action
 * @param {number} customerId Customer ID
 * @param {number} bulkId Bulk ID
 * @returns {void}
 */
  fetchCSVReport = (customerId, bulkId) => {
    const { fetchCSVReportActions } = this.props;
    fetchCSVReportActions(customerId, bulkId);
  }

  /**
   * Renders formatted timestamp based on the logged user's timezone
   * @param {string} timestamp Timestamp
   * @returns {string} the formatted timestamp
   */
   formatTimestamp = (timestamp) => {
     const { loggedUser } = this.props;
     const { timeZone } = loggedUser;
     let formattedDateTime = convertTimestampToDateTime(timestamp, timeZone);

     if (formattedDateTime) {
       let [date, time, timeZone] = formattedDateTime.split(' ');
       let index;

       if (timeZone.includes('(')) {
         timeZone = timeZone.slice(1, timeZone.length - 1);
       }
       if (timeZone.includes('+')) {
         index = timeZone.indexOf('+');
       } else if (timeZone.includes('-')) {
         index = timeZone.indexOf('-');
       }

       timeZone = timeZone.slice(0, index);

       formattedDateTime = [date, time, timeZone].join(' ');
     }
     return formattedDateTime;
   };

   /**
   * Use different cell type depending on the column
   * @param  {Object} content Data for Each Cell
   * @param  {string} key Column Name
   * @return {ReactElement|Object} Table Cell || Object
   */
   cellRenderer(content, key) {
     const { match } = this.props;
     const urlId = match.params.id;
     let cell;

     switch (key) {
       case 'organization':
         cell = <OrganizationCell content={content} />;
         break;
       case 'customer':
         cell = <CustomerCell content={content} />;
         break;
       case 'facility':
         cell = <FacilityCell content={content} />;
         break;
       case 'unit':
         cell = <UnitCell content={content} />;
         break;
       case 'bedCart':
         cell = <UheUnitCell content={content} />;
         break;
       case 'timestamp':
         cell = this.formatTimestamp(content);
         break;
       case 'actions':
         cell = this.renderCSVReportDownloadButton(urlId, content);
         break;
       case 'status':
         cell = <CommandStatusCell content={content} />;
         break;
       default:
         cell = content;
     }

     return cell;
   }

   /**
    * Render back buttons
    * @returns {JSX} EditHeader Component
  */
   renderHeadLine() {
     const { loading, lastLocation, history } = this.props;
     return (
       <EditHeader
         goBack={goBackTo(`/configuration/customers?sort=${encodeURIComponent('organization,asc')}`, lastLocation?.pathname, history)}
         loading={loading}
       />
     );
   }

  /**
   * Renders Action Buttons
   * @param {string} actionName Action Name
   * @returns {JSX.Element} Action Button
   */
  renderActionButton = (actionName) => {
    const { customer: { id }, intl: { formatMessage }, logggedUser } = this.props;
    const splittedActionName = actionName.split('_');
    const camelCaseActionName = [...splittedActionName].shift() + splittedActionName.slice(1)
      .map((char) => char[0].toUpperCase() + char.slice(1));
    return (
      <Popconfirm
        title={formatMessage({ id: 'common.areYouSureThat' })
          + formatMessage({ id: `configuration.bedsCarts.actions.${camelCaseActionName}` })
          + formatMessage({ id: 'common.questionMark' })}
        onConfirm={() => this.onActionClick(actionName)}
      >
        <Button
          htmlType="submit"
          className="my-btn action-button"
          disabled={!id || (actionName === 'hard_reboot' && !shouldDoHardReboot(logggedUser))}
        >
          <IntlMessages id={`configuration.bedsCarts.actions.${camelCaseActionName}`} />
        </Button>
      </Popconfirm>
    );
  };

  /**
   * Renders CSV Report Download Button
   * @param {number} customerId Customer ID
   * @param {number} bulkId Bulk ID
   * @returns {JSX.Element} BulkActions Component
   */
  renderCSVReportDownloadButton = (customerId, bulkId) => {
    const { networkReport } = this.props;
    const currentReport = networkReport.findIndex((report) => report.bulk_id === bulkId);

    if (networkReport[currentReport].status === SUCCESS
      || networkReport[currentReport].status === FAIL) {
      return (
        <Button
          type="button"
          className="button-password gx-d-flex download-button"
          onClick={() => { this.fetchCSVReport(customerId, bulkId); }}
        >
          <i className="icon icon-files download-icon" />
          <IntlMessages id="configuration.customer.downloadCsv" />
        </Button>
      );
    }
  };

  /**
   * Change radio button event
   * @param {Object} event radio
   * @returns {void} element
   */
  onChangeRadioButton = (event) => {
    const { selectedRadio, selectedRows } = this.state;
    const oldSelectedRadio = selectedRadio;

    if (selectedRows.length > 0) {
      this.setState({
        selectedRadio: null, newSelectedRadio: event.target.value, oldSelectedRadio,
      });
    } else {
      this.setState({ selectedRadio: event.target.value, oldSelectedRadio });
    }
  }

  /**
   * On Pop Confirm Event
   * @returns {void}
   */
  onPopConfirm = () => {
    const { newSelectedRadio, alteredData } = this.state;

    const newAlteredData = alteredData.map((element) => {
      if (element.selected === true) {
        return { ...element, selected: false };
      }
      return element;
    });

    this.setState({ selectedRadio: newSelectedRadio, alteredData: newAlteredData, selectedRows: [] });
  }

  /**
   * On Pop Cancel Event
   * @returns {void}
   */
  onPopCancel = () => {
    const { oldSelectedRadio } = this.state;

    this.setState({ selectedRadio: oldSelectedRadio });
  }

  /**
   * Handles if radio button for Update Network Certificate is disabled
   * @param {string} value radio button value
   * @returns {boolean} disabled
   */

  shouldDisableRadio = (value) => {
    const { customer, loggedUser } = this.props;
    const { attributes } = customer;
    if (value === 'update_network' && attributes) {
      let disabled = false;
      attributes.forEach((attribute) => {
        if (attribute?.name === 'remote_update_network_certificate_enabled' && attribute?.value?.toLowerCase() !== 'true') {
          disabled = true;
        }
      });
      return disabled;
    }
    if (value === 'hard_reboot') {
      let disabled = true;
      if (shouldshouldDisableActionRadio(loggedUser)) {
        disabled = false;
      }
      return disabled;
    }
  }

  /**
   * Render radio buttons
   * @returns {JSX} element
   */
  renderRadioButtons() {
    const { actions, selectedRadio, selectedRows } = this.state;
    const actionsArray = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(actions)) {
      let popoverText;

      switch (key) {
        case 'restartApp':
          popoverText = <IntlMessages id="common.restartAppTooltip" />;
          break;
        case 'restartOs':
          popoverText = <IntlMessages id="common.restartOsTooltip" />;
          break;
        case 'restartHardware':
          popoverText = <IntlMessages id="common.hardRebootTooltip" />;
          break;
        case 'updateVersion':
          popoverText = <IntlMessages id="common.updateVersionTooltip" />;
          break;
        case 'updateNetworkCertificate':
          popoverText = <IntlMessages id="common.updateCertificateTooltip" />;
          break;
        case 'moveSystemDevices':
          popoverText = <IntlMessages id="common.moveRoomTooltip" />;
          break;
        default:
          break;
      }

      actionsArray.push(
        {
          key,
          value,
          popoverText,
        },
      );
    }

    const radios = actionsArray.map((action) => (
      <Popconfirm
        title={this.intl.formatMessage({ id: 'common.deviceSelection' })}
        disabled={!(selectedRows.length > 0)}
        onConfirm={this.onPopConfirm}
        onCancel={this.onPopCancel}
        okText={this.intl.formatMessage({ id: 'common.confirm' })}
        cancelText={this.intl.formatMessage({ id: 'common.cancel' })}
      >
        <Popover placement="bottom" content={action.popoverText}>
          <Radio value={action.value} disabled={this.shouldDisableRadio(action.value)}>
            <IntlMessages id={`configuration.bedsCarts.actions.${action.key}`} />
          </Radio>
        </Popover>
      </Popconfirm>
    ));

    return (
      <Radio.Group value={selectedRadio} onChange={this.onChangeRadioButton}>
        {radios}
      </Radio.Group>
    );
  }

  /**
    * Verify cisco device connectivity
    * @param {string} action command
    * @param {number} page number
    * @param {string} sort of table
    * @param {string} filter of table
    * @returns {void}
  */
  moveDeviceSystem = (action, page, sort, filter) => {
    const { crtContent, pemContent, selectedRows } = this.state;
    const {
      actions, customer, location,
    } = this.props;
    const facilityId = location.search.split('topf_unit=');
    this.setState({ isClearedInputs: true });

    const deviceIdsArr = [];

    selectedRows.forEach((row) => {
      deviceIdsArr.push(row.deviceId);
    });

    if (action === UPDATE_CERTIFICATE) {
      actions.moveDeviceRequest({
        command: action,
        key: 'endpoints',
        value: deviceIdsArr,
        id: customer.id,
        cert: crtContent,
        pem: pemContent,
        isUpdateNetworkCert: true,
        target_unit_id: facilityId[1],
        page,
        sort,
        filter,
      });
    } else {
      actions.moveDeviceRequest({
        key: 'endpoints',
        value: deviceIdsArr,
        id: customer.id,
        target_unit_id: facilityId[1],
        page,
        sort,
        filter,
      });
    }
    this.clearOnSubmit();
  }

  /**
    * Handles if checkbox should be disabled
    * @param {Object} record current row
    * @returns {boolean}
  */

  shouldBeDisabled = (record) => {
    const { selectedRadio } = this.state;
    const { deviceTypes, data, latestVersion } = this.props;
    let isOnline = false;
    let canHardReboot = false;
    let shouldUpdate = false;

    data.forEach((device) => {
      if (device.device_id === record.deviceId && device.status !== 'not_configured') {
        isOnline = true;
      }
      if (device.device_id === record.deviceId && parseFloat(device.version) < latestVersion) {
        shouldUpdate = true;
      }
    });
    deviceTypes.forEach((device) => {
      if (device.value === record.type && device.can_hard_reboot === 1) {
        canHardReboot = true;
      }
    });
    if (selectedRadio === 'move_system') {
      return false;
    }
    if (record?.type?.includes('APS') && isOnline) {
      switch (selectedRadio) {
        case 'hard_reboot':
          return !canHardReboot;
        case 'update_version':
          return !shouldUpdate;
        default:
          return false;
      }
    }
    return true;
  }

  /**
   * Render Bulk Actions Page
   * @return {JSX.Element} Tables
   */
  clearOnSubmit = () => {
    const { location, history } = this.props;
    const { search } = location;
    const defaultSearch = search.split('&')[0];
    history.push({ search: defaultSearch });

    this.setState({
      selectedRadio: 'soft_reboot',
      newSelectedRadio: null,
      oldSelectedRadio: null,
      selectedRows: [],
      selectedRowsAll: [],
    });
  }

  /**
   * Render Bulk Actions Page
   * @return {JSX.Element} Tables
   */
  render() {
    const {
      pagination,
      actionPagination,
      loading,
      networkReport,
      networkReportPagination,
    } = this.props;
    pagination.onChange = this.onPageChange;
    actionPagination.onChange = this.onPendingActionsChange;
    networkReportPagination.onChange = this.onNetworkReportPaginationChange;
    const {
      selectedRowKeys, topFilters, customer, showModal, selectedRadio,
    } = this.state;
    this.columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
        }),
      };
    });

    const { alteredData, selectedRows, selectedRowsAll } = this.state;

    const selectedRowsPagination = {
      pageSize: LISTING_TABLES_PAGE_SIZE,
      total: selectedRows.length,
    };

    let unselectedRowsArray = alteredData.filter((element) => !element.selected);

    if (!selectedRowsAll.length) {
      unselectedRowsArray = alteredData;
    }

    const selectedRowsIdsArray = selectedRows.map((item) => item.key);

    const selectedRowSelection = {
      selectedRowKeys: selectedRowsIdsArray,
      onSelect: this.onUnselectChange,
      onSelectAll: this.onUnselectAllChange,
    };

    const unselectedRowSelection = {
      selectedRowKeys,
      onSelect: this.onSelectChange,
      onSelectAll: this.onSelectAllChange,
      getCheckboxProps: (record) => ({
        disabled: this.shouldBeDisabled(record),
      }),
    };

    const availableDevices = alteredData.filter((el) => el.selected === false);

    return (
      <div className="dashboard bulk-action-page">
        <div>
          <div className="uhe-table-header">
            {this.pendingTableTitle()}
            <div className="manage-customer-wrapper">
              {this.renderHeadLine()}
            </div>
          </div>
          <Card
            className="bulk-actions-card"
            title={<IntlMessages id="common.deviceTable" />}
          >
            <Row gutter={16}>
              {this.renderRadioButtons()}
            </Row>
            <div className="device-header">
              {topFilters.length > 0
                && (
                  <>
                    <Row gutter={16} className="bulk-action-wrapper">
                      <Col lg={9} md={12} sm={12} xs={24} className="bulk-action-rows">
                        <Row gutter={16} className="bulk-action-rows">
                          <Col lg={8} md={12} sm={7} xs={11} className="bulk-action-rows">
                            <IntlMessages id="common.organizationText" />
                          </Col>
                          <Col lg={10} md={12} sm={13} xs={13} className="bulk-action-rows filter-title">
                            {customer.organization ? customer.organization.name : ''}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={9} md={12} sm={12} xs={24} className="bulk-action-rows">
                        <Row gutter={16} className="bulk-action-rows">
                          <Col lg={8} md={11} sm={7} xs={8} className="bulk-action-rows">
                            <IntlMessages id="common.customerText" />
                          </Col>
                          <Col lg={10} md={13} sm={13} xs={16} className="bulk-action-rows filter-title">
                            {customer ? customer.name : ''}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={10} justify="end">
                      <Col>
                        {availableDevices.length}
                      </Col>
                      <Col>
                        <IntlMessages id="common.availableDevices" />
                      </Col>
                    </Row>
                  </>
                )}
            </div>
            <div className="uhe-table">
              <Table
                bordered
                className="gx-table-responsive"
                columns={this.columns}
                dataSource={unselectedRowsArray}
                onChange={this.handleTableChange}
                pagination={pagination}
                rowClassName={() => 'editable-row'}
                rowSelection={unselectedRowSelection}
                loading={loading}
              />
            </div>
          </Card>
          {selectedRowsAll.length > 0
            && (
              <Card className="bulk-actions-card" title={<IntlMessages id="common.selectedDeviceTitle" />}>
                <Row gutter={10} justify="end">
                  <Col>
                    {selectedRows.length}
                  </Col>
                  <Col>
                    <IntlMessages id="common.selectedDevices" />
                  </Col>
                </Row>
                <div className="uhe-table">
                  <Table
                    bordered
                    className="gx-table-responsive"
                    columns={this.selectedTableColumns}
                    dataSource={selectedRows}
                    onChange={this.handleTableChange}
                    pagination={selectedRowsPagination}
                    rowClassName={() => 'editable-row'}
                    rowSelection={selectedRowSelection}
                    loading={loading}
                  />
                </div>
                <div className="ant-table-wrapper gx-table-responsive gx-mt-3">
                  {selectedRadio === 'move_system' && (
                    <Row className="margin-left-8">
                      <IntlMessages id="common.moveTo" />
                    </Row>
                  )}
                  <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
                    {selectedRadio === 'soft_reboot'
                      && (
                        <Popover content={<IntlMessages id="common.restartAppTooltip" />}>
                          <Col lg={6} md={6} sm={8} sx={8}>
                            {this.renderActionButton('soft_reboot')}
                          </Col>
                        </Popover>

                      )}
                    {selectedRadio === 'reboot_os'
                      && (
                        <Popover content={<IntlMessages id="common.restartOsTooltip" />}>
                          <Col lg={6} md={6} sm={8} sx={8}>
                            {this.renderActionButton('reboot_os')}
                          </Col>
                        </Popover>
                      )}
                    {selectedRadio === 'hard_reboot'
                      && (
                        <Col lg={6} md={6} sm={8} sx={8}>
                          <Popover content={<IntlMessages id="common.hardRebootTooltip" />}>
                            {this.renderActionButton('hard_reboot')}
                          </Popover>
                        </Col>
                      )}
                    {selectedRadio === 'update_version'
                      && (
                        <Popover content={<IntlMessages id="common.updateVersionTooltip" />}>
                          <Col lg={6} md={6} sm={8} sx={8}>
                            {this.renderActionButton('update_version')}
                          </Col>
                        </Popover>
                      )}
                    {selectedRadio === 'update_network'
                      && (
                        <Col lg={6} md={6} sm={8} sx={8}>
                          {showModal && (
                            <UpdateNetworkCertificateModal
                              visible={showModal}
                              onOk={this.validateUpdateNetworkCert}
                              getCertContent={this.getCertContent}
                              onCancel={() => this.setState({
                                showModal: false,
                                crtContent: '',
                                pemContent: '',
                              })}
                            />
                          )}
                          <Popover content={<IntlMessages id="common.updateCertificateTooltip" />}>
                            <Button
                              className="my-btn"
                              onClick={this.shouldShowUpdateWifiModal}
                            >
                              <IntlMessages id="configuration.bedsCarts.actions.updateNetworkCertificate" />
                            </Button>
                          </Popover>
                        </Col>
                      )}
                    {selectedRadio === 'move_system'
                    && (
                      <>
                        <Col lg={14} md={14} sm={14} sx={14}>
                          <ListingsTopFilter filters={topFilters} />
                        </Col>
                        <Col lg={6} md={6} sm={8} sx={8}>
                          <Popover content={<IntlMessages id="common.moveRoomTooltip" />}>
                            <Button
                              className="my-btn"
                              onClick={this.moveDeviceSystem}
                            >
                              <IntlMessages id="configuration.bedsCarts.actions.moveSystemDevices" />
                            </Button>
                          </Popover>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </Card>
            )}
        </div>
        <Card
          className="gx-card"
          title={<IntlMessages id="configuration.customer.networkCertUpdateLog" />}
        >
          <Table
            className="gx-table-responsive"
            loading={loading}
            columns={this.networkReportColumns}
            pagination={networkReportPagination}
            dataSource={BulkActions.reportDataAdapter(networkReport)}
            onChange={this.handleTableChange}
          />
          <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
            <Col lg={6} md={6} sm={8} sx={8}>
              <Button
                htmlType="submit"
                className="my-btn"
                onClick={() => { this.onButtonRefresh(); }}
              >
                <IntlMessages id="common.refresh" />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

BulkActions.defaultProps = {
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  networkReportPagination: {},
  pendingActionData: [],
  lastLocation: {},
  deviceTypes: [],
  fetchDeviceTypes: null,
  latestVersion: null,
  actions: {},
};

BulkActions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pendingActionData: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  pagination: PropTypes.shape(),
  networkReportPagination: PropTypes.shape(),
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  latestVersion: PropTypes.shape(),
  customersOnFetchDevicesData: PropTypes.func.isRequired,
  pendingActionsData: PropTypes.func.isRequired,
  setSubtitle: PropTypes.func.isRequired,
  customer: PropTypes.shape().isRequired,
  lastLocation: PropTypes.objectOf(PropTypes.string),
  networkReport: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchCSVReportActions: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  fetchDeviceTypes: PropTypes.func,
  deletePendingAction: PropTypes.func.isRequired,
  deviceTypes: PropTypes.arrayOf(PropTypes.shape()),
  actions: PropTypes.shape(),

};

/**
 * Maps State to Props
 * @param {object} param Desctructed Store Objects
 * @returns {object} Props
 */
const mapStateToProps = ({
  ConfigurationCustomers, ConfigurationUsers, subtitle, SystemSettingsSystem, common, bedsCarts,
}) => {
  const { list = [], page = {} } = ConfigurationCustomers.table || {};
  const actionsTableData = ConfigurationCustomers.actionsTable || {};
  const networkReportData = ConfigurationCustomers.networkReportPagination || {};

  const { loading } = ConfigurationCustomers;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };
  const actionPagination = {
    total: actionsTableData.page.totalElements || 0,
    current: actionsTableData.page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };
  const networkReportPagination = {
    total: networkReportData.totalElements || 0,
    current: networkReportData.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  const { production_version = {} } = SystemSettingsSystem.data;
  const latestVersion = parseFloat(production_version.value) || null;

  return {
    deviceTypes: bedsCarts.deviceTypes,
    data: list,
    pendingActionData: actionsTableData.list,
    pagination,
    actionPagination,
    networkReportPagination,
    loading,
    subtitle,
    latestVersion,
    customer: ConfigurationCustomers.selectedCustomer,
    networkReport: ConfigurationCustomers.networkReport,
    loggedUser: ConfigurationUsers.ownUser,
    error: common.error,
  };
};

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Actions to Props
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  customersOnFetchDevicesData:
    (page, sort, filter, id) => dispatch(customersOnFetchDevicesData(page, sort, filter, id)),
  pendingActionsData:
    (page, sort, filter, id) => dispatch(pendingActionsData(page, sort, filter, id)),
  actions: bindActionCreators(bulkActions, dispatch),
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
  fetchDeviceTypes: (data) => dispatch(fetchDeviceTypes(data)),
  cleanUpSelectedCustomer: () => dispatch(cleanUpSelectedCustomer()),
  deletePendingAction: (orgID, id, page, sorting, filter) => dispatch(deletePendingAction({
    orgID, id, page, sorting, filter,
  })),
  onGetCustomerOptions: (id) => dispatch(onGetOptions('customer', id)),
  onGetFacilityOptions: (id) => dispatch(onGetOptions('facility', id)),
  onGetUnitOptions: (id) => dispatch(onGetOptions('unit', id)),
  getNetworkCertificateReport:
    (
      customerId,
      page,
      sorting,
      filter,
    ) => dispatch(getNetworkCertificateReport(customerId, page, sorting, filter)),
  fetchCSVReportActions:
    (
      customerId,
      bulkId,
    ) => dispatch(bulkActions.fetchCSVReport(customerId, bulkId)),
  moveDeviceRequest: (id) => dispatch(moveDeviceRequest(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(BulkActions))));
