import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import Uhe from '@routes/Uhe/Uhe';
import NonUhe from '@routes/Uhe/NonUhe';
import Standards from '@routes/Standards/Standards';
import Organizations from '@routes/Configuration/Organizations/Organizations';
import ManageOrganization from '@containers/uhe/Configuration/ManageOrganization';
import ManageCustomer from '@containers/uhe/Configuration/ManageCustomer';
import Customers from '@routes/Configuration/Customers/Customers';
import ManageFacility from '@containers/uhe/Configuration/ManageFacility';
import Facilities from '@routes/Configuration/Facilities/Facilities';
import SystemView from '@routes/System/Settings/System';
import Iobserver from '@routes/System/Settings/Iobserver';
import Support from '@routes/System/Settings/Support';
import SsoProviders from '@routes/System/Settings/SsoProviders';
import AdvancedDev from '@routes/System/Settings/AdvancedDev';
import ManageSsoProvider from '@containers/uhe/System/ManageSsoProvider';
import ManageSAMLProvider from '@containers/uhe/System/ManageSAMLProvider';
import ManageUnit from '@containers/uhe/Configuration/ManageUnit';
import Units from '@routes/Configuration/Units/Units';
import UheUnits from '@routes/Configuration/bedsCarts/UheUnits';
import ManageUheBedCart from '@containers/uhe/Configuration/ManageUheBedCart';
import NonUheUnits from '@routes/Configuration/bedsCarts/NonUheUnits';
import ManageNonUheBedCart from '@containers/uhe/Configuration/ManageNonUheBedCart';
import ManageUsers from '@containers/uhe/Configuration/ManageUsers/ManageUsers';
import ManageMobilePatients from '@containers/uhe/Configuration/ManageMobilePatients/ManageMobilePatients';
import Users from '@routes/Configuration/Users/Users';
import MobilePatients from '@routes/Configuration/mobilePatients/MobilePatients';
import SignInReport from '@containers/uhe/Reports/SignInReport';
import VmrReport from '@containers/uhe/Reports/VmrReport';
import CreateBedCart from '@containers/uhe/Configuration/CreateBedCart';
import ActivateUser from '@containers/ActivateUser';
import { INVITE_TOKEN } from '@constants/UHESettings';
import DomainList from '@containers/uhe/System/DomainList';
import BulkUpload from '@containers/uhe/Configuration/BulkUpload';
import ResetPassword from '@containers/App/ResetPassword';
import BulkActions from '@containers/uhe/Configuration/BulkActions/BulkActions';
import { useSelector } from 'react-redux';
import Notifications from '@routes/System/Notifications/Notifications';
import ManageNotifications from '@routes/System/Notifications/ManageNotifications';
import Developer from '@routes/System/Developer/Developer';
import Technician from '@routes/System/Technician/Technician';
import Programs from '@routes/Configuration/Programs/Programs';
import ManagePrograms from '@containers/uhe/Configuration/ManagePrograms';
import { getOwnUser, getCustAttr } from '@uhe_selectors/configuration/users/UsersSelectors';
import {
  shouldRedirectUserAdmin,
  hasAnyRole,
} from '@util/UheRoleChecker';
import Twilio from '@routes/System/Settings/Twilio';
import ManageTwilio from '@containers/uhe/System/ManageTwilio';
import { isNotAdmin, isAuthorizedTechnician } from '../util/UheRoleChecker';

/**
 * Renders App Component
 * @param {object} match Route Path Information
 * @param {object} location React Route Location
 * @returns {JSX.Element} App Component
 */
const App = ({ match, location }) => {
  const loggedUser = useSelector(getOwnUser);
  const custAttr = useSelector(getCustAttr);

  return (
    <div className="gx-main-content-wrapper">
      {hasAnyRole(loggedUser) && (
        <Switch>
          {isNotAdmin(loggedUser) && isAuthorizedTechnician(loggedUser)
            ? (
              <Route exact path="/">
                {location.search.indexOf(INVITE_TOKEN) === -1 ? (
                  <Redirect to="/technician" />
                ) : (
                  <ActivateUser />
                )}
              </Route>
            )
            : (
              <Route exact path="/">
                {location.search.indexOf(INVITE_TOKEN) === -1 ? (
                  <Redirect to="/uhe-units" />
                ) : (
                  <ActivateUser />
                )}
              </Route>
            )}
          <Route exact path="/">
            {location.search.indexOf(INVITE_TOKEN) === -1 ? (
              <Redirect to="/uhe-units" />
            ) : (
              <ActivateUser />
            )}
          </Route>
          <Route exact path={`${match.url}uhe-units`}>
            {shouldRedirectUserAdmin(loggedUser)
              ? <Redirect to="/system/support" />
              : <Uhe />}
          </Route>
          <Route path={`${match.url}non-uhe-units`} component={Standards}>
            {custAttr
              ? <NonUhe />
              : <Redirect to="/system/support" />}
          </Route>
          <Route
            exact
            path={`${match.url}monitoring/non-uhe-units`}
            component={NonUhe}
          />
          <Route
            exact
            path={`${match.url}configuration/organizations`}
            render={(props) => <Organizations {...props} key={props.location.hash} />}
          />
          <Route
            path={`${match.url}configuration/customers/edit/:id/bulk-actions`}
            render={(props) => <BulkActions key="bulkActions" {...props} />}
          />
          <Route
            path={`${match.url}configuration/customers/edit/:id`}
            render={(props) => <ManageCustomer key="editCustomer" {...props} />}
          />
          <Route
            path={`${match.url}configuration/customers/new/`}
            component={ManageCustomer}
          />
          <Route
            path={`${match.url}configuration/customers`}
            render={(props) => <Customers {...props} key={props.location.hash} />}
          />
          <Route
            exact
            path={`${match.url}configuration/facilities`}
            render={(props) => <Facilities {...props} key={props.location.hash} />}
          />
          <Route
            exact
            path={`${match.url}configuration/facilities/edit/:id`}
            render={(props) => <ManageFacility key="editFacility" {...props} />}
          />
          <Route
            exact
            path={`${match.url}configuration/facilities/new/`}
            component={ManageFacility}
          />
          <Route
            exact
            path={`${match.url}system/settings/system/twilio/edit/:id`}
            component={ManageTwilio}
          />
          <Route
            exact
            path={`${match.url}system/settings/system/twilio/new`}
            component={ManageTwilio}
          />
          <Route
            path={`${match.url}system/settings/system/twilio`}
            component={Twilio}
          />
          <Route
            exact
            path={`${match.url}system/settings/advanced-dev`}
            component={AdvancedDev}
          />
          <Route
            exact
            path={`${match.url}system/settings/system`}
            component={SystemView}
          />
          <Route
            path={`${match.url}system/settings/iobserver`}
            component={Iobserver}
          />
          <Route
            path={`${match.url}system/settings/sso-providers/edit/:id/domains`}
            render={(props) => <DomainList key="editDomainList" {...props} />}
          />
          <Route
            path={`${match.url}system/settings/sso-providers/edit/:id/saml`}
            render={(props) => <ManageSAMLProvider key="editSAMLProvider" {...props} />}
          />
          <Route
            path={`${match.url}system/settings/sso-providers/edit/:id`}
            render={(props) => <ManageSsoProvider key="editSsoProvider" {...props} />}
          />
          <Route
            path={`${match.url}system/settings/sso-providers/new`}
            component={ManageSsoProvider}
          />
          <Route
            exact
            path={`${match.url}system/settings/sso-providers`}
            component={SsoProviders}
          />
          <Route
            exact
            path={`${match.url}system/developer`}
            render={(props) => <Developer {...props} key={props.location.hash} />}
          />
          <Route
            path={`${match.url}configuration/units/edit/:id`}
            render={(props) => <ManageUnit key="editUnit" {...props} />}
          />
          <Route
            path={`${match.url}configuration/units/new/`}
            component={ManageUnit}
          />
          <Route
            path={`${match.url}configuration/units`}
            render={(props) => <Units {...props} key={props.location.hash} />}
          />
          <Route
            path={`${match.url}configuration/organizations/edit/:id`}
            render={(props) => (
              <ManageOrganization key="editOrganization" {...props} />
            )}
          />
          <Route
            path={`${match.url}configuration/organizations/new`}
            component={ManageOrganization}
          />
          <Route
            exact
            path={`${match.url}configuration/beds-carts/uhe-units`}
            render={(props) => <UheUnits {...props} key={props.location.hash} />}
          />
          <Route
            path={`${match.url}configuration/beds-carts/uhe-units/edit/:id/:customer_id`}
            component={ManageUheBedCart}
          />
          <Route
            path={`${match.url}configuration/beds-carts/uhe-units/new`}
            component={CreateBedCart}
          />
          <Route
            exact
            path={`${match.url}configuration/beds-carts/non-uhe-units`}
            render={(props) => <NonUheUnits {...props} key={props.location.hash} />}
          />
          <Route
            path={`${match.url}configuration/beds-carts/non-uhe-units/edit/:id`}
            component={ManageNonUheBedCart}
          />
          <Route
            path={`${match.url}configuration/beds-carts/non-uhe-units/new`}
            component={CreateBedCart}
          />
          <Route
            path={`${match.url}configuration/beds-carts/uhe-units/new`}
            component={CreateBedCart}
          />
          <Route
            path={`${match.url}configuration/users/new/`}
            component={ManageUsers}
          />
          <Route
            path={`${match.url}configuration/users/bulk/upload`}
            component={BulkUpload}
          />
          <Route
            path={`${match.url}configuration/users/edit/:id`}
            component={ManageUsers}
          />
          <Route
            path={`${match.url}configuration/users`}
            render={(props) => <Users {...props} key={props.location.hash} />}
          />
          <Route
            path={`${match.url}configuration/mobile-patients/new/`}
            component={ManageMobilePatients}
          />
          <Route
            path={`${match.url}configuration/mobile-patients/bulk/upload`}
            component={BulkUpload}
          />
          <Route
            path={`${match.url}configuration/mobile-patients/edit/:id`}
            component={ManageMobilePatients}
          />
          <Route
            exact
            path={`${match.url}configuration/mobile-patients`}
            render={(props) => <MobilePatients {...props} key={props.location.hash} />}
          />
          <Route
            exact
            path={`${match.url}system/support`}
            component={Support}
          />
          <Route
            path={`${match.url}reporting/sign-in-summary`}
            render={(props) => <SignInReport {...props} key={props.location.hash} />}
          />
          <Route
            path={`${match.url}reporting/vmr-availability`}
            component={VmrReport}
          />
          <Route path={`${match.url}activate-user`} component={ActivateUser} />
          <Route
            path={`${match.url}change-password`}
            component={ResetPassword}
          />
          <Route
            path={`${match.url}technician`}
            component={Technician}
          />
          <Route
            exact
            path={`${match.url}system/notifications`}
            component={Notifications}
          />
          <Route
            exact
            path={`${match.url}system/notifications/edit/:id`}
            component={ManageNotifications}
          />
          <Route
            path={`${match.url}configuration/programs/new/`}
            component={ManagePrograms}
          />
          <Route
            path={`${match.url}configuration/programs/bulk/upload`}
            component={BulkUpload}
          />
          <Route
            exact
            path={`${match.url}configuration/programs`}
            component={Programs}
          />
          <Route
            path={`${match.url}configuration/programs/edit/:id`}
            component={ManagePrograms}
          />
        </Switch>
      )}
    </div>
  );
};

App.propTypes = {
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default App;
