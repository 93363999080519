import {
  PROGRAMS_LISTING_REQUEST,
  PROGRAMS_LISTING_SUCCESS,
  DELETE_PROGRAM_REQUEST,
  DELETE_PROGRAM_SUCCESS,
  SAVE_PROGRAM_SUCCESS,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_REQUEST,
  UPDATE_PROGRAM_SUCCESS,
  PROGRAM_GRANTS_REQUEST,
  PROGRAM_GRANTS_SUCCESS,
  UPDATE_PROGRAM_GRANTS_REQUEST,
  UPDATE_PROGRAM_GRANTS_SUCCESS,
  CLEAR_PROGRAM_STATE,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  table: {
    list: [],
    page: {},
  },
  selectedProgram: {},
  programGrants: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROGRAMS_LISTING_REQUEST: {
      return { ...state, loading: true };
    }

    case PROGRAMS_LISTING_SUCCESS: {
      const newState = { table: action.payload, loading: false };
      return { ...state, ...newState };
    }

    case DELETE_PROGRAM_REQUEST: {
      return { ...state, loading: true };
    }

    case DELETE_PROGRAM_SUCCESS: {
      return { ...state, selectedProgram: { ...INIT_STATE.selectedProgram }, loading: false };
    }

    case SAVE_PROGRAM_SUCCESS:
      return { ...state, loading: false, programId: action.payload.id };

    case GET_PROGRAM_REQUEST: {
      return { ...state, loading: true };
    }

    case GET_PROGRAM_SUCCESS: {
      return {
        ...state,
        selectedProgram: { ...action.payload },
        loading: false,
      };
    }

    case UPDATE_PROGRAM_REQUEST: {
      return { ...state, loading: true };
    }

    case UPDATE_PROGRAM_SUCCESS: {
      return {
        ...state,
        selectedProgram: { ...state.selectedProgram, ...action.payload },
        loading: false,
      };
    }

    case PROGRAM_GRANTS_REQUEST: {
      return { ...state, loading: true };
    }

    case PROGRAM_GRANTS_SUCCESS: {
      return {
        ...state, programGrants: { ...state.programGrants, ...action.payload }, loading: false,
      };
    }

    case UPDATE_PROGRAM_GRANTS_REQUEST: {
      return { ...state, loading: true };
    }

    case UPDATE_PROGRAM_GRANTS_SUCCESS: {
      return {
        ...state, programGrants: { ...state.programGrants, ...action.payload }, loading: false,
      };
    }

    case CLEAR_PROGRAM_STATE: {
      return { ...INIT_STATE };
    }

    default:
      return state;
  }
};
