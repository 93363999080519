import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  fetchSupportVideoDataSuccess,
  saveSupportVideoSuccess,
  fetchSupportDocumentDataSuccess,
  uploadDocumentSuccess,
  supportSectionOnFetchDataSuccess,
  addSectionSuccess,
  saveDocumentSuccess,
  deleteDocumentSuccess,
  getSubsectionDataSuccess,
} from '@actions/uhe/system/SupportActions';
import {
  SUPPORT_VIDEO_FETCH_DATA,
  CREATE_SUPPORT_VIDEO_REQUEST,
  DELETE_SUPPORT_VIDEO_REQUEST,
  SUPPORT_DOCUMENT_FETCH_DATA,
  CREATE_SUPPORT_DOCUMENT_REQUEST,
  SUPPORT_SECTION_FETCH_DATA,
  DOWNLOAD_PDF_REQUEST,
  DELETE_SUPPORT_DOCUMENT_REQUEST,
  UPDATE_SUPPORT_DOCUMENT_REQUEST,
  CREATE_SUPPORT_SECTION_REQUEST,
  DELETE_SUPPORT_SECTION_REQUEST,
  SUPPORT_SUBSECTION_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * Request data
 * @param {number} id parent_id
 * @return {Object} response
 */
const doFetchSubsectionData = async (id) => {
  const request = await RestManager.request(
    `${ENDPOINTS.support.subsectionSupport}${id}`,
  );
  const requestBody = await { ...request, parent_id: id };
  return requestBody;
};

/**
   * Request filtering video data
   * @param {object} data data
   * @return {Objects} data
   */
function* fetchSubsection(data) {
  try {
    const fetchedData = yield call(doFetchSubsectionData, data.payload);
    yield put(getSubsectionDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request data
 * @return {Object}
 */
const doFetchVideoData = async () => await RestManager.request(
  `${ENDPOINTS.support.videoSupport}`,
);

/**
   * @description Request filtering video data
   * @return {Objects}
   */
function* fetchVideoData() {
  try {
    const fetchedData = yield call(doFetchVideoData);
    yield put(fetchSupportVideoDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request for add new video
 * @param  {Object} bodyData
 * @return {Object}
 */
const saveVideoData = async (bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.saveVideo}`,
  'POST',
  bodyData,
);

/**
 * @description Save video
 * @param {Object} data
 * @returns {void}
 */
function* saveVideo(data) {
  try {
    const videoId = yield call(saveVideoData, data.payload);

    if (videoId) {
      yield put(saveSupportVideoSuccess(videoId));
      yield put(showMessage('videoAdded'));
      const fetchedData = yield call(doFetchVideoData);
      yield put(fetchSupportVideoDataSuccess(fetchedData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Delete request
 * @param {Number} id
 * @return  {Promise}
 */
const deleteVideo = async (id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.deleteVideo}/${id}`,
  'DELETE',
  { id },
);

/**
 * @description Delete video by given id
 * @param {string} data
 * @return {void}
 */
function* delVideo(data) {
  try {
    const deletedVideo = yield call(deleteVideo, data.payload.id);
    if (deletedVideo && deletedVideo.status >= 200 && deletedVideo.status < 300) {
      yield put(showMessage('videoDeleted'));
      const fetchedData = yield call(doFetchVideoData);
      yield put(fetchSupportVideoDataSuccess(fetchedData));
    } else {
      console.error('DELETE ERROR: ', deletedVideo);
      yield put(fetchError(deletedVideo));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

/**
 * Request data
 * @param {string} num specify api page number
 * @return {Object} request object data
 */
const doFetchDocumentData = async (num) => {
  const page = `?page=${num}`;
  const request = await RestManager.request(
    `${ENDPOINTS.support.documentSupport}${page}`,
  );
  return request;
};

/** Fetch document data
 * @return {Objects} data
 */
function* fetchDocumentData() {
  try {
    const fetchedData = yield call(doFetchDocumentData, '0');
    const numberOfRequests = fetchedData.page.totalPages;
    for (let i = 0; i < numberOfRequests; i += 1) {
      const numToString = i.toString();
      const updatedData = yield call(doFetchDocumentData, numToString);
      yield put(fetchSupportDocumentDataSuccess(updatedData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Upload document request
 * @param {Object} file data
 * @returns {Object} request data
 */
const uploadDocumentRequest = async (file) => await RestManager.formDataRequest(
  `${ENDPOINTS.support.documentSupport}`,
  file,
);

/**
 *  Handles Response and Request for Uploading CSV File
 * @returns {void}
 */
function* uploadDocument({ payload: { file, name, sectionId } }) {
  try {
    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('name', name);
    formData.append('section.id', sectionId);
    const { id: uploadedDocumentId } = yield call(uploadDocumentRequest, formData);

    if (uploadedDocumentId) {
      console.log(uploadedDocumentId, file.name, name, sectionId);
      yield put(uploadDocumentSuccess(uploadedDocumentId, file.name, name, sectionId));
      yield put(showMessage('documentUploaded'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 *  Request section data
 * @return {Object} data
 */
const doFetchSectionData = async () => await RestManager.request(
  `${ENDPOINTS.support.sectionSupport}`,
);

/**
 * Fetch section Data
 * @return {Objects} data
 */
function* fetchSectionData() {
  try {
    const fetchedData = yield call(doFetchSectionData);
    yield put(supportSectionOnFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Request download PDF
 * @param  {Number} id file id
 * @return {Object} data
 */
const doDownloadPdf = async (id) => await RestManager.requestFile(
  `${ENDPOINTS.support.download(id)}`,
);

/**
 * @description Download pdf file
 */
function* DownloadPdf({ payload: { id, name } }) {
  try {
    const res = yield call(doDownloadPdf, id);
    const url = URL.createObjectURL(res);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteDocumentRequest = (id) => RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.deleteDocument}/${id}`,
  'DELETE',
  { id },
);

/**
 * @description Delete document by given id
 * @param {string} data
 * @return {void}
 */
function* deleteDocument({ payload: id }) {
  try {
    const deletedDocument = yield call(deleteDocumentRequest, id);
    if (deletedDocument && deletedDocument.status >= 200 && deletedDocument.status < 300) {
      yield put(deleteDocumentSuccess(id));
      yield put(showMessage('documentDeleted'));
    } else {
      console.error('DELETE ERROR: ', deletedDocument);
      yield put(fetchError(deletedDocument));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

/**
 * @description Save document data
 * @param {number} id
 * @param {string} name
 * @returns {Promise}
 */
const saveDocumentRequest = (id, name) => RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.savedocument}/${id}`,
  'POST',
  { name },
);

/**
 * @description Save document
 * @param {number} id
 * @param {string} name
 * @returns {void}
 */
function* saveDocument({ payload: { id, name } }) {
  try {
    const savedDocument = yield call(saveDocumentRequest, id, name);
    if (savedDocument) {
      yield put(saveDocumentSuccess(savedDocument));
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request for add new section data
 * @param  {Object} bodyData
 * @return {Object}
 */
const addSectionData = async (bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.addSection}`,
  'POST',
  bodyData,
);

/**
 * @description Save section
 * @param {Object} data
 * @returns {void}
 */
function* addSection(data) {
  try {
    const sectionId = yield call(addSectionData, data.payload);

    if (sectionId) {
      yield put(addSectionSuccess(sectionId));
      yield put(showMessage('subsectionCreated'));
      const fetchedData = yield call(doFetchSectionData);
      yield put(supportSectionOnFetchDataSuccess(fetchedData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteSection = async (id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.support.deleteSection}/${id}`,
  'DELETE',
  { id },
);

/**
 * @description Delete document by given id
 * @param {string} data
 * @return {void}
 */
function* delSection(data) {
  try {
    const deletedSection = yield call(deleteSection, data.payload.id);
    if (deletedSection && deletedSection.status >= 200 && deletedSection.status < 300) {
      yield put(showMessage('subsectionDeleted'));
      const fetchedData = yield call(doFetchSectionData);
      yield put(supportSectionOnFetchDataSuccess(fetchedData));
    } else {
      console.error('DELETE ERROR: ', deletedSection);
      yield put(fetchError(deletedSection));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(SUPPORT_VIDEO_FETCH_DATA, fetchVideoData);
  yield takeEvery(CREATE_SUPPORT_VIDEO_REQUEST, saveVideo);
  yield takeEvery(DELETE_SUPPORT_VIDEO_REQUEST, delVideo);
  yield takeEvery(SUPPORT_DOCUMENT_FETCH_DATA, fetchDocumentData);
  yield takeEvery(CREATE_SUPPORT_DOCUMENT_REQUEST, uploadDocument);
  yield takeEvery(SUPPORT_SECTION_FETCH_DATA, fetchSectionData);
  yield takeEvery(DOWNLOAD_PDF_REQUEST, DownloadPdf);
  yield takeEvery(DELETE_SUPPORT_DOCUMENT_REQUEST, deleteDocument);
  yield takeEvery(UPDATE_SUPPORT_DOCUMENT_REQUEST, saveDocument);
  yield takeEvery(CREATE_SUPPORT_SECTION_REQUEST, addSection);
  yield takeEvery(DELETE_SUPPORT_SECTION_REQUEST, delSection);
  yield takeEvery(SUPPORT_SUBSECTION_REQUEST, fetchSubsection);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
