import {
    CONFIGURATION_UNITS_FETCH_SUCCESS,
    CONFIGURATION_UNITS_FETCH_DATA,
    DELETE_UNITS_SUCCESS,
  } from "@constants/UHEActionTypes";

  const INIT_STATE = {
    loading: true,
    table: {
      list: [],
      page: {},
    }
  };

  /**
   * @description Units reducer
   * @param  {Object} state
   * @param  {string} action
   * @return {Object}
   */
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case CONFIGURATION_UNITS_FETCH_SUCCESS:
        const newState = { 'table': action.data, loading: false }
        return Object.assign({}, state, newState);
        break;
      case CONFIGURATION_UNITS_FETCH_DATA:
        return Object.assign({}, state, { loading: true });
        break;
        case DELETE_UNITS_SUCCESS: {
          const newState = {
            ...state,
            table: {
              list: state.table.list.filter(i => i.id !== action.payload.id),
              page: {
                ...state.table.page,
                totalElements: state.table.page.totalElements - 1,
              },
            },
          };
          return newState;
        }

      default:
        return state;
    }
  }