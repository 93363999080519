import React from 'react';
import { injectIntl } from 'react-intl';

const StatusCell = ({ content, intl, statusFigure }) => {
  const { status, statusColor } = content;
 statusFigure = 'icon-rhombus';
  if(status === "healthy"){
    statusFigure= 'icon-uncheck-squire';
  }else if (status === "unhealthy"){
    statusFigure= "icon-circle";
  }
  return (
    <div className="uhe-table-td" title={intl.formatMessage({ id: `uhe.status.${status}` })} >
      <span className="status">
        <i className={`icon ${statusFigure} ${statusColor}`} />
      </span>
    </div>
  );
};

export default injectIntl(StatusCell);
