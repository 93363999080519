import {
  CONFIGURATION_MOBILE_PATIENTS_DATA,
  CONFIGURATION_MOBILE_PATIENTS_DATA_SUCCESS,
  DELETE_MOBILE_PATIENT_REQUEST,
  DELETE_MOBILE_PATIENT_REQUEST_SUCCESS,
  SAVE_MOBILE_PATIENT,
  SAVE_MOBILE_PATIENT_SUCCESS,
  FETCH_MOBILE_PATIENT,
  FETCH_MOBILE_PATIENT_SUCCESS,
  MOBILE_PATIENT_ACCESS_DATA_SUCCESS,
  SAVE_MOBILE_PATIENT_FAIL,
  FETCH_MDM_TOKEN,
  FETCH_MDM_TOKEN_SUCCESS,
  CLEAR_MOBILE_USER,
  UPLOAD_MOBILE_PATIENT_DOCUMENT_REQUEST,
  UPLOAD_MOBILE_PATIENT_DOCUMENT_SUCCESS,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  ownUser: {
    loading: true,
  },
  selectedMobilePatient: {},
  organization: [],
  customer: [],
  facilities: [],
  unit: [],
  loading: false,
  saved: false,
  table: {
    list: [],
    page: {},
  },
  editTable: [],
  token: {},
};

/**
 * @description Mobile Patients reducer
 * @param  {Object} state
 * @param  {{type, payload, data}} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONFIGURATION_MOBILE_PATIENTS_DATA_SUCCESS:
      return { ...state, ...{ table: action.data, loading: false } };
    case CONFIGURATION_MOBILE_PATIENTS_DATA:
      return { ...state, ...{ loading: true, saved: false } };
    case DELETE_MOBILE_PATIENT_REQUEST:
      return { ...state, loading: true };
    case DELETE_MOBILE_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        selectedMobilePatient: { ...INIT_STATE.selectedMobilePatient },
        loading: false,
      };
    case SAVE_MOBILE_PATIENT:
      return { ...state, loading: true };
    case SAVE_MOBILE_PATIENT_FAIL:
      return { ...state, loading: false };
    case SAVE_MOBILE_PATIENT_SUCCESS:
      return { ...state, loading: false, savedMobilePatientId: action.payload.id };
    case FETCH_MOBILE_PATIENT:
      return {
        ...state,
        loading: true,
        savedMobilePatientId: null,
      };
    case FETCH_MOBILE_PATIENT_SUCCESS: {
      const selectedMobilePatient = { ...state.selectedMobilePatient, ...action.payload };
      const newState = { selectedMobilePatient, loading: false };
      return { ...state, ...newState };
    }
    case FETCH_MDM_TOKEN:
      return {
        ...state,
        ...INIT_STATE,
        loading: true,
        savedMobilePatientId: null,
      };
    case FETCH_MDM_TOKEN_SUCCESS: {
      const token = { ...state.token, ...action.payload };
      const newState = { token, loading: false };
      return { ...state, ...newState };
    }
    case MOBILE_PATIENT_ACCESS_DATA_SUCCESS:
      return { ...state, editTable: action.payload, loading: false };
    case CLEAR_MOBILE_USER: {
      return { ...state, selectedMobilePatient: {} };
    }
    default:
      return state;
  }
};
