//ListingsTopFilterSaga
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import { unitsFetchDataSuccess } from '@actions/uhe/configuration/units/UnitsActions';
import { CONFIGURATION_UNITS_FETCH_DATA, DELETE_UNITS_REQUEST } from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Request data for units table
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Array} - Array of objects
 */
const doFetchData = async (page, sorting, filter) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  return await RestManager.request(`${ENDPOINTS.configuration.UnitsTable}?page=${page || 0}${sortingQueryString}${filterQueryString}`);
};

/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteUnitsAction = async (id) => {
  return await RestManager.requestWithoutQueryParams(`${ENDPOINTS.configuration.DeleteUnits}/${id}`, 'DELETE');
}

/**
 * @description Request filtering units table data
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Objects}
 */
function* fetchTableData({page, sorting, filter}) {
  try {
    const fetchedData = yield call(doFetchData, page, sorting, filter);
    yield put(unitsFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Delete unit by given id
 * @param {string} data
 * @return {void}
 */
function* delUnits(data) {
  try {
    const deletedUnits = yield call(deleteUnitsAction, data.payload.id);
    if (deletedUnits && deletedUnits.status < 400) {
      yield call(fetchTableData, { sorting: data.payload.sorting, page: data.payload.page, filter: data.payload.filter });
      yield put(showMessage('unitDeleted'));
    } else {
      console.error('DELETE ERROR: ', deletedUnits);
      yield put(fetchError(deletedUnits));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(CONFIGURATION_UNITS_FETCH_DATA, fetchTableData);
  yield takeEvery(DELETE_UNITS_REQUEST, delUnits);
}

export default function* rootSaga() {
  yield all(
    [
      fork(actionsWatcher),
    ]
  );
}
