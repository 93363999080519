import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import {
  Row, Col, Card, Table,
} from 'antd';
import { deleteNotificationPortal } from '@util/UheHelper';
import {
  NOTIFICATION_ROLE,
  ALL_ORGANIZATIONS,
  ORGANIZATION,
  CUSTOMER,
  FACILITY,
  UNIT,
} from '@constants/UHESettings';

/**
 * PermissionsListing Functional Component
 * @param {object} props PermissionsListing Component Props
 * @returns {JSX.Element} PermissionsListing Component
 */
const PermissionsListing = (props) => {
  const {
    loading,
    intl,
    permissionsData,
    isProgramPage,
    globalGrantsData,
  } = props;
  const [globalGrants, setGlobalGrants] = useState(null);
  useEffect(() => {
    if (!loading) {
      if (globalGrantsData) {
        setGlobalGrants(globalGrantsData);
      }
    }
  }, [globalGrantsData]);

  const permissionColumns = [
    {
      title: <IntlMessages id="configuration.users.adminRole" />,
      dataIndex: 'adminRole',
      width: '30%',
      editable: false,
      render: (text, record) => <div className="action-btns">{record.adminRole.value}</div>,
    },
    {
      title: <IntlMessages id="uhe.table.organization" />,
      dataIndex: 'organization',
      width: '30%',
      editable: false,
      render: (text, record) => {
        if (record.organization.value === 'All') {
          return (
            <strong className="action-btns">
              {record.organization.value}
            </strong>
          );
        }
        return (
          <div className="action-btns">{record.organization.value}</div>
        );
      },
    },
    {
      title: <IntlMessages id="uhe.table.customer" />,
      dataIndex: 'customer',
      width: '30%',
      editable: false,
      render: (text, record) => {
        if (record.customer.value === 'All') {
          return (
            <strong className="action-btns">{record.customer.value}</strong>
          );
        }
        return <div className="action-btns">{record.customer.value}</div>;
      },
    },
    {
      title: <IntlMessages id="uhe.table.facility" />,
      dataIndex: 'facility',
      width: '30%',
      editable: false,
      render: (text, record) => {
        if (record.facility.value === 'All') {
          return (
            <strong className="action-btns">{record.facility.value}</strong>
          );
        }
        return <div className="action-btns">{record.facility.value}</div>;
      },
    },
    {
      title: <IntlMessages id="uhe.table.unit" />,
      dataIndex: 'unit',
      width: '30%',
      editable: false,
      render: (text, record) => {
        if (record.unit.value === 'All') {
          return <strong className="action-btns">{record.unit.value}</strong>;
        }
        return <div className="action-btns">{record.unit.value}</div>;
      },
    },
    {
      title: <IntlMessages id="uhe.table.device" />,
      dataIndex: 'device',
      width: '30%',
      editable: false,
      render: (text, record) => {
        if (record.device.value && record.device.value !== 'All') {
          return (
            <div className="action-btns">
              {record.device.isMobile ? (
                <div>
                  <i className="icon icon-phone" />
                  {' '}
                  {`${record.device.value} `}
                </div>
              ) : (
                <div>
                  <i className="icon icon-data-display" />
                  {' '}
                  {record.device.value}
                  {' '}
                </div>
              )}
            </div>
          );
        }
        return (
          <strong className="action-btns">{record.device.value}</strong>
        );
      },
    },
  ];
  /**
   * Renders "All" Text
   * @returns {object} string value, key, id
   */
  const renderAllCol = () => ({
    value: intl.formatMessage({ id: 'common.all' }),
    key: 'All',
    id: 'All',
  });

  /**
   * Render rowsrenderHeadline
   * @param {Object} data Grants
   * @param {String} adminRole Admin Role
   * @param {Object} organization Organization Grants
   * @param {Object} customer Customer Grants
   * @param {Object} facility Facility Grants
   * @param {Object} unit Unit Grants
   * @param {Object} device Device Grants
   * @returns {void}
   */
  const renderRow = (
    data,
    adminRole,
    organization,
    customer,
    facility,
    unit,
    device,
  ) => {
    data.push({
      adminRole: {
        value: intl.formatMessage({
          id: `configuration.users.${adminRole}`,
        }),
        key: adminRole,
      },
      organization: organization
        ? {
          value: organization.name,
          key: organization.name,
          id: organization.organization,
        }
        : renderAllCol(),
      customer: customer
        ? {
          value: customer.name,
          key: customer.name,
          id: customer.customer,
        }
        : renderAllCol(),
      facility: facility
        ? {
          value: facility.name,
          key: facility.name,
          id: facility.facility,
        }
        : renderAllCol(),
      unit: unit
        ? {
          value: unit.name,
          key: unit.name,
          id: unit.unit,
        }
        : renderAllCol(),
      device: device
        ? {
          value: device.name,
          key: device.name,
          id: device.device || device.user,
          isMobile: !!device.user,
        }
        : renderAllCol(),
    });
    deleteNotificationPortal(data);
  };

  /**
   * Adapts Data to the Permissions Table
   * @param {Array} permissionData Permissions Table Data
   * @return {Array} Permissions Table Data
   */
  const dataAdapter = (permissionData = []) => {
    const adaptedData = [];
    const { iconsult_notifications, iobserver_notifications } = permissionData;
    const currentPermissionData = permissionData;
    if (currentPermissionData.super_admin) {
      Object.defineProperty(currentPermissionData, 'caregility_system_admin', Object.getOwnPropertyDescriptor(currentPermissionData, 'super_admin'));
      delete currentPermissionData.super_admin;
    }
    if (permissionData.iconsult_desktop && permissionData.iobserver) {
      currentPermissionData.notificationPortaliConsult = permissionData.iconsult_desktop;
      currentPermissionData.notificationPortaliObserver = permissionData.iobserver;
    }
    const keys = Object.keys(currentPermissionData);
    const sortedPermissions = keys.map((item) => (item === 'super_admin' ? 'caregility_system_admin' : item)).sort() || '';
    const ids = {
      organization: [],
      customer: [],
      facility: [],
      unit: [],
      device: [],
      mobileDevice: [],
      key: false,
    };
    if (
      sortedPermissions?.forEach((key) => {
        const currentPermission = currentPermissionData[key];
        if (currentPermission?.granted === true) {
          if (key === 'notificationPortaliConsult') {
            if (iconsult_notifications) {
              ids.key = !ids.key;
            } else {
              return null;
            }
          }
          if (key === 'notificationPortaliObserver' && (ids.key || !iobserver_notifications)) {
            return null;
          }
          renderRow(adaptedData, key);
        } else if (currentPermission?.nested) {
          currentPermission.nested.forEach((organization) => {
            if (organization.granted === true) {
              if (key === 'notificationPortaliConsult') {
                if (iconsult_notifications) {
                  ids.organization = [...ids.organization, organization.organization];
                } else {
                  return null;
                }
              }
              if (key === 'notificationPortaliObserver' && (ids.organization.includes(organization.organization) || !iobserver_notifications)) {
                return null;
              }

              renderRow(adaptedData, key, organization);
            } else if (organization && organization.nested) {
              organization.nested.forEach((customer) => {
                if (customer.granted === true) {
                  if (key === 'notificationPortaliConsult') {
                    if (iconsult_notifications) {
                      ids.customer = [...ids.customer, customer.customer];
                    } else {
                      return null;
                    }
                  }
                  if (key === 'notificationPortaliObserver' && (ids.customer.includes(customer.customer) || !iobserver_notifications)) {
                    return null;
                  }
                  renderRow(adaptedData, key, organization, customer);
                } else if (customer && customer.nested) {
                  customer.nested.forEach((facility) => {
                    if (facility.granted === true) {
                      if (key === 'notificationPortaliConsult') {
                        if (iconsult_notifications) {
                          ids.facility = [...ids.facility, facility.facility];
                        } else {
                          return null;
                        }
                      }
                      if (key === 'notificationPortaliObserver' && (ids.facility.includes(facility.facility) || !iobserver_notifications)) {
                        return null;
                      }
                      renderRow(
                        adaptedData,
                        key,
                        organization,
                        customer,
                        facility,
                      );
                    } else if (facility && facility.nested) {
                      facility.nested.forEach((unit) => {
                        if (unit.granted === true) {
                          if (key === 'notificationPortaliConsult') {
                            if (iconsult_notifications) {
                              ids.unit = [...ids.unit, unit.unit];
                            } else {
                              return null;
                            }
                          }
                          if (key === 'notificationPortaliObserver' && (ids.unit.includes(unit.unit) || !iobserver_notifications)) {
                            return null;
                          }
                          renderRow(
                            adaptedData,
                            key,
                            organization,
                            customer,
                            facility,
                            unit,
                          );
                        } else if (unit && unit.nested) {
                          const devices = unit.nested[0];
                          const mobileDevices = unit.nested[1];
                          if (devices.nested) {
                            devices.nested.forEach((device) => {
                              if (device.granted === true) {
                                if (key === 'notificationPortaliConsult') {
                                  if (iconsult_notifications) {
                                    ids.device = [...ids.device, device.device];
                                  } else {
                                    return null;
                                  }
                                }
                                if (key === 'notificationPortaliObserver' && (ids.device.includes(device.device) || !iobserver_notifications)) {
                                  return null;
                                }
                                renderRow(
                                  adaptedData,
                                  key,
                                  organization,
                                  customer,
                                  facility,
                                  unit,
                                  device,
                                );
                              }
                            });
                          }
                          if (mobileDevices.nested) {
                            mobileDevices.nested.forEach(
                              (mobileDevice) => {
                                if (mobileDevice.granted === true) {
                                  if (key === 'notificationPortaliConsult') {
                                    if (iconsult_notifications) {
                                      ids.mobileDevice = [...ids.mobileDevice, mobileDevice.user];
                                    } else {
                                      return null;
                                    }
                                  }
                                  if (key === 'notificationPortaliObserver' && (ids.mobileDevice.includes(mobileDevice.user) || !iobserver_notifications)) {
                                    return null;
                                  }
                                  renderRow(
                                    adaptedData,
                                    key,
                                    organization,
                                    customer,
                                    facility,
                                    unit,
                                    mobileDevice,
                                  );
                                }
                              },
                            );
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      })
    );
    return adaptedData;
  };

  /**
   * Renders the Card Title Based on the Page You Are
   * @returns {JSX.Element} Card Title
   */
  const renderCardTitle = () => {
    if (isProgramPage) {
      return (
        <IntlMessages
          id="configuration.programs.programPermissions"
        />
      );
    }
    return (
      <IntlMessages
        id="configuration.users.userPermission"
      />
    );
  };

  /**
   * Render Permissions Table
   * @returns {JSX.Element} Permissions Table
   */
  const renderTable = () => {
    const columns = permissionColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
        }),
      };
    });

    return (
      <>
        <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
          <Col lg={24} md={24} sm={24} sx={24}>
            <Card
              className="permission-table-card gx-card customer-edit-info-card"
              title={renderCardTitle()}
            >
              <Row lg={24} md={24} sm={24} sx={24} gutter={16}>
                <Table
                  className="permission-table gx-table-responsive"
                  rowClassName={() => 'editable-row'}
                  scroll={{ x: false }}
                  bordered
                  dataSource={dataAdapter({ ...permissionsData, iconsult_notifications: globalGrants ? globalGrants.iconsult_notifications : false, iobserver_notifications: globalGrants ? globalGrants.iobserver_notifications : false })}
                  columns={columns}
                  pagination={false}
                  loading={loading}
                  rowKey={(rec) => `${rec.address}_${Math.random() * 1000000000}`}
                />
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderTable()}
    </div>
  );
};

PermissionsListing.propTypes = {
  intl: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  permissionsData: PropTypes.shape().isRequired,
  isProgramPage: PropTypes.bool.isRequired,
};

export default withRouter(injectIntl(PermissionsListing));
