const getVideoList = (state) => state.SupportSettings?.videoData?.list || [];

const getDocumentsList = (state) => state.SupportSettings?.documentData?.list || [];

const getSectionsList = (state) => state.SupportSettings?.sectionData?.list || [];

const getSubsection = (state) => state.SupportSettings?.subsectionData?.list || [];

const getCurrentParentID = (state) => state.SupportSettings?.subsectionData?.parent_id || [];

const getLoading = (state) => state.SupportSettings.loading;

const getSectionsWithMappedDocuments = (state) => {
  const sections = getSectionsList(state);
  const videos = getVideoList(state);
  const documents = getDocumentsList(state);
  return sections.map((section) => ({
    ...section,
    documents: documents.filter((x) => x.section.id === section.id),
    videos: videos.filter((x) => x.section.id === section.id),
  }));
};

export {
  getCurrentParentID,
  getSubsection,
  getVideoList,
  getLoading,
  getSectionsWithMappedDocuments,
};
