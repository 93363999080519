import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Cascader, Select } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import { onGetOptions, fetchOptionsSuccess } from '@uhe_actions/filters/ListingsTopFilterActions';
import { TOP_FILTER_PREFIX, TABLE_FILTER_PREFIX, USER_ROLES } from '@constants/UHESettings';
import { cascaderSearchFilter } from '@util/UheHelper';
import { isCaregilitySystemAdmin, isCaregilityAdmin } from '@util/UheRoleChecker';

const { Option } = Select;

class ListingsTopFilter extends React.Component {
  constructor(props) {
    super(props);

    this.selectedValues = {};
    this.isUsers = this.props.location.pathname.indexOf('/users') !== -1;
    this.qParams = new URLSearchParams(this.props.history.location.search);
    this.presetFiltersFromURL();
    this.clearFilter = this.clearFilter.bind(this);
    this.clearFiltersOnSelectionChange = this.clearFiltersOnSelectionChange.bind(this);
    this.selectRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(this.props.location.search);
      this.presetFiltersFromURL();
    }
  }

  /**
   * Presets the filters if there are any in the query params
   * Loads the data conditionally
   *
   * @return {void}
   */
  presetFiltersFromURL() {
    const { filters } = this.props;

    filters.forEach((config, index) => {
      const { isPrevIndependant, hasOwnValues } = config;
      let queryFilterValue = this.qParams.get(`${TOP_FILTER_PREFIX}${config.key}`);
      const castedToNumber = Number(queryFilterValue);
      queryFilterValue = Number.isNaN(castedToNumber) ? queryFilterValue : castedToNumber;
      const prevItem = index ? filters[index - 1] : null;
      const prevSelected = !index || !!(prevItem && this.selectedValues[prevItem.key]);
      this.selectedValues[config.key] = (prevSelected || isPrevIndependant) && queryFilterValue ? [queryFilterValue] : undefined;

      if (queryFilterValue && config.key === 'usersRole' && config.getIndexValue) {
        this.selectedValues[config.key] = queryFilterValue.split(',').map((filter) => config.getIndexValue(filter));
      }

      if ((!index || isPrevIndependant) && !hasOwnValues) {
        this.props.onGetOptions(config.key, null, config.shouldSort);
      } else if (prevItem && this.selectedValues[prevItem.key]) {
        this.props.onGetOptions(config.key, this.selectedValues[prevItem.key], config.shouldSort);
      }
    });
  }

  /**
   * Renders the "Clear Filter" button if enabled
   *
   * @return {ReactElement|null}
   */
  renderClearFilterButton() {
    const { showClearFiltersButton } = this.props;

    if (!showClearFiltersButton) {
      return null;
    }

    const statusFilter = this.isFilterActive();

    return (
      <button alt="Reset Button" type="reset" className="ant-btn reset-btn" onClick={this.clearFilter} disabled={!statusFilter}>
        <FormattedMessage id="uhe.listingsTopFilter.clearFiltersButtonLbl" />
      </button>
    );
  }

  /**
   * Returns true if any of the widgets filters are active
   *
   * @return {Boolean}
   */
  isFilterActive() {
    const activeFilters = Object.values(this.selectedValues).reduce((active, filter) => {
      if (filter) {
        active += 1;
      }
      return active;
    }, 0);

    return activeFilters > 0;
  }

  /**
   * Clears the whole filter
   *
   * @return {void}
   */
  clearFilter() {
    this.selectedValues = {};
    this.onFilterChange([], 0);
  }

  /**
   * Clear all filters after the selected change
   *
   * @param {index} index
   * @param {filters} filters
   */
  clearFiltersOnSelectionChange(index, filters) {
    for (let i = index + 1; i < filters.length; i++) {
      this.qParams.delete(`${TOP_FILTER_PREFIX}${filters[i].key}`);
    } 
  }

  /**
   * Triggered when input is cleared or option is selected
   *
   * @param  {Array<string>} value
   * @param  {number} index
   * @return {void}
   */
  onFilterChange(value, index) {
    const { filters, history } = this.props;
    const { key } = filters[index];
    value && value.length ? this.selectedValues[key] = value : delete this.selectedValues[key];

    if (!value || !value.length) {
      for (let i = index; i < filters.length; i++) {
        this.qParams.delete(`${TOP_FILTER_PREFIX}${filters[i].key}`);
      }
    } else {
      this.qParams.set(`${TOP_FILTER_PREFIX}${key}`, value);
      this.clearFiltersOnSelectionChange(index, filters);
    }

    history.push({ search: this.qParams.toString() });

    if (this.props.customerHandler) {
      this.props.customerHandler(value);
    }
  }

  /**
   * @description Handle Changes on the Role Input
   * @param {Object} value
   * @param {Object} selection
   * @param {Number} index
   * @returns {void}
   */
  onRoleChange = (value, selection, index) => {
    const { filters, history } = this.props;
    const { key } = filters[index];
    let filterValue = '';

    if (filters[index].getValue && typeof filters[index].getValue === 'function') {
      const seletedRoles = value.map((i) => filters[index].getValue(Number(i)));
      filterValue = seletedRoles.join(',');
    }

    filterValue && filterValue.length ? this.selectedValues[key] = value : delete this.selectedValues[key];

    if (!filterValue || !filterValue.length) {
      for (let i = index; i < filters.length; i++) {
        this.qParams.delete(`${TOP_FILTER_PREFIX}${filters[i++].key}`);
      }
    } else {
      this.qParams.set(`${TOP_FILTER_PREFIX}${key}`, filterValue);
    }

    history.push({ search: this.qParams.toString() });
  }

  /**
   * Stop event propagation on Enter key pressed.
   * @param {object} event Event data
   * @returns {void}
   */
  blurSelectRef = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      this.selectRef.current.blur();
    }
  };

  /**
   * Renders filter inputs
   *
   * @return {Array<ReactElement>|null}
   */
  renderFilterDropdowns() {
    const {
      filters, optionsList, intl, loggedUser,
    } = this.props;
    const isSuperAdmin = loggedUser && !loggedUser.isCaregilitySystemAdmin;
    const isCareAdmin = loggedUser && !loggedUser.isCaregilityAdmin;

    if (!filters || !Array.isArray(filters)) {
      return null;
    }

    const inputs = [];

    filters.forEach((config, index) => {
      const prevItem = index ? filters[index - 1] : null;
      const tableFilterExists = !!this.qParams.get(`${TABLE_FILTER_PREFIX}${config.key}`);
      const prevSelected = !!(prevItem && this.selectedValues[prevItem.key]);
      const disabled = 'disabled' in config ? config.disabled : tableFilterExists || (!prevSelected && index);
      let userRoles = USER_ROLES;
      if (config.key === 'usersRole') {
        if (isCaregilitySystemAdmin(loggedUser) || isCaregilityAdmin(loggedUser)) {
          userRoles = USER_ROLES;
        } else {
          userRoles = [...userRoles].splice(2);
        }
      }
      const roleOptions = userRoles.map((role, index) => (
        <Option
          autoComplete={Math.random()}
          value={index}
          key={index}
          className={`${
            (role.super_admin && isSuperAdmin)
            && (role.caregility_admin && isCareAdmin)
              ? 'check-user-role'
              : ''
          }`}
        >
          {intl.formatMessage({
            id: `configuration.users.${Object.keys(role)[0]}`,
          })}
        </Option>
      ));
      if (config.key === 'usersRole') {
        inputs.push(
          <Select
            autoComplete={Math.random()}
            className="role-input"
            mode="multiple"
            value={this.selectedValues[config.key]}
            onChange={(value, options) => this.onRoleChange(value, options, index)}
            placeholder={intl.formatMessage({ id: config.placeholder })}
            size="large"
            key={index}
            optionFilterProp="children"
            ref={this.selectRef}
            onInputKeyDown={this.blurSelectRef}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {roleOptions}
          </Select>,
        );
      } else {
        inputs.push(
          <Cascader
            autoComplete={Math.random()}
            className="users-input"
            popupClassName="top-filter-popup"
            fieldNames={config.fieldNames}
            key={config.key}
            expandTrigger="hover"
            size="large"
            changeOnSelect
            value={this.selectedValues[config.key]}
            options={optionsList[config.key] || []}
            onChange={(value) => this.onFilterChange(value, index)}
            placeholder={intl.formatMessage({ id: config.placeholder })}
            showSearch={{ filter: cascaderSearchFilter }}
            disabled={!!disabled}
          />
        );
      }
    });

    return inputs;
  }

  /**
   * Renders the component
   *
   * @return {ReactElement}
   */
  render() {
    const { cssClass } = this.props;
    return (
      <div className={`listings-top-filter ${cssClass}`}>
        <div className={`filter-inputs ${this.isUsers ? 'users-top-filters' : ''}`}>
          {this.renderFilterDropdowns()}
          {this.renderClearFilterButton()}
        </div>
      </div>
    );
  }
}

ListingsTopFilter.defaultProps = {
  showClearFiltersButton: true,
  cssClass: '',
};

ListingsTopFilter.propTypes = {
  fieldNames: PropTypes.shape(),
  intl: PropTypes.shape().isRequired,
  filters: PropTypes.array.isRequired,
  cssClass: PropTypes.string,
  showClearFiltersButton: PropTypes.bool,
};

const mapStateToProps = ({ listingsTopFilter }) => ({ optionsList: listingsTopFilter });

export default connect(mapStateToProps, {
  onGetOptions,
  fetchOptionsSuccess,
})(injectIntl(withRouter(ListingsTopFilter)));
