import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Card, Row, Col, Select, Input, Switch, Form, Popover, Checkbox,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import EditHeader from '@components/uhe/configuration/EditHeader.jsx';
import Branding from '@components/uhe/configuration/customer/Branding.jsx';
import OrganizationReports from '@components/uhe/configuration/organization/OrganizationReports.jsx';
import * as organizationActions from '@uhe_actions/configuration/organizations/OrganizationsActions';
import { organizationFetchDataCustomers, organizationFetchDataFacility }
  from '@uhe_actions/configuration/organizations/OrganizationsActions';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import countryList from 'react-select-country-list';
import CustomerCell from '@components/tables/cells/CustomerCell';
import AddressCell from '@components/tables/cells/AddressCell';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import PropTypes from 'prop-types';
import {
  STATES_OPTIONS,
  CANADIAN_PROVINCES,
  FICAL_YEAR,
  NUMBERS_PATTERN,
  COUNTRIES,
  URL_PATTERN
} from '@constants/UHESettings';
import {
  goBackTo,
  cascaderCustomerFiscalFilter,
  searchSelectFilter,
  getTimeZones
} from '@util/UheHelper';
import { withLastLocation } from 'react-router-last-location';
import AuditInfo, { AuditTypes } from '@components/uhe/configuration/AuditInfo';
import {
  shouldDisableOrganisationInputFields,
} from '@util/UheRoleChecker';

const { Option } = Select;

const states_options = STATES_OPTIONS.map((item, index) => (
  <Option key={index}>{item}</Option>
));

const canadian_provinces = CANADIAN_PROVINCES.map((item, index) => (
  <Option key={index}>{item}</Option>
));

const months_options = FICAL_YEAR.map((item, index) => (
  <Option key={index} value={index}>{item}</Option>
));

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const addressLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

/**
 * ManageOrganization Class Component
 */
class ManageOrganization extends Component {
  formRef = React.createRef();

  /**
   * @description Get all countries
   * @returns {Array}
   */
  static loadCountries() {
    const countries = countryList().getData();
    const countriesList = ['United States', 'Canada'];

    countries.filter((countryName) => countryName.label !== 'United States' && countryName.label !== 'Canada')
      .forEach((country) => countriesList.push(country.label));

    return countriesList;
  }

  /**
   * @description Adapts the data from the API
   * @param {Array} data
   * @returns {Array}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        customer: {
          id: value.id,
          name: value.name,
        },
      });
    });

    return adaptedData;
  }

  /**
   * @description Adapts the data from the API
   * @param {Array} dataFacility
   * @returns {Array}
   */
  static dataAdapterFacility(dataFacility = []) {
    const adaptedDataFacility = [];

    dataFacility.forEach((value, index) => {
      adaptedDataFacility.push({
        key: index,
        facility: {
          id: value.id,
          name: value.name,
        },
      });
    });

    return adaptedDataFacility;
  }

  constructor(props) {
    super(props);
    this.isNew = this.props.location.pathname.indexOf('/new') !== -1;
    this.urlId = this.props.match.params.id;

    const newOrganization = {
      id: '',
      name: '',
      alias: '',
      streetAddres: '',
      cityAddress: '',
      zip: '',

      // new field
      additional_link_1: '',
      additional_link_2: '',
      address_city: '',
      address_country: '',
      address_state: '',
      address_street: '',
      address_zip_code: '',
      fiscal_year_start: '',
      main_phone_number: '',
      organization_alias: '',
      shipping_address_street: '',
      shipping_address_zip_code: '',
      time_zone: '',
      updated_by: '',
      website: '',

      branding: {
        banner_color: '',
        font_color: '',
        attachment: null,
      },
      advanced_reports: {
        active: 0,
        username: '',
        dashboard: '',
      },
    };

    this.intl = this.props.intl;

    this.state = {
      organization: this.isNew ? { ...newOrganization } : { ...props.organization },
      errors: {},
      loading: props.isFetching,
      isSameAddress: false,
    };

    this.initState = this.state;

    this.saveOrganization = this.saveOrganization.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageCahngeFacility = this.onPageCahngeFacility.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);

    if (this.isNew) {
      if (this.props.subtitle.langId !== 'configuration.organizations.title_new') {
        this.props.setSubtitle('configuration.organizations.title_new');
      }
    } else if (this.props.subtitle.langId !== 'configuration.organizations.title_edit') {
      this.props.setSubtitle('configuration.organizations.title_edit');
    }

    this.columns = [];
    this.culomnsFacility = [];

    this.tableKeys = [
      'customer',
      'address',
      'city',
      'state',
      'zip',
      'timezone',
      'comment',
    ];

    this.tableKeyFasility = [
      'facility',
      'address',
      'city',
      'state',
      'zip',
      'timezone',
      'comment',
    ];

    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={false}
          />
        ),
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.tableKeyFasility.forEach((value, index) => {
      this.culomnsFacility.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={false}
          />
        ),
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRendererFacility(content, value),
      });
    });

    this.onPageChange(1);
    this.onPageCahngeFacility(1);

  }

  /**
     * @param {string} content
     * @param {string} key
     * @returns {JSX}
     */
  cellRenderer(content, key) {
    let cell;
    switch (key) {
      case 'customer':
        cell = <CustomerCell content={content} showIco />;
        break;
      case 'address':
        cell = <AddressCell content={content} />;
        break;
      default:
        cell = content;
    }
    return cell;
  }

  /**
     * @param {string} content
     * @param {string} key
     * @returns {JSX}
     */
  cellRendererFacility(content, key) {
    let cell;
    const { intl } = this.props;

    switch (key) {
      case 'facility':
        cell = <CustomerCell content={content} showIco />;
        break;
      default:
        cell = content;
    }
    return cell;
  }

  /**
   * @description Update local state
   * @returns {void}
   */
  componentDidMount() {
    const urlId = this.props.match.params.id;
    if (urlId && urlId !== this.props.organization.id) {
      this.props.actions.fetchOrganization({ id: urlId });
    }
  }

  /**
   * @description Update local state depends on the props
   * @param {object} prevProps
   */
  componentDidUpdate(prevProps) {
    const { organization, loggedUser } = this.props;
    const organization_data = this.state.organization;

    if (prevProps.error !== this.props.error) {
      // handle system error
      if (this.props.error.code === 404 && !this.isNew) {
        this.props.history.push('/configuration/organizations');
      }
    }

    if (this.isNew && organization.id !== prevProps.organization.id) {
      this.props.history.push(`/configuration/organizations/edit/${organization.id}`);
      return;
    }

    if (!this.isNew && organization.id !== prevProps.organization.id) {
      this.setState({ organization });
    }

    this.formRef.current.setFieldsValue({
      name: organization_data.name,
      website: organization_data.website,
      additional_link_1: organization_data.additional_link_1,
      additional_link_2: this.state.organization.additional_link_2,
      address_city: organization_data.address_city,
      address_country: organization_data.address_country,
      address_state: organization_data.address_state,
      address_street: organization_data.address_street,
      address_zip_code: organization_data.address_zip_code,
      fiscal_year_start: organization_data.fiscal_year_start,
      main_phone_number: organization_data.main_phone_number,
      organization_alias: organization_data.organization_alias,
      shipping_address_city: organization_data.shipping_address_city,
      shipping_address_country: organization_data.shipping_address_country,
      shipping_address_state: organization_data.shipping_address_state,
      shipping_address_street: organization_data.shipping_address_street,
      shipping_address_zip_code: organization_data.shipping_address_zip_code,
      time_zone: organization_data.time_zone,
      updated_by: organization_data.updated_by,
    });
  }

  saveOrganization = (event) => {
    event.persist();
    event.preventDefault();

    this.formRef.current.validateFields()
      .then((values) => {
        const { organization } = this.state;
        const { actions } = this.props;
        actions.saveOrganization(organization);
      })
      .catch((info) => {
        console.log('info:', info);
      });
  }

  /**
   * @description Event handler for onChange event fired in the Branding config section
   * @param {string} fieldName
   * @param {string} fieldValue
   */
  onChangeBrandingHandler = (fieldName, fieldValue) => {
    if (fieldValue) {
      const { organization } = this.state;
      organization.branding[fieldName] = fieldValue.val;
      this.setState({ organization });
    }
  };

  /**
   * Set same shipping address as main address
   * @returns {Void} function
   */
  setShippingAddress() {
    const { organization } = this.state;
    const {
      address_street,
      shipping_address_street,
      address_country,
      address_city,
      address_state,
      address_zip_code,
    } = organization;

    organization.shipping_address_street = address_street;
    organization.shipping_address_country = address_country;
    organization.shipping_address_city = address_city;
    organization.shipping_address_state = address_state;
    organization.shipping_address_zip_code = address_zip_code;
    this.setState({
      ...shipping_address_street,
      shipping_address_street: address_street,
      shipping_address_country: address_country,
      shipping_address_city: address_city,
      shipping_address_state: address_state,
      shipping_address_zip_code: address_zip_code,
    });
  }

  /**
   * Common event handler for onChange event fired from the inputs
   * @param {object} event true or false
   * @param {string} fieldName input string
   * @param {string} sectionName of input
   * @param {string} fieldValue of input
   * @returns {String} of input field
   */
  onChangeHandler = (event, fieldName, sectionName, fieldValue) => {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    const { organization } = this.state;
    const newOrgState = { ...organization };
    const valueToSet = fieldValue
      ? fieldValue.val
      : event && event.target && event.target.value
        ? event.target.value
        : '';

    if (sectionName) {
      newOrgState[sectionName][fieldName] = valueToSet;
    } else {
      newOrgState[fieldName] = valueToSet;
    }
    const isShipping = fieldName.includes('shipping');
    this.setState({ organization: newOrgState }, () => {
      if (organization.shipping_address_same_as_main === true && !isShipping) {
        this.setShippingAddress();
      }
    });
  }

  /**
   * Change Country
   * @param {string} values countries
   * @returns {string} country
   */
  onChangeCountry = (values) => {
    const { organization } = this.state;
    organization.address_country = values;
    organization.address_state = null;
    this.setState({ organization }, () => {
      if (organization.shipping_address_same_as_main === true) {
        this.setShippingAddress();
      }
    });
  }

  /**
   * @description Change Shipping Country
   * @param {string} values
   */
  onChangeShippingCountry = (values) => {
    const { organization } = this.state;
    organization.shipping_address_country = values;
    organization.shipping_address_state = null;
    this.setState({ organization });
  }

  /**
   * Changes State Address
   * @param {string} key of state
   * @returns {string} of state
   */
  onChangeStateAddress = (key) => {
    const { organization } = this.state;
    const options = organization.address_country === 'Canada' ? canadian_provinces : states_options;
    organization.address_state = options[key].props.children;
    this.setState({ organization }, () => {
      if (organization.shipping_address_same_as_main === true) {
        this.setShippingAddress();
      }
    });
  };

  /**
   * @description Renders State Address according to selected country
   * @returns Select with Options or Input
   */
  renderStreetStateField = () => {
    const { organization } = this.state;
    const { loggedUser } = this.props;
    if (organization.address_country !== 'United States' && organization.address_country !== 'Canada') {
      return (
        <Input
          autoComplete={Math.random()}
          name="address_state"
          type="text"
          value={organization.state}
          placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.state' })}
          title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.state' })}
          onChange={(event) => this.onChangeHandler(event, 'address_state')}
          disabled={shouldDisableOrganisationInputFields(loggedUser)}
        />
      );
    }
    const options = organization.address_country === 'Canada' ? canadian_provinces : states_options;

    return (
      <Select
        showSearch
        filterOption={cascaderCustomerFiscalFilter}
        placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.state' })}
        onChange={this.onChangeStateAddress}
        disabled={shouldDisableOrganisationInputFields(loggedUser)}
      >
        {options}
      </Select>
    );
  }

  /**
   * @description Change Shippping Address
   * @param {string} index
   * @param {Object} test
   */
  onChangeStateShippingAddress = (key) => {
    const { organization } = this.state;
    const options = organization.shipping_address_country === 'Canada' ? canadian_provinces : states_options;
    organization.shipping_address_state = options[key].props.children;
    this.setState({ organization });
  };

  /**
   * Renders State Address according to selected country
   * @return {JSX} Select with Options or Input
   */
  renderShippingStateField = () => {
    const { organization } = this.state;
    const { loggedUser, intl } = this.props;
    if (organization.shipping_address_country !== 'United States' && organization.shipping_address_country !== 'Canada') {
      return (
        <Input
          autoComplete={Math.random()}
          name="shipping_address_state"
          type="text"
          value={organization.shipping_address_state}
          placeholder={intl.formatMessage({ id: 'configuration.organizations.edit.state' })}
          title={intl.formatMessage({ id: 'configuration.organizations.edit.state' })}
          onChange={(event) => this.onChangeHandler(event, 'shipping_address_state')}
          disabled={
            shouldDisableOrganisationInputFields(loggedUser)
            || organization.shipping_address_same_as_main
          }
        />
      );
    }
    const options = organization.shipping_address_country === 'Canada' ? canadian_provinces : states_options;

    return (
      <Select
        placeholder={intl.formatMessage({ id: 'configuration.organizations.edit.state' })}
        showSearch
        filterOption={cascaderCustomerFiscalFilter}
        onChange={this.onChangeStateShippingAddress}
        disabled={
          shouldDisableOrganisationInputFields(loggedUser)
          || organization.shipping_address_same_as_main
        }
      >
        {options}
      </Select>
    );
  }

  /**
   * Change Time zone
   * @param {string} values
   */
  onChangeTimeZone = (values) => {
    const { organization } = this.state;

    organization.time_zone = values;
    this.setState({ organization });
  }

  /**
   * @description Change Fiscal Year
   * @param {string} values
   */
  onChangeFiscalYearAddress = (value) => {
    const { organization } = this.state;
    organization.fiscal_year_start = value;

    this.setState({ organization });
  };

  /**
   * @description Render title, back and save buttons
   */
  renderHeadLine() {
    const { editMode, isFetching, lastLocation, history } = this.props;
    const titleKey = editMode
      ? 'configuration.organizations.title_edit'
      : 'configuration.customer.title_add_new';

    return (
      <EditHeader
        goBack={goBackTo('/configuration/organizations', lastLocation?.pathname, history)}
        save={this.saveOrganization}
        loading={isFetching}
        titleKey={titleKey}
      />
    );
  }

  onPageChange(page) {
    this.currentPage = page - 1;
    this.props.organizationFetchDataCustomers(page - 1);
  }

  onPageCahngeFacility(page) {
    this.currentPage = page - 1;
    this.props.organizationFetchDataFacility(page - 1);
  }

  /**
   * Renders AuditInfo component
   * @param {number} auditType Use AuditType for setting this field
   * @returns {JSX.Element} AuditInfo Component
   */
  renderAuditInfo(auditType) {
    const { organization } = this.state;
    return (
      <AuditInfo
        className="manageOrganization__status--auditInfo"
        type={auditType}
        data={organization}
      />
    );
  }

  /**
   * If it's true and Address is populated then populate shipping address
   * @param {Object} event of checkbox
   * @returns {Object} data
   */
  onChangeCheckBox(event) {
    this.setState({ isSameAddress: event.target.checked }, () => {
      const { organization, isSameAddress } = this.state;
      organization.shipping_address_same_as_main = isSameAddress;
      this.setState({ organization });
      if (organization.shipping_address_same_as_main === true) {
        this.setShippingAddress();
      }
    });
  }

  /**
   * Renders ManageOrganization Component
   * @returns {JSX.Element} ManageOrganization
   */
  render() {
    const { isFetching, editMode, loggedUser } = this.props;
    const { organization, newOrganization } = this.state;
    const { address_country, shipping_address_country, shipping_address_same_as_main } = organization;
    const {
      pagination,
    } = this.props;

    pagination.onChange = this.onPageChange;
    this.props.paginationFacility.onChange = this.onPageCahngeFacility;

    const countries = ManageOrganization.loadCountries();
    const countries_options = countries.map((val, index) => (
      <Option key={index + 1} value={val}>
        {val}
      </Option>
    ));
    return (
      <div className="manage-customer-wrapper">
        <Form ref={this.formRef} name="manage-organizations" {...layout} autoComplete="off">
          {this.renderHeadLine()}
          <Row gutter={16} className="form-item-row">
            <Col lg={24} md={24} sm={24} xs={24} className="organization-row">
              <Card
                className="gx-card"
                loading={isFetching}
              >
                <Form.Item
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="configuration.organizations.edit.name" />}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                    {
                      max: 100,
                      message: 'Must not be more that 100 characters',
                    },
                  ]}
                >
                  <Input
                    autoComplete={Math.random()}
                    name="name"
                    type="text"
                    value={organization.name}
                    placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.name' })}
                    title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.name' })}
                    onChange={(event) => this.onChangeHandler(event, 'name')}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                  />
                </Form.Item>
                <Form.Item
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="configuration.organizations.edit.alias.title" />}
                  name="organization_alias"
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                    {
                      min: 2,
                      message: this.intl.formatMessage({ id: 'common.minCharAlias' }),
                    },
                    {
                      max: 6,
                      message: 'Must not be more that 6 characters',
                    },
                  ]}
                >
                  <Input
                    autoComplete={Math.random()}
                    name="organization_alias"
                    type="text"
                    value={this.state.organization.alias}
                    placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.alias.title' })}
                    title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.alias.description' })}
                    onChange={(event) => this.onChangeHandler(event, 'organization_alias')}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                  />
                </Form.Item>
                <Row className="align-row" gutter={16}>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      {...addressLayout}
                      className="align-item-city form-item-row org-address-item"
                      label={<IntlMessages id="configuration.organizations.edit.organization_address.title" />}
                      name="address_street"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          max: 50,
                          message: 'Must not be more that 50 characters',
                        },
                      ]}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="address_street"
                        type="text"
                        value={organization.address}
                        placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.organization_address.title' })}
                        title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.organization_address.description' })}
                        onChange={(event) => this.onChangeHandler(event, 'address_street')}
                        disabled={shouldDisableOrganisationInputFields(loggedUser)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={5} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row align-label"
                      {...addressLayout}
                      label={<IntlMessages id="configuration.organizations.edit.country" />}
                      name="address_country"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 10 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 14 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      <Select
                        autoComplete={Math.random()}
                        showSearch
                        onChange={(value) => { this.onChangeCountry(value); }}
                        disabled={shouldDisableOrganisationInputFields(loggedUser)}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 'fit-content' }}
                      >
                        {countries_options}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city-form form-item-row align-label"
                      {...addressLayout}
                      label={<IntlMessages id="configuration.organizations.edit.city" />}
                      name="address_city"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          max: 50,
                          message: 'Must not be more that 50 characters',
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 10 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 14 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="address_city"
                        type="text"
                        value={organization.city}
                        placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.organization_city.title' })}
                        title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.organization_city.description' })}
                        onChange={(event) => this.onChangeHandler(event, 'address_city')}
                        disabled={shouldDisableOrganisationInputFields(loggedUser)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row align-label"
                      {...addressLayout}
                      label={<IntlMessages id="configuration.organizations.edit.state" />}
                      name="address_state"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      {this.renderStreetStateField()}
                    </Form.Item>
                  </Col>
                  <Col xl={3} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row align-label"
                      {...addressLayout}
                      label={<IntlMessages id="configuration.organizations.edit.zip" />}
                      name="address_zip_code"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          max: 20,
                          message: <IntlMessages id="common.minCharactersMessage" />,
                        },
                        {
                          pattern: address_country === COUNTRIES.usa
                            || address_country === COUNTRIES.usaIslands
                            ? NUMBERS_PATTERN
                            : '',
                          message: <IntlMessages id="common.onlyNumbersMessage" />,
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 10 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 14 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="address_zip_code"
                        type="text"
                        value={organization.zip}
                        placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.organization_zip.title' })}
                        title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.organization_zip.description' })}
                        onChange={(event) => this.onChangeHandler(event, 'address_zip_code')}
                        disabled={shouldDisableOrganisationInputFields(loggedUser)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row lg={24} md={24} sm={24} xs={24} gutter={16} className="form-item-row align-roww">
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      className="form-item-row"
                      label={(
                        <span>
                          <IntlMessages id="configuration.organizations.shippingAddressIsTheSame" />
                        </span>
                      )}
                      labelCol={{
                        xl: { span: 0 },
                        lg: { span: 0 },
                        md: { span: 0 },
                        sm: { span: 0 },
                      }}
                      wrapperCol={{
                        xl: { span: 1 },
                        lg: { span: 1 },
                        md: { span: 1 },
                        sm: { span: 1 },
                      }}
                    >
                      <Checkbox
                        type="checkbox"
                        onChange={(e) => this.onChangeCheckBox(e)}
                        checked={shipping_address_same_as_main}
                        disabled={shouldDisableOrganisationInputFields(loggedUser)}
                        longdesc={this.intl.formatMessage({
                          id: 'configuration.organizations.shippingAddressIsTheSame',
                        })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="align-row" gutter={16}>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      {...addressLayout}
                      className="align-item-city form-item-row org-address-item"
                      label={<IntlMessages id="configuration.organizations.edit.shipping_address.title" />}
                      name="shipping_address_street"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          max: 50,
                          message: 'Must not be more that 50 characters',
                        },
                      ]}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="shipping_address_street"
                        type="text"
                        value={organization.shipping_address_street}
                        placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.shipping_address.title' })}
                        title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.shipping_address.description' })}
                        onChange={(event) => this.onChangeHandler(event, 'shipping_address_street')}
                        disabled={shouldDisableOrganisationInputFields(loggedUser) || organization.shipping_address_same_as_main}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={5} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row align-label"
                      {...addressLayout}
                      label={<IntlMessages id="configuration.organizations.edit.country" />}
                      name="shipping_address_country"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 10 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 14 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      <Select
                        autoComplete={Math.random()}
                        showSearch
                        onChange={(value) => { this.onChangeShippingCountry(value); }}
                        disabled={shouldDisableOrganisationInputFields(loggedUser) || organization.shipping_address_same_as_main}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: 'fit-content' }}
                      >
                        {countries_options}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city-form form-item-row align-label"
                      {...addressLayout}
                      label={<IntlMessages id="configuration.organizations.edit.city" />}
                      name="shipping_address_city"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          max: 50,
                          message: 'Must not be more that 50 characters',
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 10 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 14 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="shipping_address_city"
                        type="text"
                        value={organization.shipping_address_city}
                        placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.shipping_city.title' })}
                        title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.shipping_city.description' })}
                        onChange={(event) => this.onChangeHandler(event, 'shipping_address_city')}
                        disabled={shouldDisableOrganisationInputFields(loggedUser) || organization.shipping_address_same_as_main}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row align-label"
                      {...addressLayout}
                      label={<IntlMessages id="configuration.organizations.edit.state" />}
                      name="shipping_address_state"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      {this.renderShippingStateField()}
                    </Form.Item>
                  </Col>
                  <Col xl={3} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row align-label"
                      {...addressLayout}
                      label={<IntlMessages id="configuration.organizations.edit.zip" />}
                      name="shipping_address_zip_code"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                        },
                        {
                          max: 20,
                          message: <IntlMessages id="common.minCharactersMessage" />,
                        },
                        {
                          pattern: shipping_address_country === COUNTRIES.usa
                            || shipping_address_country === COUNTRIES.usaIslands
                            ? NUMBERS_PATTERN
                            : '',
                          message: <IntlMessages id="common.onlyNumbersMessage" />,
                        },
                      ]}
                      labelCol={{
                        xl: { span: 10 },
                        lg: { span: 10 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                      wrapperCol={{
                        xl: { span: 14 },
                        lg: { span: 14 },
                        md: { span: 12 },
                        sm: { span: 12 },
                      }}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="shipping_address_zip_code"
                        type="text"
                        value={organization.shipping_address_zip_code}
                        placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.shipping_zip.title' })}
                        title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.shipping_zip.description' })}
                        onChange={(event) => this.onChangeHandler(event, 'shipping_address_zip_code')}
                        disabled={shouldDisableOrganisationInputFields(loggedUser) || organization.shipping_address_same_as_main}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="configuration.organizations.edit.phone.title" />}
                  name="main_phone_number"
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                    {
                      min: 6,
                      message: this.intl.formatMessage({ id: 'configuration.users.minPhoneNumberLength' }),
                    },
                    {
                      max: 20,
                      message: this.intl.formatMessage({ id: 'configuration.users.maxPhoneNumberLength' }),
                    },
                    {
                      pattern: NUMBERS_PATTERN,
                      message: this.intl.formatMessage({ id: 'configuration.users.phoneNumberFormat' }),
                    },
                  ]}
                >
                  <Input
                    className="align-item-city-input"
                    autoComplete={Math.random()}
                    name="main_phone_number"
                    type="text"
                    value={organization.main_phone_number}
                    placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.phone.title' })}
                    title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.phone.description' })}
                    onChange={(event) => this.onChangeHandler(event, 'main_phone_number')}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                  />
                </Form.Item>
                <Form.Item
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="configuration.organizations.edit.timezone.organization" />}
                  name="time_zone"
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Select
                    className="time-zone"
                    placeholder={this.intl.formatMessage({ id: 'configuration.customer.timeZone' })}
                    showSearch
                    onChange={(value) => { this.onChangeTimeZone(value); }}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                    filterOption={searchSelectFilter}
                  >
                    {getTimeZones()}
                  </Select>
                </Form.Item>
                <Form.Item
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="configuration.organizations.edit.fiscal_year.title" />}
                  name="fiscal_year_start"
                  rules={[
                    {
                      required: true,
                      message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                    },
                  ]}
                >
                  <Select
                    className="align-item-city-select"
                    name="fiscal_year_start"
                    placeholder="Fiscal Year"
                    showSearch
                    filterOption={cascaderCustomerFiscalFilter}
                    onChange={this.onChangeFiscalYearAddress}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                  >
                    {months_options}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="website"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="configuration.organizations.edit.website.title" />}
                  rules={[
                    {
                      pattern: URL_PATTERN,
                      message: <IntlMessages id="configuration.organizations.edit.website.invalidUrl" />,
                    },
                  ]}
                >
                  <Input
                    autoComplete={Math.random()}
                    name="website"
                    type="text"
                    value={organization.website}
                    placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.website.title' })}
                    title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.website.description' })}
                    onChange={(event) => this.onChangeHandler(event, 'website')}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                  />
                </Form.Item>
                <Form.Item
                  name="additional_link_1"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="configuration.organizations.edit.link.title1" />}
                  rules={[
                    {
                      pattern: URL_PATTERN,
                      message: <IntlMessages id="configuration.organizations.edit.website.invalidUrl" />,
                    },
                  ]}
                >
                  <Input
                    autoComplete={Math.random()}
                    name="additional_link_1"
                    type="text"
                    value={organization.additional_link_1}
                    placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.link.title1' })}
                    title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.link.description' })}
                    onChange={(event) => this.onChangeHandler(event, 'additional_link_1')}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                  />
                </Form.Item>
                <Form.Item
                  name="additional_link_2"
                  className="align-item-city form-item-row"
                  label={<IntlMessages id="configuration.organizations.edit.link.title2" />}
                  rules={[
                    {
                      pattern: URL_PATTERN,
                      message: <IntlMessages id="configuration.organizations.edit.website.invalidUrl" />,
                    },
                  ]}
                >
                  <Input
                    autoComplete={Math.random()}
                    name="additional_link_2"
                    type="text"
                    value={organization.additional_link_2}
                    placeholder={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.link.title2' })}
                    title={this.props.intl.formatMessage({ id: 'configuration.organizations.edit.link.description' })}
                    onChange={(event) => this.onChangeHandler(event, 'additional_link_2')}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                  />
                </Form.Item>
                <Form.Item
                  className="align-item-city form-item-row"
                  label={(
                    <Popover content={<IntlMessages id="configuration.organizations.edit.popoverCameraReboot" />} placement="topRight">
                      <span>
                        <IntlMessages id="configuration.organizations.edit.camera_connection" />
                      </span>
                    </Popover>
                  )}
                >
                  <Switch
                    defaultChecked
                    checked={!!organization.auto_reboot}
                    onChange={(value) => this.onChangeHandler(null, 'auto_reboot', null, { val: value ? 1 : 0 })}
                    disabled={shouldDisableOrganisationInputFields(loggedUser)}
                  />
                </Form.Item>
                {!this.isNew && <Row>
                  <Form.Item
                    className="align-item-city form-item-row"
                    label={<IntlMessages id="uhe.table.iConsultNo" />}
                    labelCol={{
                      xl: { span: 20 },
                      lg: { span: 20 },
                      md: { span: 20 },
                      sm: { span: 20 },
                    }}
                    wrapperCol={{
                      xl: { span: 4 },
                      lg: { span: 4 },
                      md: { span: 4 },
                      sm: { span: 4 },
                    }}
                  >
                    {organization.total_iconsult_users}
                  </Form.Item>
                  <Form.Item
                    className="align-item-city form-item-row"
                    label={<IntlMessages id="uhe.table.iObserverNo" />}
                    labelCol={{
                      xl: { span: 20 },
                      lg: { span: 18 },
                      md: { span: 20 },
                      sm: { span: 20 },
                    }}
                    wrapperCol={{
                      xl: { span: 4 },
                      lg: { span: 4 },
                      md: { span: 4 },
                      sm: { span: 4 },
                    }}
                  >
                    {organization.total_iobserver_users}
                  </Form.Item>
                </Row>}
                {this.renderAuditInfo(AuditTypes.createdAt)}
                {this.renderAuditInfo(AuditTypes.updatedAt)}
              </Card>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Branding
                branding={organization.branding}
                loading={isFetching}
                onChangeHandler={this.onChangeBrandingHandler}
                iconUrl={`${BASE_URL}${ENDPOINTS.organization.fetchById}`}
                disabled={shouldDisableOrganisationInputFields(loggedUser)}
              />
            </Col>
            <Col lg={16} md={16} sm={24} xs={24} className="hideReporting">
              <OrganizationReports
                organization={organization}
                editMode={editMode}
                loading={isFetching}
                onChangeHandler={this.onChangeHandler}
              />
            </Col>
          </Row>
          {this.renderHeadLine()}
        </Form>
      </div>
    );
  }
}

ManageOrganization.defaultProps = {
  loading: true,
  showIco: false,
};

ManageOrganization.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  dataFacility: PropTypes.array,
  history: PropTypes.object,
  lastLocation: PropTypes.object,
  intl: PropTypes.shape().isRequired,
  loggedUser: PropTypes.shape().isRequired,
};

function mapStateToProps({
  ConfigurationOrganizations, subtitle, common, ConfigurationUsers,
}, ownProps) {
  const urlId = ownProps.match.params.id || ''; // from the path `.../organization/:id`

  const { list, page } = ConfigurationOrganizations.customerTable || { page: {}, list: [] };
  const facilityTable = ConfigurationOrganizations.facilityTable || { page: {}, list: [] };

  const pagination = {
    total: page.totalElements || 0,
  };
  const paginationFacility = {
    total: facilityTable.page.totalElements || 0,
  };

  const { loading } = ConfigurationOrganizations;

  ConfigurationOrganizations.selectedOrganization.auto_reboot = ConfigurationOrganizations.selectedOrganization.auto_reboot
    ? ConfigurationOrganizations.selectedOrganization.auto_reboot
    : 0;

  ConfigurationOrganizations.selectedOrganization.acc_active = ConfigurationOrganizations.selectedOrganization.acc_active
    ? ConfigurationOrganizations.selectedOrganization.acc_active
    : 1;

  return {
    urlId,
    error: common.error,
    subtitle,
    saved: !!ConfigurationOrganizations.saved,
    organization: ConfigurationOrganizations.selectedOrganization,
    isFetching: ConfigurationOrganizations.loading,
    data: list,
    pagination,
    paginationFacility,
    loading,
    dataFacility: facilityTable.list,
    loggedUser: ConfigurationUsers.ownUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSubtitle: (langId) => dispatch(setSubtitle(langId)),
    actions: bindActionCreators(organizationActions, dispatch),

    organizationFetchDataCustomers: (page, sort, filter) => dispatch(organizationFetchDataCustomers(
      page, sort, filter,
    )),

    organizationFetchDataFacility: (page, sort, filter) => dispatch(organizationFetchDataFacility(
      page, sort, filter,
    )),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageOrganization))));
