import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

/**
 * Renders Uhe Unit Cell in the Tables
 * @param {object} content UheUnitCell Data
 * @returns {JSX.Element} Link
 */
const UheUnitCell = ({ content }) => {
  const intl = useIntl();
  const { id, name, customerId } = content;

  return (
    <div className="uhe-table-td">
      <Link to={`/configuration/beds-carts/uhe-units/edit/${id}/${customerId}`} title={intl.formatMessage({ id: 'sidebar.view' })}>
        <span>{name}</span>
      </Link>
    </div>
  );
};

export default UheUnitCell;
