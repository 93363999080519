import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { onFetchData } from '@uhe_actions/monitoring/UHEActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import lodash from 'lodash';
import { LISTING_TABLES_PAGE_SIZE, TABLE_FILTER_PREFIX } from '@constants/UHESettings';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import { Table } from 'antd';
import IntlMessages from 'util/IntlMessages';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import AddressCell from '@components/tables/cells/AddressCell';
import NonUheUnitCell from '@components/tables/cells/NonUheUnitCell';
import StatusCell from '@components/tables/cells/StatusCell';
import LastEventCell from '@components/tables/cells/LastEventCell';
import { getCurrentSort } from '@util/UheHelper';

/**
 * Renders NonUhe Page
*/
class NonUhe extends React.Component {
  /**
   * Adapts data returned from the server
   * @param  {array} data Data from server
   * @return {array} Adapted data
   */
  static dataAdapter(data = []) {
    const adaptedData = [];
    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization_id,
          name: value.organization_name,
        },
        customer: {
          id: value.customer_id,
          organizationId: value.organization_id,
          name: value.customer_name,
        },
        facility: {
          id: value.facility_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          name: value.facility_name,
        },
        unit: {
          id: value.unit_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          facilityId: value.facility_id,
          name: value.unit_name,
        },
        bedCart: {
          id: value.device_id,
          name: value.cart_name,
          customerId: value.customer_id,
        },
        deviceId: value.device_id,
        protocol: value.dial_out_type,
        address: {
          id: value.device_id,
          name: value.alias,
        },
        status: {
          status: value.status,
          statusColor: value.status_color,
        },
        lastEvent: value.last_event,
        type: value.type,
      });
    });

    return adaptedData;
  }

  /**
   * Uhe Constructor
   * @param {object} props Props
   */
  constructor(props) {
    super(props);

    const { intl, history } = this.props;
    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.data = [];
    this.columns = [];
    this.tableFilterMap = {
      organization: 'organization_name',
      customer: 'customer_name',
      facility: 'facility_name',
      unit: 'unit_name',
      bedCart: 'cart_name',
      deviceId: 'device_id',
      status: 'status',
      lastEvent: 'last_event',
      type: 'type',
      protocol: 'dial_out_type',
      address: 'alias',
    };
    this.tableKeys = [
      'organization',
      'customer',
      'facility',
      'unit',
      'bedCart',
      'deviceId',
      'status',
      'lastEvent',
      'type',
      'protocol',
      'address',
    ];

    this.filterTypes = {
      status: {
        type: 'dropdown',
        options: [
          { value: 'not_configured', label: intl.formatMessage({ id: 'uhe.table.filter.status.not_configured' }) },
          { value: 'healthy', label: intl.formatMessage({ id: 'uhe.table.filter.status.healthy' }) },
          { value: 'unhealthy', label: intl.formatMessage({ id: 'uhe.table.filter.status.unhealthy' }) },
        ],
      },
      lastEvent: {
        type: 'dropdown',
        options: [
          { value: '1', label: 'to 1 minute' },
          { value: '30', label: 'to 30 minutes' },
          { value: '60', label: 'to 1 hour' },
          { value: '360', label: 'to 6 hours' },
          { value: '720', label: 'to 12 hours' },
          { value: '1440', label: 'to 1 day' },
          { value: '10080', label: 'to 1 week' },
          { value: '44640', label: 'to 1 month' },
          { value: '525600', label: 'to 1 year' },
          { value: '5256000', label: 'to 10 years' },
        ],
      },
    };

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
          />
        ),
        sorter: { multiple: index },
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.numberInputs = {
      deviceId: 'number',
    };

    this.onPageChange(1);
  }

  /**
   * Content executed on first component mount
   * @return {void}
   */
  componentDidMount() {
    const { setSubtitle } = this.props;
    setSubtitle('standarts.title');
  }

  /**
   * Updates the component on location change
   * @param {Object} prevProps Previous props
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /**
   * OnPageChange function which is executed on page change to get correct items
   * @param  {number} page New Page
   * @return {void}
   */
  onPageChange = (page) => {
    const { onFetchData } = this.props;
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else if (this.numberInputs[key]) {
          filter.push(`${value}=${encodeURIComponent(Number(filterParam))}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    const sort = getCurrentSort(currSort, this.tableFilterMap);

    this.filter = filter;
    this.sort = sort;

    onFetchData(page - 1, sort, filter);
  }

  /**
   * Renders different cell type depending on the column
   * @param  {Object} content Content
   * @param  {string} key Key
   * @return {ReactElement|Object} Different cell component based on column
   */
  cellRenderer(content, key) {
    let cell;
    const { location } = this.props;
    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'facility':
        cell = <FacilityCell content={content} />;
        break;
      case 'unit':
        cell = <UnitCell content={content} location={location} />;
        break;
      case 'bedCart':
        cell = <NonUheUnitCell content={content} />;
        break;
      case 'address':
        cell = <AddressCell editLink={`/configuration/beds-carts/non-uhe-units/edit/${content.id}`} content={content} />;
        break;
      case 'status':
        cell = <StatusCell content={content} />;
        break;
      case 'lastEvent':
        cell = <LastEventCell content={content} />;
        break;
      default:
        cell = content;
    }

    return cell;
  }

  /**
   * Renders Table
   * @returns {JSX.Element} Table
   */
  renderTable = () => {
    const {
      pagination,
      data,
      loading,
    } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <Table
        bordered
        className="gx-table-responsive"
        columns={this.columns}
        dataSource={NonUhe.dataAdapter(data)}
        pagination={pagination}
        loading={loading}
      />
    );
  }

  /**
   * Renders NonUhe Component
   * @returns {JSX.Element} NonUhe Component
   */
  render() {
    const { pagination } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <>
        <div className="gx-justify-content-end">
          <p className="gx-d-flex gx-justify-content-end">
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <div className="uhe-table">
          {this.renderTable()}
        </div>
      </>
    );
  }
}

NonUhe.defaultProps = {
  location: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
};

NonUhe.propTypes = {
  location: PropTypes.objectOf(PropTypes.string),
};

/**
 * Maps Redux store state to this.props
 * @param {Array} state Redux State
 * @returns {object} Plain object containing data from Redux store state
*/
const mapStateToProps = (state) => {
  const { list, page } = state.MonitoringStandards.table || {};
  const { loading } = state.MonitoringStandards;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };
  const loggedUser = state.ConfigurationUsers.ownUser;

  return {
    data: list,
    pagination,
    loading,
    loggedUser,
  };
};

/**
 * Maps dispatchable actions to this.props
 * @param {Array} dispatch Dispatch
 * @returns {String} Plain object containing dispatchable actions
*/
const mapDispatchToProps = (dispatch) => ({
  onFetchData: (page, sort, filter) => dispatch(onFetchData(page, sort, filter, 'monitoring-standards')),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(NonUhe)));
