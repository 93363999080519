import React from 'react';
import {
  Row,
  Col,
  Card,
  Input,
  Checkbox,
  Button,
  Select,
  message,
  Form,
} from 'antd';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  systemOnFetchData,
  saveSystem,
  sendTestEmail,
  fetchLicensing,
  sendLicensingKey,
  fetchApplicationVersion,
} from '@uhe_actions/system/SystemActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

const { Option } = Select;

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
);

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

/**
 * Renders System Page Component
 */
class System extends React.Component {
  /**
   * System Component Constructor
   * @param {object} props System Component Props
   * @returns {void}
   */
  constructor(props) {
    super(props);
    const {
      intl,
      subtitle,
      setSubtitle
    } = this.props;
    this.intl = intl;
    this.state = {
      data: {},
      mail: {},
      license_key: '',
    };

    if (subtitle && subtitle.langId !== 'system.title') {
      setSubtitle('system.title');
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onLicenseKeyChange = this.onLicenseKeyChange.bind(this);
  }

  /**
   * Updates local state
   * @returns {void}
   */
  componentDidMount() {
    const {
      systemOnFetchData,
      fetchLicensing,
      fetchApplicationVersion
    } = this.props;
    systemOnFetchData();
    fetchLicensing();
    fetchApplicationVersion();
  }

  /**
   * @description Save data
   * @return {Object}
   */
  onSaveButtonClick(event) {
    event.persist();
    event.preventDefault();

    const { saveSystemData } = this.props;
    const { data } = this.state;
    const { sendLicensingKey } = this.props;
    const { license_key } = this.state;

    saveSystemData(data);
    if (license_key !== '') {
      sendLicensingKey({ license_key });
    }
  }

  /**
   * @description Watch for empty data and if is empty set new state data
   * @param {Object} prevProps
   * @return {Object}
   */
  componentDidUpdate(prevProps) {
    if (
      Object.keys(prevProps.data).length === 0
      && Object.keys(this.props.data).length > 0
    ) {
      this.setState({ data: { ...this.props.data } });
    }
  }

  /**
   * Event for change value in input fields
   * @param {string} key property
   * @param {Object} event of changed data
   * @returns {String} of event
   */
  onChangeSystemData(key, event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [key]: { ...data[key], value: event.target.value },
      },
    });
  }

  /**
   * Event for change value in checkbox fields
   * @param {string} key property
   * @param {Object} event of changed data
   * @returns {String} of event
   */
  onChangeCheckbox(key, event) {
    const { data } = this.state;
    const newState = event.target.checked ? 1 : 0;
    this.setState({
      data: {
        ...data,
        [key]: { ...data[key], value: newState },
      },
    });
  }

  /**
   *Send Test Email
   * @param {Object} e of changed data
   * @returns {String} of event
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const { mail } = this.state;
    const { sendTestEmail } = this.props;
    if (emailRegex.test(mail)) {
      message.success(this.intl.formatMessage({ id: 'common.sentEmail' }));
      sendTestEmail({ mail });
    }
  };

  /**
   * @description Change input value
   */
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => console.log(this.state));
  };

  /**
   * @description Handle Changes in the 'Update Key' Input and Updates the Local State
   * @param {Object} event
   * @returns {void}
   */
  onLicenseKeyChange(event) {
    this.setState({
      license_key: event.target.value,
    });
  }

  /**
   * @description Converts timestamp into more readable date format
   * @param {string} timestamp
   * @returns {string}
   */
  convertTimestamp = (timestamp) => {
    const convertedDate = moment.utc(timestamp).format('MMMM DD, YYYY hh:mm:ss UTC');

    return convertedDate;
  }

  /**
   * Renders System Component
   * @returns {JSX} System Component
   */
  render() {
    const { mail } = this.state;
    const isEmailValid = emailRegex.test(mail);
    const { data } = this.state;
    const { licensing, version, loading } = this.props;
    const hasVersion = process && process.env && process.env.REACT_APP_VERSION;
    if (Object.keys(data).length === 0) {
      return null;
    }

    return (
      <div className="manage-customer-wrapper system">
        <Form {...layout} autoComplete={Math.random()}>
          <Row gutter={16}>
            {/* Save Button */}
            <Col lg={24} md={24} sm={24} xs={24} className="save-btn-container gx-d-flex gx-justify-content-end">
              <Button
                title="Save"
                alt="Save link"
                className="save-icon header-btn gx-d-flex gx-justify-content-between"
                htmlType="submit"
                onClick={this.onSaveButtonClick}
              >
                <i className="icon icon-check" />
                <IntlMessages id="configuration.save" />
              </Button>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={9} md={9} sm={9} sx={24}>
              {/* Licensing */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.licensing" />}
                loading={loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.licenseIssued" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>{licensing.To}</div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.licenseExpires" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      {licensing.Expires
                        ? moment(licensing.Expires).format(
                          'MMMM Do YYYY hh:mm:ss',
                        )
                        : ''}
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.timeRemaining" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      {licensing.Expires
                        ? moment(licensing.Expires).endOf('hour').fromNow()
                        : ''}
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.rooms" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      {licensing.Units}
                      <IntlMessages id="system.usedOf" />
                      {licensing.No_Of_Units}
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.admins" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      {licensing.Admins}
                      <IntlMessages id="system.usedOf" />
                      {licensing.No_Of_Admins}
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.clinicians" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      {licensing.Clinicians}
                      <IntlMessages id="system.usedOf" />
                      {licensing.No_Of_Clinicians}
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.iobservers" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      {licensing.Observers}
                      <IntlMessages id="system.usedOf" />
                      {licensing.No_Of_Observers}
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.technicians" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      {licensing.Technicians}
                      <IntlMessages id="system.usedOf" />
                      {licensing.No_Of_Technicians}
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.updateKey" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      id="aSettingsUsername"
                      name="name"
                      type="text"
                      onChange={this.onLicenseKeyChange}
                    />
                  </Col>
                </Row>
              </Card>
              {/* VMR Settings - All */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.vmrSettingsAll" />}
                loading={this.props.loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.vmrServerTag" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('server_tag', e);
                      }}
                      defaultValue={data?.server_tag?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.useUnderScoreBefore" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('server_tag_underscore', e);
                      }}
                      defaultValue={data?.server_tag_underscore?.value}
                    />
                  </Col>
                </Row>
              </Card>
              {/* VMR Settings - Clinician */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.vmrSettingsClinician" />}
                loading={this.props.loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.vmrPool" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('vmr_on_deck_amount', e);
                      }}
                      defaultValue={data?.vmr_on_deck_amount?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.vmrPoolByChannel" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData(
                          'vmr_on_deck_by_channel_amount',
                          e,
                        );
                      }}
                      defaultValue={data?.vmr_on_deck_by_channel_amount?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.prefixTag" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('clinician_vmr_prefix', e);
                      }}
                      defaultValue={data?.clinician_vmr_prefix?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.useUnderscoreAfterPrefix" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData(
                          'clinician_prefix_underscore',
                          e,
                        );
                      }}
                      defaultValue={data?.clinician_prefix_underscore?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.themeURL" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('pexip_theme_uri', e);
                      }}
                      value={data?.pexip_theme_uri?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.pinLength" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('clinician_vmr_pin_length', e);
                      }}
                      value={data?.clinician_vmr_pin_length?.value}
                    />
                  </Col>
                </Row>
              </Card>
              {/* VMR Settings - Meetings */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.vmrSettingsMeetings" />}
                loading={loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.vmrPool" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData(
                          'vmr_meeting_on_deck_amount',
                          e,
                        );
                      }}
                      defaultValue={data?.vmr_meeting_on_deck_amount?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.prefixTag" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('outpatient_vmr_prefix', e);
                      }}
                      defaultValue={data?.outpatient_vmr_prefix?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.useUnderscoreAfterPrefix" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData(
                          'outpatient_prefix_underscore',
                          e,
                        );
                      }}
                      defaultValue={data?.outpatient_prefix_underscore?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.themeURL" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('pexip_meeting_theme_uri', e);
                      }}
                      defaultValue={data?.pexip_meeting_theme_uri?.value}
                    />
                  </Col>
                </Row>
              </Card>
              {/* LDAP Settings */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.ldapSettings" />}
                loading={loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.useLdap" />
                    </div>
                  </Col>
                  <Col lg={2} md={24} sm={24} xs={24}>
                    <Checkbox
                      type="checkbox"
                      onChange={(e) => {
                        this.onChangeCheckbox('use_ldap', e);
                      }}
                      checked={Number(data?.use_ldap?.value) === 1}
                    />
                  </Col>
                  <Col lg={1} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="common.yes" />
                    </div>
                  </Col>
                </Row>
              </Card>
              {/* Third Party Mailer Template */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.thirdPartyMailerTemplate" />}
                loading={loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.useSystem" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Checkbox
                      type="checkbox"
                      onChange={(e) => {
                        this.onChangeCheckbox('third_party_mailer_template_system', e);
                      }}
                      checked={
                        Number(data?.third_party_mailer_template_system?.value) === 1
                      }
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.userId" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('third_party_mailer_user_id', e);
                      }}
                      defaultValue={data?.third_party_mailer_user_id?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.userPassword" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData(
                          'third_party_mailer_user_password',
                          e,
                        );
                      }}
                      defaultValue={data?.third_party_mailer_user_password?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.clientUniqueId" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('third_party_mailer_unique_id', e);
                      }}
                      defaultValue={data?.third_party_mailer_unique_id?.value}
                    />
                  </Col>
                </Row>
              </Card>
              {/* Twilio Settings */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.twilioSettings" />}
                loading={loading}
              >
                <Form onClick={this.handleSubmit} autoComplete={Math.random()}>
                  <Row className="baseLineAlignedItems" gutter={16}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <div>
                        <IntlMessages id="system.phoneNumbers" />
                      </div>
                    </Col>
                    <Col lg={6} md={24} sm={24} xs={24}>
                      <Link
                        to="/system/settings/system/twilio"
                        className="sendButton viewButton "
                      >
                        <IntlMessages id="system.viewButton" />
                      </Link>
                    </Col>
                  </Row>
                  <Row className="baseLineAlignedItems" gutter={16}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <div>
                        <IntlMessages id="system.messagingServiceId" />
                      </div>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <Input
                        autoComplete={Math.random()}
                        type="text"
                        onChange={(e) => {
                          this.onChangeSystemData('twilio_messaging_service_sid', e);
                        }}
                        defaultValue={data?.twilio_messaging_service_sid?.value}
                      />
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
            <Col lg={15} md={15} sm={15} xs={24}>
              {/* Version Settings */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.versionSettings" />}
                loading={loading}
              >
                <Row gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.applicationVersion" />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      {hasVersion ? hasVersion.split('.').splice(0, 3).join('.') : ''}
                      {' '}
                      /
                      {version.version}
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.buildDate" />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      {hasVersion ? this.convertTimestamp(new Date(hasVersion.split('.').splice(3, 1) * 1000).toISOString()) : ''}
                      {' '}
                      /
                      {this.convertTimestamp(version.timestamp)}
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.patientUnitVersion" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('production_version', e);
                      }}
                      defaultValue={data?.production_version?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.unitPackageName" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('version_file_name', e);
                      }}
                      defaultValue={data?.version_file_name?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.webAppVersion" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('web_app_version', e);
                      }}
                      defaultValue={data?.web_app_version?.value}
                    />
                  </Col>
                </Row>
              </Card>
              {/* System Settings */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.systemSettings" />}
                loading={this.props.loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.websocketConnection" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('server_name', e);
                      }}
                      defaultValue={data?.server_name?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.inviteEmailLinkServer" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('invite_server_name', e);
                      }}
                      defaultValue={data?.invite_server_name?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.reportingURL" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('layerx_address', e);
                      }}
                      defaultValue={data?.layerx_address?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.alertNotificationAction" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Select defaultValue={data?.alert_message_sending?.value}>
                      <Option value="sms">
                        {data?.alert_message_sending?.value}
                      </Option>
                      <Option value="email">
                        {this.intl.formatMessage({
                          id: 'system.alertNotificationEmail',
                        })}
                      </Option>
                    </Select>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.releaseUnderway" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Checkbox
                      type="checkbox"
                      onChange={(e) => {
                        this.onChangeCheckbox('release_underway', e);
                      }}
                      checked={
                        Number(data?.release_underway?.value) === 1
                      }
                    />
                  </Col>
                </Row>
              </Card>
              {/* Reporting Settings */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.reportingSettings" />}
                loading={this.props.loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.showSystemReports" />
                    </div>
                  </Col>
                  <Col lg={1} md={24} sm={24} xs={24}>
                    <Checkbox
                      type="checkbox"
                      onChange={(e) => {
                        this.onChangeCheckbox('system_use_layerx', e);
                      }}
                      checked={
                        Number(data?.system_use_layerx?.value) === 1
                      }
                    />
                  </Col>
                  <Col lg={1} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="common.yes" />
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="configuration.customer.username" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('system_layerx_username', e);
                      }}
                      defaultValue={data?.system_layerx_username?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.dashboardID" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData(
                          'system_layerx_dashboard_id',
                          e,
                        );
                      }}
                      defaultValue={data?.system_layerx_dashboard_id?.value}
                    />
                  </Col>
                </Row>
              </Card>
              {/* Pexip Settings */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.pexipSettings" />}
                loading={loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.configurationURL" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('pexip_api_base_url', e);
                      }}
                      defaultValue={data?.pexip_api_base_url?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.vmrAliasDomainExtension" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('pexip_vmr_domain_name', e);
                      }}
                      defaultValue={data?.pexip_vmr_domain_name?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.conferenceNodeAddress" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('conference_node', e);
                      }}
                      defaultValue={data?.conference_node?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="configuration.customer.username" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('mailer_username', e);
                      }}
                      defaultValue={data?.mailer_username?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="configuration.customer.password" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="password"
                      onChange={(e) => {
                        this.onChangeSystemData('pexip_api_password', e);
                      }}
                      defaultValue={data?.pexip_api_password?.value}
                    />
                  </Col>
                </Row>
              </Card>
              {/* Email SMTP Settings */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.emailSMTPSettings" />}
                loading={this.props.loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={1} md={1} sm={1} xs={1}>
                    <div>
                      <i className="icon icon-sweet-alert" />
                    </div>
                  </Col>
                  <Col lg={22} md={22} sm={22} xs={22}>
                    <div>
                      <IntlMessages id="system.emailSMTPInfo" />
                    </div>
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.smtpMailingAddress" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('mailer_address', e);
                      }}
                      defaultValue={data?.mailer_address?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.sendingDomain" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('mailer_domain', e);
                      }}
                      defaultValue={data?.mailer_domain?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.smtpPortNumber" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('mailer_port', e);
                      }}
                      defaultValue={data?.mailer_port?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="configuration.customer.username" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('mailer_username', e);
                      }}
                      defaultValue={data?.mailer_username?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="configuration.customer.password" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="password"
                      onChange={(e) => {
                        this.onChangeSystemData('pexip_api_password', e);
                      }}
                      defaultValue={data?.pexip_api_password?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.emailFromAddress" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('mailer_default_email', e);
                      }}
                      defaultValue={data?.mailer_default_email?.value}
                    />
                  </Col>
                </Row>
              </Card>
              {/* Send Test Email */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.sendTestEmail" />}
                loading={this.props.loading}
              >
                <Form onClick={this.handleSubmit} autoComplete={Math.random()}>
                  <Row className="baseLineAlignedItems" gutter={16}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                      <div>
                        <IntlMessages id="system.sendTestEmailTo" />
                      </div>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <Input
                        autoComplete={Math.random()}
                        className={!isEmailValid && this.state.mail.length > 0 ? 'error' : null}
                        type="mail"
                        name="mail"
                        noValidate
                        onChange={this.handleChange}
                      />
                      {!isEmailValid && this.state.mail.length > 0 && (
                        <span className="errorMessage">
                          {this.intl.formatMessage({ id: 'common.invalidEmail' })}
                        </span>
                      )}
                    </Col>
                    <Col lg={6} md={24} sm={24} xs={24}>
                      <Button
                        title="Send"
                        alt="Send link"
                        className="sendButton"
                        htmlType="submit"
                        disabled={!isEmailValid}
                        noValidate
                      >
                        <IntlMessages id="system.sendButton" />
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
              {/* Pexip Settings - Meetings (MyChart) */}
              <Card
                className="gx-card customer-edit-info-card"
                title={<IntlMessages id="system.pexipSettingsMyChart" />}
                loading={loading}
              >
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.configurationURL" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('hcm_pexip_api_base_url', e);
                      }}
                      defaultValue={data?.hcm_pexip_api_base_url?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.vmrAliasDomainExtension" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('hcm_pexip_vmr_domain_name', e);
                      }}
                      defaultValue={data?.hcm_pexip_vmr_domain_name?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="system.conferenceNodeAddress" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('hcm_conference_node', e);
                      }}
                      defaultValue={data?.hcm_conference_node?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="configuration.customer.username" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="text"
                      onChange={(e) => {
                        this.onChangeSystemData('hcm_pexip_api_username', e);
                      }}
                      defaultValue={data?.hcm_pexip_api_username?.value}
                    />
                  </Col>
                </Row>
                <Row className="baseLineAlignedItems" gutter={16}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="configuration.customer.password" />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <Input
                      autoComplete={Math.random()}
                      type="password"
                      onChange={(e) => {
                        this.onChangeSystemData('hcm_pexip_api_password', e);
                      }}
                      defaultValue={data?.hcm_pexip_api_password?.value}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

System.defaultProps = {
  data: {},
  loading: true,
};

System.propTypes = {
  data: PropTypes.object,
  systemOnFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
};

/**
 * Maps State to Props
 * @param {object} bedsCarts Bed Carts Data
 * @param {object} common Common Data
 * @param {object} subtitle Subtitle Data
 * @param {object} listingsTopFilter Top Filters Data
 * @returns {object} Data from the Store
 */
const mapStateToProps = ({ SystemSettingsSystem, subtitle }) => {
  const { data } = SystemSettingsSystem;
  const mail = SystemSettingsSystem.mail || {};
  const { loading } = SystemSettingsSystem;
  const { licensing } = SystemSettingsSystem;
  const { version } = SystemSettingsSystem;

  return {
    data, loading, subtitle, licensing, version,
  };
};

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Actions
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  systemOnFetchData: (page) => dispatch(systemOnFetchData(page)),
  saveSystemData: (data) => dispatch(saveSystem(data)),
  sendTestEmail: (mail) => dispatch(sendTestEmail(mail)),
  fetchLicensing: (data) => dispatch(fetchLicensing(data)),
  sendLicensingKey: (body) => dispatch(sendLicensingKey(body)),
  fetchApplicationVersion: (data) => dispatch(fetchApplicationVersion(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(System)));
