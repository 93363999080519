import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import {
  Card, Col, Input, Row,
} from 'antd';
import { injectIntl } from 'react-intl';

/**
 * Render Customer Attributes card for edit/add
 * @param {object} customer object
 * @param {function} onChangeHandlerTest event
 * @param {object} intl intl
 * @param {boolean} disabled true||false
 * @param {{ customer: boolean }} readyMap Container for state of loading elements
 * @param {boolean} isNew Switch which tells if the component is new
 * @returns {JSX.Element} row
 */
const CustomerAttributes = ({
  customer, onChangeHandlerTest, intl, disabled, readyMap, isNew,
}) => {
  /**
   * Render Attributes
   * @param {string} atr name
   * @param {Number} index of object
   * @returns {JSX} row
   */
  const renderAtr = (atr, index) => {
    const currentAtr = intl.formatMessage({ id: `configuration.customer.${atr.name}` }) ? intl.formatMessage({ id: `configuration.customer.${atr.name}` }) : atr.display;
    return (
      <Row className="baseLineAlignedItems" key={atr.id} gutter="16">
        <Col lg={8} md={8} sm={24} xs={24}>
          <div>
            {currentAtr}
          </div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Input
            autoComplete={Math.random()}
            id={String(atr.id)}
            name="name"
            type="text"
            value={atr.value}
            onChange={(event) => onChangeHandlerTest(event, index)}
            disabled={disabled}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Card
      className="gx-card customer-edit-attributes-card"
      title={<IntlMessages id="configuration.customer.customer_attributes" />}
      loading={!isNew && !readyMap.customer}
    >
      {customer
        && customer.attributes
        && customer.attributes.sort((firstAtr, secondAtr) => {
          const firstAtrName = firstAtr.name ? intl.formatMessage({ id: `configuration.customer.${firstAtr.name}` }) : firstAtr.display;
          const secondAtrName = secondAtr.name ? intl.formatMessage({ id: `configuration.customer.${secondAtr.name}` }) : secondAtr.display;
          if (firstAtr.group === secondAtr.group) {
            return firstAtrName.localeCompare(secondAtrName);
          }
        }).map((atr, ind) => renderAtr(atr, ind))}
    </Card>
  );
};

CustomerAttributes.defaultProps = {
  isNew: true,
};

CustomerAttributes.propTypes = {
  intl: PropTypes.shape().isRequired,
  readyMap: PropTypes.shape({ customer: PropTypes.bool }).isRequired,
  customer: PropTypes.object.isRequired,
  onChangeHandlerTest: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

export default injectIntl(CustomerAttributes);
