import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Cascader, Form } from 'antd';
import { injectIntl } from 'react-intl';
import { onGetOptions, fetchOptionsSuccess, resetFilters } from '@uhe_actions/filters/ListingsTopFilterActions';

/**
 * FilterDropdowns class component
 */
class FilterDropdowns extends React.Component {
  constructor(props) {
    super(props);
  
    this.selectedFilterVals = {};
    this.clearFiltersOnSelectionChange = this.clearFiltersOnSelectionChange.bind(this);
    this.setInitFilter = this.setInitFilter.bind(this);
    this.updateValuesByFilters = this.updateValuesByFilters.bind(this);

    this.state = {
      selectedFilterVals: {},
    };

    this.setInitFilter();
  }

  /**
   * @description Set initial filter
   *
   * @return {void}
   */
  setInitFilter() {
    const { filters, onGetOptions } = this.props;
    onGetOptions(filters[0].key);
  }

  /**
   * Clear all filters after the selected change
   *
   * @param  {number} filterIndex
   * @param {filters} filters
   * @param  {Array<string>} selectedFilters
   * @return {void}
   */
  clearFiltersOnSelectionChange(filterIndex, filters, selectedFilters) {
    const { selectedFilterVals } = this.state;
    if (selectedFilterVals.customer && selectedFilterVals.facility && filterIndex === 0) {
      selectedFilterVals.customer[0] = null;
      selectedFilterVals.facility[0] = null;
    } else if (selectedFilterVals.customer && filterIndex === 0) {
      selectedFilterVals.customer[0] = null;
    } else if (selectedFilterVals.facility && filterIndex === 1) {
      selectedFilterVals.facility[0] = null;
    }
    for (let i = filterIndex + 1; i < filters.length; i++) {
      delete selectedFilters[filters[i].key];
    }
  }

  /**
   * Triggered when input is cleared or option is selected
   *
   * @param  {Array<string>} value
   * @param  {number} filterIndex
   * @return {void}
   */
  updateValuesByFilters(value, filterIndex) {
    const { filters, onChangeParent, onGetOptions } = this.props;

    const selectedFilter = filters[filterIndex];
    const selectedFilters = {...this.state.selectedFilterVals};
    selectedFilters[selectedFilter.key] = value;
    this.clearFiltersOnSelectionChange(filterIndex, filters, selectedFilters);
    this.setState({ selectedFilterVals: { ...selectedFilters } });

    onChangeParent({ name: selectedFilter.key, id: selectedFilters[selectedFilter.key][0] });
    const filter = filters[filterIndex + 1] || null;
    this.isFilterActive();
    if (filter) {
      onGetOptions(filter.key, value[0]);
    }
  }

  /**
   * Returns true if any of the widgets filters are active
   *
   * @return {Boolean}
   */
  isFilterActive() {
    const { selectedFilterVals } = this.state;
    const activeFilters = Object.values(selectedFilterVals).reduce((active, filter) => {
      if (filter) {
        active += active + 1;
      }
      return active;
    }, 0);

    return activeFilters > 0;
  }

  /**
   * @description Handle onChange event
   *
   * @param  {Array<string>} value
   * @param  {number} index
   * @return {void}
   */
  onFilterChange(value, index) {
    const { filters } = this.props;
    const { key } = filters[index];

    if (!value.length) {
      const selVals = { ...this.state.selectedFilterVals };
      delete selVals[key];
      this.setState({ selectedFilterVals: { ...selVals } });
    }

    this.updateValuesByFilters(value, index);
  }

  /**
   * @description Renders input titles
   * @param {Object} config
   * @returns {JSX}
   */
  renderInputTitles(config) {
    let field;
    const { intl } = this.props;

    if (config.key === 'facility') {
      field = intl.formatMessage({ id: config.placeholder });
    } else {
      field = intl.formatMessage({ id: config.placeholder });
    }

    return field;
  }

  /**
   * Renders filter inputs
   *
   * @return {Array<ReactElement>|null}
   */
  renderFilterDropdowns() {
    const { filters, optionsList, intl } = this.props;
    const { selectedFilterVals } = this.state;

    if (!filters || !Array.isArray(filters)) {
      return null;
    }

    const inputs = [];

    filters.forEach((config, index) => {
      const prevItem = index ? filters[index - 1] : null;
      const prevSelected = !!(prevItem && selectedFilterVals[prevItem.key]);
      const disabled = !prevSelected && index;

      inputs.push(
        <Form.Item
          key={config.key}
          className="gx-d-flex gx-align-items-center"
          name={config.key}
          label={this.renderInputTitles(config)}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Cascader
            popupClassName="top-filter-popup"
            fieldNames={config.fieldNames}
            key={config.key}
            expandTrigger="hover"
            size="large"
            changeOnSelect
            value={selectedFilterVals[config.key]}
            options={optionsList[config.key] || []}
            onChange={(value) => this.onFilterChange(value, index)}
            placeholder={intl.formatMessage({ id: config.placeholder })}
            showSearch={config.showSearch}
            disabled={!!disabled}
          />
        </Form.Item>,
      );
    });

    return inputs;
  }

  /**
   * Renders the component
   *
   * @return {ReactElement}
   */
  render() {
    return (
      <div className="filterDropdowns">
        {this.renderFilterDropdowns()}
      </div>
    );
  }
}

FilterDropdowns.defaultProps = {
  cssClass: '',
};

FilterDropdowns.propTypes = {
  fieldNames: PropTypes.shape(),
  intl: PropTypes.shape().isRequired,
  filters: PropTypes.array.isRequired,
  cssClass: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  onChangeParent: PropTypes.func.isRequired,
  onGetOptions: PropTypes.func.isRequired,
};

const mapStateToProps = ({ listingsTopFilter }) => ({ optionsList: listingsTopFilter });

export default connect(mapStateToProps, {
  onGetOptions,
  fetchOptionsSuccess,
  resetFilters,
})(injectIntl(withRouter(FilterDropdowns)));
