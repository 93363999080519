import React from 'react';
import { Link } from 'react-router-dom';

const FacilityCell = ({ content }) => {
  const { id, organizationId, customerId, name } = content;

  return (
    <div className="uhe-table-td">
      <Link to={`/configuration/facilities/edit/${id}`}>
        <span>{name}</span>
      </Link>
      <Link to={`/configuration/units?topf_organization=${organizationId}&topf_customer=${customerId}&topf_facility=${id}`} className="search-icon">
        <i className="icon icon-search-new" />
      </Link>
    </div>
  );
};

export default FacilityCell;