import React, {Component} from "react";
import { connect } from 'react-redux';
import {GoogleMap, Marker, withGoogleMap} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import RestManager from '@util/RestManager';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { onFetchGmapData } from '@uhe_actions/monitoring/UHEActions';
import { MAP_DEFAULT_CENTER } from '@constants/UHESettings';

const MarkerClustererGoogleMap = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={props.zoom}
    defaultCenter={props.mapCenter}
    onIdle={props.onZoomBoundsChanged}
    onTilesLoaded={props.onTilesLoaded}
    ref={props.onMapMounted}
    options={props.mapOptions}
  >
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
      styles={props.clusterStyles}
      calculator={props.clusterRenderer}
    >
      {props.markers.filter(marker => marker.latitude && marker.longitude).map((marker, index) => {
        return (
          <Marker
            position={{lat: marker.latitude, lng: marker.longitude}}
            key={`marker_${index}`}
            icon={`/marker_${marker.status}.png`}
          />
        );
      })}
    </MarkerClusterer>
  </GoogleMap>
));

class UHEMarkerClusterer extends Component {
  constructor(props) {
    super();

    this.tilesLoaded = false;
    this.mapRef = undefined;
    this.onMapMounted = this.onMapMounted.bind(this);
    this.onZoomBoundsChanged = this.onZoomBoundsChanged.bind(this);
    this.onTilesLoaded = this.onTilesLoaded.bind(this);
  }

  onTilesLoaded() {
    this.tilesLoaded = true;
  }

  /**
   * Handles map zoom and boundaries change event
   *
   * @returns {void}
   */
  onZoomBoundsChanged() {
    const { onZoomBoundsChanged } = this.props;
    const zoom = this.mapRef.getZoom();
    const bounds = this.mapRef.getBounds();
    const center = this.mapRef.getCenter();

    if (onZoomBoundsChanged && this.tilesLoaded) {
      onZoomBoundsChanged(zoom, bounds, center);
    }
  }

  /**
   * Handles map mounted event
   *
   * @param {object} map
   *
   * @returns {void}
   */
  onMapMounted(map) {
    this.mapRef = map;

    let { zoom, mapCenter, data } = this.props;

    if (this.mapRef && (!mapCenter || !zoom)) {
      const { mapCenter, mapBounds } = this.calculateMapBoundsByMarkers(data.list);
      this.mapRef.center = mapCenter;

      if (mapBounds) {
        this.mapRef.fitBounds(mapBounds);
      }      
    }
  }

  /**
   * Calculates the center point for the map from the markers list
   *
   * @param {Array<Object>} markers
   *
   * @returns {Object}
   */
  calculateMapBoundsByMarkers(markers) {
    if (!markers || !Array.isArray(markers) || markers.length === 0) {
      return {
        mapCenter: MAP_DEFAULT_CENTER,
        mapBounds: null,
      };
    }

    const latitudes = markers.map(marker => marker.latitude);
    const longitudes = markers.map(marker => marker.longitude);

    const minLatitude = Math.min.apply(null, latitudes);
    const maxLatitude = Math.max.apply(null, latitudes);

    const minLongitude = Math.min.apply(null, longitudes);
    const maxLongitude = Math.max.apply(null, longitudes);

    const mapCenter = {
      lat: ((maxLatitude + minLatitude) / 2.0),
      lng: ((maxLongitude + minLongitude) / 2.0),
    };

    const mapBounds = {
      //bottom left
      south: minLatitude,
      west: minLongitude,
      //top right
      north: maxLatitude,
      east: maxLongitude,
    };

    return {
      mapCenter,
      mapBounds,
    }
  }

  render() {
    const { data, zoom, mapCenter, mapOptions, clusterStyles, clusterRenderer } = this.props;
    const markers = data.list || [];

    return (
      <MarkerClustererGoogleMap
        loadingElement={<div style={{height: `100%`}}/>}
        containerElement={<div style={{height: `237px`}}/>}
        mapElement={<div style={{height: `100%`}}/>}
        markers={markers}
        onZoomBoundsChanged={this.onZoomBoundsChanged}
        onMapMounted={this.onMapMounted}
        zoom={zoom}
        mapCenter={mapCenter}
        onTilesLoaded={this.onTilesLoaded}
        mapOptions={mapOptions}
        clusterStyles={clusterStyles}
        clusterRenderer={clusterRenderer}
      />
    );
  }
}

const mapStateToProps = ({ UheGmap = {} }) => {
  const { data = [] } = UheGmap;

  return { data };
};

export default connect(mapStateToProps, {
  onFetchGmapData,
})(UHEMarkerClusterer);
