import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
  Row,
  Input,
  Form,
  Button,
  Alert,
}
  from 'antd';
import * as userActions from "@uhe_actions/ResetPasswordActions";
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

/**
 * @description Renders Reset Password Page
 */
class ResetPassword extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.isNew = this.props.location.pathname.indexOf("/new") !== -1;
    this.intl = this.props.intl;
    this.state = {
      password: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      hasError: false,
    };

    this.changeOwnPassword = this.changeOwnPassword.bind(this);
    this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
    this.showError = this.showError.bind(this);

    this.history = this.props.history;
    this.qParams = new URLSearchParams(this.history.location.search);

  }

  /**
   * @description Watch for empty data and if is empty set new state data
   * @param {Object} prevProps
   * @return {Object}
   */
  componentDidUpdate(prevProps) {
    const { 
      error,
      history,
      message,
      intl
    } = this.props;
    const password_data = this.state.password;

    if (!message && !error) {
      return;
    }

    // display errror message
    if (prevProps.error !== this.props.error) {
      // handle system error
      if (error.code === 404) {
        history.push('/change-password');
      }
      if(error.code === 400) {
        history.push('/change-password');
      }
    }

    this.formRef.current.setFieldsValue({
      oldPassword: password_data.oldPassword,
      newPassword: password_data.newPassword,
      confirmPassword: password_data.confirmPassword,
    });

  }

  /**
   * @description Save Changes on Save Button Click in the header
   * @param {Object} event
   * @returns {void}
   */
  changeOwnPassword(event) {
    event.persist();
    event.preventDefault();

  this.formRef.current.validateFields()
  .then(values => {
      const { actions } = this.props;
      const { password } = this.state;
      actions.passwordOnFetchData({ password });
    })
    .catch(info => {
      console.log('info:', info);
    });
  }

  /**
   * @description Change input name
   * @param {*} key
   * @param {Object} event
   * @return {void}
   */
  onChangeOldPassword(key, event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    this.setState({
      password: {
        ...this.state.password,
        ...this.state.password,
        [key]: event.target.value
      }
    });
  }

    /**
   * @description Change input name
   * @param {*} key
   * @param {Object} event
   * @return {void}
   */
  onChangeNewPassword(key, event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    this.setState({
      password: {
        ...this.state.password,
        ...this.state.password,
        [key]: event.target.value
      }
    });
  }

      /**
   * @description Change input name
   * @param {*} key
   * @param {Object} event
   * @return {void}
   */
  onChangeConfirmPassword(key, event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    this.setState({
      password: {
        ...this.state.password,
        ...this.state.password,
        [key]: event.target.value
      }
    });
  }

  /**
   * @description Handle Error Messages
   * @returns {void}
   */
  showError() {
    if(this.props.error.code === 400) {
      return (
        <Alert
          message={this.intl.formatMessage({ id: 'common.wrong_current_password' })}
          type="error"
          showIcon
        />
      );
    }
  }

  render() {
    const { loading } = this.props;
    const mathcPassword = this.intl.formatMessage({ id: 'configuration.users.confirmNewPass' });
    return (
      <div className="reset-password-wrapper">
        <div>
          <Row type="flex" justify="center" align="middle">
            <div>
              <img className="reset-logo-image" src={require('assets/images/caregility_logo_large.png')} alt="logo" />
            </div>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <h3>
              <IntlMessages id="app.userAuth.resetYourPassword" />
            </h3>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Form
              autoComplete={Math.random()}
              layout="vertical"
              className="reset-form"
              ref={this.formRef}
            >
              <Form.Item
              loading={loading ? 1 : 0}
                name="oldPassword"
                label={this.intl.formatMessage({ id: 'app.userAuth.currentPassword' })}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                  },
                ]}
              >
                <Input.Password
                  autoComplete={Math.random()}
                  type='password'
                  onChange={(e) => { this.onChangeOldPassword('oldPassword', e) }}
                  value={this.state.password.oldPassword}
                />
              </Form.Item>
              {this.showError()}
              <Form.Item
                name="newPassword"
                label={this.intl.formatMessage({ id: 'app.userAuth.newPassword' })}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                  },
                  {
                    pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})',
                    message: this.intl.formatMessage({ id: 'configuration.users.newPassRule' }),
                  }
                ]}
              >
                <Input.Password
                  autoComplete={Math.random()}
                  type='password'
                  onChange={(e) => { this.onChangeNewPassword('newPassword', e) }}
                  value={this.state.password.newPassword}
                />
              </Form.Item>
              <Form.Item
                label={this.intl.formatMessage({ id: 'app.userAuth.confirmPassword' })}
                name='confirmPassword'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: this.intl.formatMessage({ id: 'configuration.users.emptyField' }),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(mathcPassword);
                    },
                  }),    
                ]}
              >
                <Input.Password
                  autoComplete={Math.random()}
                  type='password'
                  onChange={(e) => { this.onChangeConfirmPassword('confirmPassword', e) }}
                  value={this.state.password.newPassword}
                />
              </Form.Item>
              <Form.Item>
                <Row type="flex" justify="center" align="middle">
                  <Button className="reset-submit-btn" onClick={this.changeOwnPassword}>
                    <IntlMessages id="common.submit" />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Row>
          <Row>
            <p><IntlMessages id="app.userAuth.resetPasswordContact" /></p>
          </Row>
        </div>
      </div>
    );
  }
};

ResetPassword.defaultProps = {
  password: {},
  loading: true,
};

ResetPassword.propTypes = {
  password: PropTypes.object,
  setSubtitle: PropTypes.func,
};

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({ ResetPassword, subtitle, common }) => {
  const { savedUserId } = ResetPassword;
  return {
    subtitle,
    savedUserId,
    error: common.error,
    loading: ResetPassword.loading,
    password: ResetPassword.currentPassword || {},
  };
};

/**
 * @description Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(ResetPassword)));
