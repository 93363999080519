import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import { Pie, PieChart, Sector } from 'recharts';
import { Card, Empty } from 'antd';

import { onFetchPieChartData } from '@uhe_actions/monitoring/UHEActions';
import { TABLE_FILTER_PREFIX } from '@constants/UHESettings';

const RADIAN = Math.PI / 180;
let _locale;

/**
 * @param  {number} cx
 * @param  {number} cy
 * @param  {number} midAngle
 * @param  {number} innerRadius
 * @param  {number} outerRadius
 * @param  {number} value
 * @param  {string} fill
 * @return {ReactElement}
 */
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, value, fill,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) + 10;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  let textAnchor;

  if (_locale === 'ar') {
    textAnchor = x > cx ? 'end' : 'start';
  } else {
    textAnchor = x > cx ? 'start' : 'end';
  }

  return (
    <g>
      <text x={x} y={y} fill={fill} textAnchor={textAnchor} dominantBaseline="central">
        {value}
      </text>
    </g>
  );
};

/**
 * @param  {number} cx
 * @param  {number} cy
 * @param  {number} innerRadius
 * @param  {number} outerRadius
 * @param  {string} fill
 * @param  {string} name
 * @param  {number} startAngle
 * @param  {number} endAngle
 * @return {ReactElement}
 */
const renderActiveShape = ({
  cx, cy, innerRadius, outerRadius, fill, name, startAngle, endAngle,
}) => (
  <g>
    <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill}>{name}</text>
    <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={fill}>
      <IntlMessages id="uhe.pie.clickFilter" />
    </text>
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
    <Sector
      cx={cx}
      cy={cy}
      startAngle={startAngle}
      endAngle={endAngle}
      innerRadius={outerRadius + 1}
      outerRadius={outerRadius + 5}
      fill={fill}
    />
  </g>
);

/**
 *
 */
class UheChart extends React.Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { filter } = nextProps;

    const pieHolder = document.getElementById('pie-chart-holder');
    const newState = { pieWidth: 400 };

    if (pieHolder) {
      newState.pieWidth = pieHolder.clientWidth - 50;
    }

    if (JSON.stringify(filter) === JSON.stringify(prevState.filter)) {
      return newState;
    }

    nextProps.onFetchPieChartData(filter);
    newState.filter = filter;

    return newState;
  }

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: null,
    };

    this.history = this.props.history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.lastQueryString = this.history.location.search;
  }

  componentDidUpdate() {
    if (this.lastQueryString != this.history.location.search) {
      this.lastQueryString = this.history.location.search;
      this.hasAnimationEnded = false;
    }
  }

  /**
   * Adapt data returned by the server
   *
   * @param  {Array<Object>} data
   * @return {Object}
   */
  dataAdapter(data) {
    const { intl } = this.props;
    const adaptedData = {};
    let total = 0;

    data.forEach((value) => {
      if (!value.not_configured
        && (value.online || value.in_a_call || value.inAlarm)) {
        total += value.count;
      }

      if (value.inAlarm) {
        adaptedData.alarm = adaptedData.alarm
          ? {
            key: 'unhealthy', name: intl.formatMessage({ id: 'uhe.pie.inAlarm' }), value: (adaptedData.alarm.value + value.count), fill: '#EE66A4',
          }
          : {
            key: 'unhealthy', name: intl.formatMessage({ id: 'uhe.pie.inAlarm' }), value: value.count, fill: '#EE66A4',
          };
      } else if (value.online && value.activated && !value.in_a_call && !value.inAlarm && !value.not_configured) {
        adaptedData.standby = adaptedData.standby
          ? {
            key: 'healthy', name: intl.formatMessage({ id: 'uhe.pie.standby' }), value: (adaptedData.standby.value + value.count), fill: '#c4db75',
          }
          : {
            key: 'healthy', name: intl.formatMessage({ id: 'uhe.pie.standby' }), value: value.count, fill: '#c4db75',
          };
      } else if (value.in_a_call) {
        adaptedData.inCall = adaptedData.inCall
          ? {
            key: 'inCall', name: intl.formatMessage({ id: 'uhe.pie.inCall' }), value: (adaptedData.inCall.value + value.count), fill: '#C2DB70',
          }
          : {
            key: 'inCall', name: intl.formatMessage({ id: 'uhe.pie.inCall' }), value: value.count, fill: '#C2DB70',
          };
      } else if (value.not_configured) {
        adaptedData.unconfigured = adaptedData.unconfigured
          ? {
            key: 'not_configured', name: intl.formatMessage({ id: 'uhe.pie.not_configured' }), value: (adaptedData.unconfigured.value + value.count), fill: '#FFBF00',
          }
          : {
            key: 'not_configured', name: intl.formatMessage({ id: 'uhe.pie.not_configured' }), value: value.count, fill: '#aaa2a2',
          };
      }
    });

    return { total, chartData: Object.values(adaptedData) };
  }

  /**
   * Handle click on the Pie Chart
   *
   * @param  {Object} data
   * @return {void}
   */
  onPieClicked = (data) => {
    const currStatusFlter = this.qParams.get(`${TABLE_FILTER_PREFIX}status`);
    const currInCallFlter = this.qParams.get(`${TABLE_FILTER_PREFIX}inCall`);

    if (data.key === currStatusFlter || data.key === currInCallFlter) {
      this.qParams.delete(`${TABLE_FILTER_PREFIX}${data.key}`);
      this.history.push({ search: this.qParams.toString() });
      return;
    }

    if (data.key === 'inCall') {
      this.qParams.set(`${TABLE_FILTER_PREFIX}inCall`, '0');
    } else {
      this.qParams.set(`${TABLE_FILTER_PREFIX}status`, data.key);
    }

    this.history.push({ search: this.qParams.toString() });
  }

  /**
   * Renders the reset button element
   *
   * @returns {ReactElement|null}
   */
  renderResetButton() {
    const inCallFilter = this.qParams.get(`${TABLE_FILTER_PREFIX}inCall`);
    const statusFilter = this.qParams.get(`${TABLE_FILTER_PREFIX}status`);

    return (
      <button className="ant-btn reset-btn ant-btn-sm" onClick={this.clearFilters} disabled={!inCallFilter && !statusFilter}>
        <IntlMessages id="uhe.listingsTopFilter.clearFiltersButtonLbl" />
      </button>
    );
  }

  /**
   * Clears map zoom and boundary parameters from URL
   *
   * @returns {void}
   */
  clearFilters = () => {
    this.qParams.delete(`${TABLE_FILTER_PREFIX}inCall`);
    this.qParams.delete(`${TABLE_FILTER_PREFIX}status`);

    this.history.push({ search: this.qParams.toString() });
  }

  /**
   *
   */
  render() {
    const { loading, data } = this.props;
    const { pieWidth } = this.state;
    const { total, chartData } = this.dataAdapter(data);
    _locale = this.props.intl.locale;

    return (
      <Card
        loading={loading}
        id="pie-chart-holder"
        className="pie-chart-box gx-card"
        title={<IntlMessages id="uhe.pie.activeDevices" />}
        extra={this.renderResetButton()}
      >
        <div className="pie-chart-flex">
          {!!chartData.length && (
          <div>
            <PieChart className="pie-chart" width={pieWidth} height={220}>
              <Pie
                labelLine={false}
                label={renderCustomizedLabel}
                data={chartData}
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                onMouseEnter={(data, index) => this.hasAnimationEnded && this.setState({ activeIndex: index })}
                onMouseLeave={() => this.hasAnimationEnded && this.setState({ activeIndex: null })}
                innerRadius={60}
                outerRadius={95}
                onClick={this.onPieClicked}
                fill="#8884d8"
                dataKey="value"
                onAnimationStart={() => this.hasAnimationEnded = false}
                onAnimationEnd={() => this.hasAnimationEnded = true}
              />
            </PieChart>
            <span>
              <IntlMessages id="uhe.pie.totalDevices" />
              {' '}
              {total}
            </span>
            <br />
          </div>
          )}
          {!chartData.length && <Empty className="no-pie-data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = ({ UhePieChart = {} }) => {
  const { loading = true, data = [] } = UhePieChart;

  return { loading, data };
};

export default connect(mapStateToProps, {
  onFetchPieChartData,
})(injectIntl(withRouter(UheChart)));
