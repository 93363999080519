import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import lodash from 'lodash';
import { Card, Table } from 'antd';
import PropTypes from 'prop-types';

import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import IntlMessages from 'util/IntlMessages';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TABLE_FILTER_PREFIX,
  APP_PAGES_CONTEXT,
  IS_EDIT_PAGE_REGEX,
} from '@constants/UHESettings';
import { getProgramsListing, deleteProgram } from '@uhe_actions/configuration/programs/ProgramsActions';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import ProgramCell from '@components/tables/cells/ProgramCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import { shouldResetPaginationSuccess } from '@actions';
import { withLastLocation } from 'react-router-last-location';
import { ImportButton, ExportButton } from '@components/uhe/listings/Buttons';
import { shouldBeAbleToImport, shouldBeAbleToExport, shouldBeAbleToAdd } from '@util/UheRoleChecker';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper from 'util/UheHelper';
import { getColumnSorting, setTableData, getCurrentSort } from '@util/UheHelper';

/**
 * Programs Class Component
 * @returns {void}
 */
class Programs extends Component {
  static pageContext = APP_PAGES_CONTEXT.programs;

  /**
   * Programs Component Constructor
   * @param {object} props Programs Component Props
   * @returns {void}
   */
  constructor(props) {
    super(props);
    const {
      pagination, history, subtitle, setSubtitleAction,
    } = this.props;
    this.state = {};

    this.tableFilterMap = {
      organization: 'organization.name',
      program: 'name',
    };

    this.tableKeys = [
      'organization',
      'program',
      'actions',
    ];

    this.data = [];
    this.columns = [];
    this.filterTypes = {};
    const { location } = props;

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            showFilter={
              !(
                value === 'actions'
              )
            }
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: (value) ? getColumnSorting(value, location) : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    if (subtitle?.langId !== 'configuration.programs.title') {
      setSubtitleAction('configuration.programs.title');
    }

    this.qParams = new URLSearchParams(history.location.search);
    this.defaultSorted = true;
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const {
      lastLocation,
      pagination,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag) {
      this.onPageChange(1);
      resetPaginationSuccess();
      return;
    }

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * Invokes on Update
   * @param {object} prevProps Programs PrevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      location,
      lastLocation,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }

    if (location.hash !== prevProps.location.hash
      && (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag)) {
      this.onPageChange(1);
      resetPaginationSuccess();
    }
  }

  /**
   * Checks Sort Order
   * @returns {string} Order string
   */
  checkSortTableOrder = () => {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * Handles Page Changes
   * @param {number} page Page Number
   * @returns {void}
   */
  onPageChange = (page) => {
    const { getProgramsListingAction, shouldResetPaginationFlag } = this.props;
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (
          this.filterTypes[key]
          && this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = ['organization.name,asc', 'name,asc'];
    } else {
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;
    getProgramsListingAction(page - 1, sort, filter);
  }

  /**
   * Adapts Listing Data
   * @param {array} data Listing
   * @returns {array} Listing
   */
  dataAdapter = (data = []) => {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization.id,
          name: value.organization.name,
        },
        program: {
          id: value.id,
          name: value.name,
        },
        actions: {
          id: value.id,
        },
      });
    });
    return adaptedData;
  }

  /**
   * Renders Table Cells
   * @param {object} content Content Data
   * @param {string} key Content Key e.g. Organization
   * @return {JSX} Cell
   */
  cellRenderer(content, key) {
    let cell;
    const { loggedUser, data, deleteProgramAction } = this.props;

    switch (key) {
      case 'organization':
        cell = content.id ? (
          <OrganizationCell content={content} />
        ) : (
          <div className="uhe-table-td">
            <IntlMessages id="configuration.users.allOrganizations" />
          </div>
        );
        break;
      case 'program':
        cell = <ProgramCell content={content} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.programs}
            loggedUser={loggedUser}
            content={content}
            data={data}
            editLink={`/configuration/programs/edit/${content.id}`}
            deleteAction={() => {
              deleteProgramAction({
                id: content.id,
                page: this.currentPage,
                sort: this.sort,
                filter: this.filter,
              });
            }}
          />
        );
        break;
      default:
        cell = content;
    }

    return cell;
  }

  /**
   * Render Add Button
  * @returns {JSX} component
  */
  renderAddButton = () => {
    const { loggedUser } = this.props;
    return (
      <Link
        to="/configuration/programs/new"
        shape="circle"
        icon="+"
        className="page-icons page-icon-plus"
        hidden={!shouldBeAbleToAdd(loggedUser, Programs.pageContext)}
      >
        <i className="icon icon-add" />
        <span><IntlMessages id="common.addText" /></span>
      </Link>
    );
  }

  /**
   * Render Add Button
  * @returns {JSX} component
  */
  renderImportButton = () => {
    const { loggedUser } = this.props;
    return (
      <ImportButton
        visible={shouldBeAbleToImport(loggedUser, Programs.pageContext)}
        url="/configuration/programs/bulk/upload"
      />
    );
  }

  /**
   * Render Export Button
   * @returns {JSX} component
   */
  renderExportButton = () => {
    const { loggedUser } = this.props;
    return (
      <ExportButton
        visible={shouldBeAbleToExport(loggedUser, Programs.pageContext)}
        url={UheHelper.getCsvUrl(this.filter, this.sorting, ENDPOINTS.ExportCsv.programs)}
      />
    );
  }

  /**
 * Listings page
 * @returns {JSX} Programs Listing page
 */
  render() {
    const { pagination, data, loading } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div>
        <div className="uhe-table-header">
          <div className="buttons-container">
            {this.renderAddButton()}
            {this.renderImportButton()}
            {this.renderExportButton()}
          </div>
          <p>
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{pagination.total}</span>
          </p>
        </div>
        <Card className="filter-boxes gx-card">
          <Table
            bordered
            loading={loading}
            className="gx-table-responsive"
            dataSource={this.dataAdapter(data)}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>
      </div>
    );
  }
}

Programs.defaultProps = {
  shouldResetPaginationFlag: false,
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
};

Programs.propTypes = {
  pagination: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  loggedUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  getProgramsListingAction: PropTypes.func.isRequired,
  deleteProgramAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  subtitle: PropTypes.shape().isRequired,
  setSubtitleAction: PropTypes.func.isRequired,
  lastLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: undefined,
    key: PropTypes.string,
  }),
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
};

/**
 * Maps State to Props
 * @returns {object} Object with States
 */
const mapStateToProps = ({
  programs, ConfigurationUsers, subtitle, common,
}) => ({
  loading: programs.loading,
  pagination: {
    total: programs?.table?.page?.totalElements || 0,
    current: programs?.table?.page?.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  data: programs.table.list,
  loggedUser: ConfigurationUsers.ownUser,
  subtitle,
  shouldResetPaginationFlag: common.shouldResetPagination,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  getProgramsListingAction:
    (page, sort, filter) => dispatch(getProgramsListing(page, sort, filter)),
  setSubtitleAction: (langId) => dispatch(setSubtitle(langId)),
  deleteProgramAction: (payload) => dispatch(deleteProgram(payload)),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withLastLocation(withRouter(Programs))));
