import React, { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';

import { footerText } from 'util/config';
import App from 'routes/index';
import { useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import UHESystemNotifications from 'components/uhe/UHESystemNotifications';
import UheHelper from 'util/UheHelper';
import { useIntl } from 'react-intl';
import { isNotAdmin, isAuthorizedTechnician } from '@util/UheRoleChecker';
import { getOwnUser } from '@uhe_selectors/configuration/users/UsersSelectors';
import Sidebar from '../Sidebar/index';
import HorizontalDefault from '../Topbar/HorizontalDefault/index';
import HorizontalDark from '../Topbar/HorizontalDark/index';
import InsideHeader from '../Topbar/InsideHeader/index';
import AboveHeader from '../Topbar/AboveHeader/index';
import BelowHeader from '../Topbar/BelowHeader/index';

import Topbar from '../Topbar/index';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import NoHeaderNotification from '../Topbar/NoHeaderNotification/index';

import { ACCESS_TOKEN_KEY } from '../../constants/UHESettings';

const { Content, Footer } = Layout;

/**
 *
 */
const MainApp = () => {
  const [hasPermision, setHasPermision] = useState(false);
  const [updatedApp, setUpdatedApp] = useState(false);
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();
  const location = useLocation();
  const qParams = new URLSearchParams(location.search);
  const technicianParam = qParams.has('technician');

  const isRedirected = window.opener;
  /**
   *
   * @param {*} navStyle
   */
  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-container-wrap';
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap';
      default:
        return '';
    }
  };

  /**
   *
   * @param {*} navStyle
   */
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  /**
   *
   * @param {*} navStyle
   * @param {*} width
   */
  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  const loggedUser = useSelector(getOwnUser);

  const intl = useIntl();
  const comBridgeURL = window.env_overrides.REACT_APP_NOTIFICATION_PORTAL_URL || process.env.REACT_APP_NOTIFICATION_PORTAL_URL;

  /** Sends a message before unload
   *@returns {void}
   */
  const unloadHandler = () => {
    if (isRedirected) {
      UheHelper.sendAppCommunicationMessage(`${window.name}AppStarted`, '0');
    }
  };

  /** Handles new messages
   * @param {Object } e event
   *@returns {void}
   */
  const messageHandler = (e) => {
    if (e.origin === comBridgeURL && e.data) {
      switch (e.data.key) {
        case 'notificationAppState':
          window.NotificationsAppState = e.data.value;
          break;
        case 'landingAppState':
          window.DashboardAppState = e.data.value;
          break;
        case 'iconsultAppState':
          window.iConsultAppState = e.data.value;
          break;
        case 'adminAppState':
          window.AdminAppState = e.data.value;
          break;
        case 'technicianAppState':
          window.TechnicanAppState = e.data.value;
          break;
        case 'reportingAppState':
          window.ReportingAppState = e.data.value;
          break;
        case 'iobserverAppState':
          window.iObserverAppState = e.data.value;
          break;
        case 'logout':
          window.close();
          UheHelper.logoutToSSO();
          break;
        default:
      }
      setUpdatedApp(current => !current);
    }
  };

  useEffect(() => {
    if (isNotAdmin(loggedUser) && !isAuthorizedTechnician(loggedUser)) {
      setHasPermision(true);
    }
  }, [loggedUser]);

  useEffect(() => {}, [updatedApp]);

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, []);

  /** Handles redirecting to landing when the user has no permissions
   *@returns {void}
   */
  const redirectToLandingPage = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (accessToken !== null) {
      window.location.href = `${window.env_overrides.REACT_APP_REDIRECT_TO_LANDING_URL || process.env.REACT_APP_REDIRECT_TO_LANDING_URL}/?access_token=${accessToken}`;
    }
  };

  window.name = window.location.pathname.includes('technician') ? 'technician' : 'admin';

  const sendInitialMessage = () => {
    if (isRedirected) {
      UheHelper.sendAppCommunicationMessage(`${window.name}AppStarted`, '1');
    }
  };
  

  /** Opens Dashboard if it isn't opened already
   *@returns {void}
   */
  const goToDashboard = () => {
    if (window.DashboardAppState === '1') {
      if (window.location.href.includes('technician')) {
        UheHelper.sendAppCommunicationMessage('open', 'Technician');
      } else UheHelper.sendAppCommunicationMessage('open', 'Admin');
    } else {
      redirectToLandingPage();
    }
  };

  /** Checks if Admin portal or technician page is open
   *@returns {void}
   */
  const isAppOpened = () => {
    if (window.location.href.includes('technician')) {
      return window.TechnicanAppState;
    } else {
      return window.AdminAppState;
    }
  }

  return (
    <Layout className="gx-app-layout">
      <UHESystemNotifications />
      <iframe style={{ display: 'none' }} src={`${comBridgeURL}/intraAppCommunication.html`} id="appCommunicationFrame" onLoad={() => sendInitialMessage()} />
      {!hasPermision && isRedirected ? getSidebar(navStyle, width) : ''}

      <Layout>
        {isRedirected && getNavStyles(navStyle)}
        {hasPermision && isRedirected
          && (
            <div className="removeToken">
              <p>{intl.formatMessage({ id: 'noAccessMsg' })}</p>
              <span onClick={() => redirectToLandingPage()}>{intl.formatMessage({ id: 'goToDashBoard' })}</span>
            </div>
          )}
        {!isRedirected && isAppOpened() && (
          <div className="removeToken">
            <p>
              {isAppOpened() === '0'
                ? `${window.env_overrides.REACT_APP_NOT_REDIRECTED_MSG || process.env.REACT_APP_NOT_REDIRECTED_MSG}`
                : `${window.env_overrides.REACT_APP_ALREADY_OPENED_MSG || process.env.REACT_APP_ALREADY_OPENED_MSG}`}
            </p>
            {isAppOpened() === '0' && (
              <Button onClick={() => goToDashboard()}>{intl.formatMessage({ id: 'goToDashBoard' })}</Button>
            )}
          </div>
        )}
        {(technicianParam || !isNotAdmin(loggedUser) || isAuthorizedTechnician(loggedUser)) && isRedirected && (
          <Content
            className={`gx-layout-content ${getContainerClass(navStyle)} `}
          >
            <App match={match} location={location} />
            <Footer>
              <div className="gx-layout-footer-content">
                {footerText}
                <small className="footer-version">
                  v
                  {process.env.REACT_APP_VERSION}
                </small>
              </div>
            </Footer>
          </Content>
        )}
      </Layout>
    </Layout>
  );
};
export default MainApp;
