import React from 'react';
import lodash from 'lodash';
import { Card, Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper from 'util/UheHelper';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';

import { unitsOnFetchData, deleteUnits } from '@uhe_actions/configuration/units/UnitsActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
  APP_PAGES_CONTEXT,
  IS_EDIT_PAGE_REGEX
} from '@constants/UHESettings';
import { withLastLocation } from 'react-router-last-location';
import {
  getColumnSorting, setTableData, getCurrentSort, saveDefaultSortOnClick,
} from '@util/UheHelper';

import { shouldResetPaginationSuccess } from '@actions';

/**
 * @description Renders units table
 */
class Units extends React.Component {
  /**
   * @description Adapt data returned by the server
   * @param  {Array<Object>} data
   * @return {Object}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.facility.customer.organization.id,
          name: value.facility.customer.organization.name,
        },
        customer: {
          id: value.facility.customer.id,
          organizationId: value.facility.customer.organization.id,
          name: value.facility.customer.name,
        },
        facility: {
          id: value.facility.id,
          customerId: value.facility.customer.id,
          organizationId: value.facility.customer.organization.id,
          name: value.facility.name,
        },
        unit: {
          id: value.id,
          organizationId: value.facility.customer.organization.id,
          customerId: value.facility.customer.id,
          facilityId: value.facility.id,
          name: value.name,
        },
        bedCartNo: value.total_endpoints,
        configuredApsNo: value.total_configured_endpoints,
        sbtDevicesNo: value.total_endpoints_sbt,
        byodNo: value.total_mobile_users,
        actions: {
          id: value.id,
        },
      });
    });

    return adaptedData;
  }

  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.setTableData = setTableData.bind(this);

    const { history, subtitle, setSubtitle, pagination
    } = this.props;

    this.state = {
      tableData: {
        organization: {
          currSort: 'asc',
          isClicked: false,
        },
        customer: {
          currSort: 'asc',
          isClicked: false,
        },
        facility: {
          currSort: 'asc',
          isClicked: false,
        },
        unit: {
          currSort: 'asc',
          isClicked: false,
        }
      }
    }
    this.data = [];
    this.columns = [];
    const { location } = props;
    const { tableData } = this.state;

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'facility.customer.organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'facility.customer.id',
      [`${TOP_FILTER_PREFIX}facility`]: 'facility.id',
    };
    this.tableFilterMap = {
      organization: 'facility.customer.organization.name',
      customer: 'facility.customer.name',
      facility: 'facility.name',
      unit: 'name',
    };

    this.filterTypes = {};
    this.tableKeys = [
      'organization',
      'customer',
      'facility',
      'unit',
      'bedCartNo',
      'configuredApsNo',
      'sbtDevicesNo',
      'byodNo',
      'actions',
    ];
    this.tableKeys.forEach((value, index) => {
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            cellData={cellData}
            tableData={tableData}
            setTableData={this.setTableData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={
              !(
                value === 'actions'
                || value === 'bedCartNo'
                || value === 'configuredApsNo'
                || value === 'sbtDevicesNo'
                || value === 'byodNo'
                || value === 'iConsultNo'
                || value === 'iObserverNo'
              )
            }
          />
        ),
        sorter: (value === 'actions'
          || value === 'bedCartNo'
          || value === 'iConsultNo'
          || value === 'iObserverNo'
          || value === 'configuredApsNo'
          || value === 'sbtDevicesNo'
          || value === 'byodNo')
          ? false : { multiple: index },
        defaultSortOrder: value ? getColumnSorting(value, location) : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
      },
    ];

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.defaultSorted = true;

    if (subtitle && subtitle.langId !== 'configuration.unit.title') {
      setSubtitle('configuration.unit.title');
    }

    this.onPageChange = this.onPageChange.bind(this);
    this.setTableData = setTableData.bind(this);
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const {
      lastLocation,
      pagination,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag) {
      this.onPageChange(1);
      resetPaginationSuccess();
      return;
    }

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * Updates table on location change
   * @param {Object} prevProps previous props
   * @returns {void} void
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /** @description Change data after filtering
   * @param {number} page
   * @return {Void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];
    const defaultSort = ['facility.customer.organization.name,asc', 'facility.customer.name,asc', 'facility.name,asc', 'name,asc'];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = defaultSort;
    } else if (currSort.length) {
      const customSort = getCurrentSort(currSort, this.tableFilterMap);
      const nonCustomFilteredColumns = saveDefaultSortOnClick(defaultSort, customSort);
      sort = [...customSort, ...nonCustomFilteredColumns];
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;

    this.props.unitsOnFetchData(page - 1, sort, filter);
  }

  /**
 * @description Get endpoint url for data export
 */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting && this.sorting.length ? `&sort=${this.sorting.join('&sort=')}` : '';
    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;
    return `${BASE_URL}${ENDPOINTS.configuration.UnitsTable}/csv?${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * @description Renders cell
   * @param {string} content
   * @param {string} key
   * @return {JSX}
   */
  cellRenderer(content, key) {
    let cell;
    const {
      intl, deleteUnits, loggedUser, location,
    } = this.props;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'unit':
        cell = <UnitCell content={content} location={location} />;
        break;
      case 'facility':
        cell = <FacilityCell content={content} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.units}
            loggedUser={loggedUser}
            intl={intl}
            content={content}
            editLink={`/configuration/units/edit/${content.id}`}
            deleteAction={() => {
              deleteUnits(
                content.id, this.currentPage, this.sort, this.filter,
              );
            }}
          />
        );
        break;

      default:
        cell = content;
    }

    return cell;
  }

  /**
  * @description Render Add Button
  * @returns {JSX}
  */
  renderAddButton = () => {
    const { isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin) {
      return (
        <Link to="/configuration/units/new" shape="circle" icon="+" className="page-icons page-icon-plus">
          <i className="icon icon-add" />
          <span><IntlMessages id="common.addText" /></span>
        </Link>
      )
    }

    return null;
  }

  /**
  * @description Render Export Csv Button
  * @returns {JSX}
  */
  renderExportCsvButton = () => {
    const { isCaregilitySystemAdmin, isCaregilityAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isCaregilityAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin) {
      return (
        <ExportCsv
          getCsvUrl={UheHelper.getCsvUrl(
            this.filter, this.sorting, ENDPOINTS.ExportCsv.units,
          )}
        />
      )
    }
  }


  /**
  * @description Render table
  * @returns {JSX}
  */
  renderTable = () => {
    const { pagination, data, loading, loggedUser } = this.props;
    pagination.onChange = this.onPageChange;
    const { location } = this.props;
    const { tableData } = this.state;

    if (loggedUser.roles) {
      const { isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin } = this.props.loggedUser;
      if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin) {

        if (this.columns.length <= 4) {

          const foundActions = this.tableKeys.filter(key => key === 'actions')

          if (foundActions.length === 0) {
            this.tableKeys.push('actions');
          }
          this.tableKeys.filter(key => key === 'actions').forEach((value, index) => {
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  tableData={tableData}
                  setTableData={this.setStableData}
                  dataKey={value}
                  showFilter={!(value === 'actions')}
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: value ? getColumnSorting(value, location) : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });

        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive"
          columns={this.columns}
          dataSource={Units.dataAdapter(data)}
          pagination={pagination}
          loading={loading}
        />
      )
    }
  }

  render() {
    const { pagination, loading, data } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div className="dashboard configuration">
        <div className="customers-filter-box">
          <Card className="filter-boxes gx-card" title={<IntlMessages id="uhe.title.filters" />}>
            <ListingsTopFilter filters={this.topFilters} />
          </Card>
        </div>
        <div>
          <div className="uhe-table-header">
            <div className="buttons-container">
              {this.renderAddButton()}
              {this.renderExportCsvButton()}
            </div>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

Units.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
  shouldResetPaginationFlag: false,
};

Units.propTypes = {
  data: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  intl: PropTypes.object,
  pagination: PropTypes.object,
  subtitle: PropTypes.object,
  loading: PropTypes.bool,
  unitsOnFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
  lastLocation: PropTypes.object,
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
};

/**
 * mapStateToProps function
 * @param {Object} ConfigurationUnits object
 * @param {Object} subtitle object
 * @param {Object} ConfigurationUsers object
 * @param {Object} common object
 * @returns {Object} object
 */
const mapStateToProps = ({
  ConfigurationUnits,
  subtitle,
  ConfigurationUsers,
  common,
}) => {
  const { list, page } = ConfigurationUnits.table || {};
  const { loading } = ConfigurationUnits;
  const { shouldResetPagination } = common;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list,
    pagination,
    loading,
    subtitle,
    loggedUser: ConfigurationUsers.ownUser,
    shouldResetPaginationFlag: shouldResetPagination,
  };
};

/**
 * mapDispatchToProps function
 * @param {Function} dispatch dispatch
 * @returns {Object} object
 */
const mapDispatchToProps = (dispatch) => ({
  deleteUnits: (id, page, sorting, filter) => dispatch(deleteUnits({
    id, page, sorting, filter,
  })),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  unitsOnFetchData: (page, sort, filter) => dispatch(unitsOnFetchData(page, sort, filter)),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
});

export default
  connect(
    mapStateToProps, mapDispatchToProps,
  )(injectIntl(withLastLocation(withRouter(Units))));
