import {
  DELETE_UHE_BED_CART_SUCCESS,
  DELETE_NON_UHE_BED_CART_SUCCESS,
  FETCH_BED_CART_SUCCESS,
  FETCH_DEVICE_TYPES_SUCCESS,
  FETCH_DEVICE_QR_CODE_SUCCESS,
  FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS,
  CLEAR_BED_CART,
  FETCH_BED_CART_REQUEST,
  SAVE_UHE_BEDCART_REQUEST,
  SAVE_UHE_BEDCART_SUCCESS,
  FETCH_CARE_DEVICE_MODES,
  FETCH_CARE_DEVICE_MODES_SUCCESS,
  FETCH_CALL_ACTION,
  FETCH_CALL_ACTION_SUCCESS,
  DELETE_ROOM_PICTURE_SUCCESS,
  SAVE_BEDCART_FAIL,
  VERIFY_CONNECTIVITY,
  VERIFY_CONNECTIVITY_SUCCESS,
  FETCH_ACKNOWLEDGED_EMAIL_SUCCESS,
  FETCH_COMMANDS_STATUS_SUCCESS,
  FETCH_DEVICE_ACTION,
} from '@constants/UHEActionTypes';
import {
  HARD_REBOOT,
  SOFT_REBOOT,
  REBOOT,
  REBOOT_OS
} from '@constants/UHESettings';

const INIT_STATE = {
  selectedBedCart: {},
  deviceTypes: [],
  loading: false,
  modes: {},
  callInfo: {},
  qrData: {},
  qrAdditionalData: {},
  acknowledged_email: '',
  connectivity: {},
  commandsStatus: {},
  command: '',
};

/**
 *
 * UHE AND NON UHE Beds/Carts Reducers
 * @param  {Object} state state
 * @param  {string} action action
 * @return {Object} updated states
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELETE_UHE_BED_CART_SUCCESS: {
      const selectedUheBedCart = { ...INIT_STATE.selectedUheBedCart };
      const newState = { selectedUheBedCart };
      return { ...state, ...newState };
    }

    case DELETE_NON_UHE_BED_CART_SUCCESS: {
      const selectedNonUheBedCart = { ...INIT_STATE.selectedNonUheBedCart };
      const newState = { selectedNonUheBedCart };
      return { ...state, ...newState };
    }

    case FETCH_BED_CART_SUCCESS: {
      const selectedBedCart = {
        ...INIT_STATE.selectedBedCart,
        ...action.payload,
      };

      const newState = { selectedBedCart, loading: false };
      return { ...state, ...newState };
    }

    case FETCH_BED_CART_REQUEST: {
      return { ...state, loading: true };
    }

    case FETCH_DEVICE_TYPES_SUCCESS: {
      const deviceTypes = [];
      action.data.types.list.forEach((type) => {
        const currentType = {
          value: type.device_common_name,
          active: type.active_device_type,
          disabled: type.active_device_type !== 1,
          list_priority: type.list_priority,
          can_hard_reboot: type.can_hard_reboot,
          has_battery: type.has_battery,
        };
        deviceTypes.push(currentType);
      });
      return { ...state, deviceTypes };
    }

    case FETCH_DEVICE_QR_CODE_SUCCESS: {
      const qrData = action.payload;

      return { ...state, qrData };
    }

    case FETCH_DEVICE_ADDITIONAL_QR_CODE_SUCCESS: {
      const qrAdditionalData = action.payload;

      return { ...state, qrAdditionalData };
    }

    case CLEAR_BED_CART: {
      return { ...INIT_STATE };
    }
    case SAVE_UHE_BEDCART_REQUEST: {
      return { ...state, loading: true };
    }
    case SAVE_BEDCART_FAIL:
      return { ...state, loading: false };

    case FETCH_ACKNOWLEDGED_EMAIL_SUCCESS: {
      return { ...state, acknowledged_email: action.payload.acknowledged_email };
    }

    case SAVE_UHE_BEDCART_SUCCESS: {
      const newState = { savedUheBedCartId: action.payload.id };
      return { ...state, ...newState, loading: false };
    }
    case FETCH_CALL_ACTION: {
      const newState = { savedUheBedCartId: action.payload };
      return { ...state, ...newState };
    }
    case FETCH_CALL_ACTION_SUCCESS: {
      const newState = { callInfo: action.data };
      return { ...state, ...newState };
    }
    case FETCH_CARE_DEVICE_MODES: {
      const newState = { loading: true, saved: true };
      return { ...state, ...newState };
    }
    case FETCH_CARE_DEVICE_MODES_SUCCESS: {
      const newState = { modes: action.data, loading: false };
      return { ...state, ...newState };
    }
    case DELETE_ROOM_PICTURE_SUCCESS: {
      const selectedUheBedCart = { ...INIT_STATE.selectedUheBedCart };
      const newState = { ...selectedUheBedCart };
      return { ...state, ...newState };
    }
    case VERIFY_CONNECTIVITY:
      return { ...state, loading: true };

    case VERIFY_CONNECTIVITY_SUCCESS:
      return { ...state, loading: false, connectivity: action.payload };
    case FETCH_COMMANDS_STATUS_SUCCESS:
      return { ...state, commandsStatus: action.payload };
    case FETCH_DEVICE_ACTION:
      const { command } = action.payload;
      if (command === SOFT_REBOOT || command === REBOOT_OS || command === HARD_REBOOT || command === REBOOT) {
        return { ...state, command };
      }
      return { ...state, command: '' };
    default:
      return state;
  }
};
