import { Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSubsection } from '@uhe_selectors/system/SupportSelectors';

const { Option } = Select;

/**
 * Subsection component
 * @param {object} props component props
 * @returns {JSX} render component
 */
const SubsectionOptions = (props) => {
  const { handleSubsectionID } = props;
  const subsection = useSelector(getSubsection);
  return (
    <Select placeholder="Subsections" onChange={(value) => handleSubsectionID(value)}>
      {subsection && subsection.map((item) => {
        const { name, id } = item;
        return (
          <Option value={id}>
            {name}
          </Option>
        );
      })}
    </Select>
  );
};

export default SubsectionOptions;
