import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Card, Table } from 'antd';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import AddressCell from '@components/tables/cells/AddressCell';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { fetchSignInReports } from '@uhe_actions/reports/SignInReportsActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
} from '@constants/UHESettings';
import { getCurrentSort } from '@util/UheHelper';

class SignInReport extends Component {
  constructor(props) {
    super(props);

    this.data = [];
    this.columns = [];
    this.tableKeys = [
      'name',
      'email',
      'organization',
      'customer',
      'signInCount',
      'callsCount',
    ];

    this.tableFilterMap = {
      name: 'customer.name',
      organization: 'customer.organization.name',
      email: 'email',
      customer: 'customers',
      firstName: 'first_name',
      lastName: 'last_name',
    };

    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'customer.organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'customer.id',
    };

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
    ];

    this.filterTypes = {};
    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            showFilter={!(value === 'signInCount' || value === 'callsCount' || value === 'customer')}
          />
        ),
        sorter: true,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.props.setSubtitle('signInReport.title');

    this.history = this.props.history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.defaultSorted = true;

    this.onPageChange(1);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(this.props.location.search);
      this.onPageChange(this.currentPage + 1);
    }
  }

  /**
   * @description Use different cell type depending on the column
   * @param  {Object} content
   * @param  {string} key
   * @return {ReactElement|Object}
   */
  cellRenderer(content, key) {
    let cell;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'email':
        cell = <AddressCell content={content} />;
        break;

      default:
        cell = content;
    }

    return cell;
  }

  /**
   * Change data after filtering
   * @param {number} page Page
   * @return {Void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (
          this.filterTypes[key] &&
          this.filterTypes[key].type === 'dropdown'
        ) {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = ['customer.name,asc', 'email,asc', 'customer.organization.name,asc', 'customers,asc'];
    } else {
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;

    this.props.SignInReports(page - 1, sort, filter);
  }

  /**
   * @description Adapt data returned from the server
   * @param  {Array<Object>} data
   * @return {Array<Object>}
   */
  dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        name: value.customer.name,
        email: value.email,
        organization: {
          name: value.customer.organization.name,
          id: value.customer.organization.id,
        },
        customer: {
          id: value.customer.id,
          organizationId: value.customer.organization.id,
          name: value.customers,
        },
        signInCount: value.sign_in_count,
        callsCount: value.call_amount,
      });
    });

    return adaptedData;
  }

  render() {
    const { pagination, loading } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div>
        <Card
          className="gx-card"
          title={<IntlMessages id="uhe.title.filters" />}
        >
          <ListingsTopFilter filters={this.topFilters} />
        </Card>
        <div className="uhe-table-header sign-in-table-header">
          <p>
            <IntlMessages id="uhe.table.matchingResults" />
            <span>{this.props.pagination.total}</span>
          </p>
        </div>
        <div className="uhe-table">
          <Table
            bordered
            className="gx-table-responsive sign-in-table"
            columns={this.columns}
            dataSource={this.dataAdapter(this.props.data)}
            pagination={pagination}
            loading={loading}
          />
        </div>
      </div>
    );
  }
}

SignInReport.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
};

SignInReport.propTypes = {
  data: PropTypes.array,
  SignInReports: PropTypes.func,
  setSubtitle: PropTypes.func,
};

/**
 * @description Map Global State to Props
 * @param {Object} SignInReports
 * @param {Object} subtitle
 * @return {Object}
 */
const mapStateToProps = ({ SignInReports, subtitle }) => {
  const { list, page } = SignInReports.table || { page: {}, list: [] };
  const { loading } = SignInReports;
  const { signInData } = SignInReports;
  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return { data: list, pagination, loading, subtitle, signInData };
};

/**
 * @description Map Dispatch to Props
 * @param {function} dispatch
 * @return {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    setSubtitle: (langId) => dispatch(setSubtitle(langId)),
    SignInReports: (page, sort, filter) =>
      dispatch(fetchSignInReports(page, sort, filter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignInReport));
