import React, { useState } from 'react';
import {
  Col, Row, Collapse, Table, Select, Button,
} from 'antd';
import { DOCUMENT_TYPE } from '@constants/UHESettings';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import {
  PicLeftOutlined, FileTextOutlined, PlayCircleOutlined,
} from '@ant-design/icons';
import Subsection from '@routes/System/Settings/Subsection';

const { Option } = Select;

const { Panel } = Collapse;

/**
 * Handles Section Functional Component
 * @param {object} props props
 * @returns {JSX} Render Section Component
 */
const Section = (props) => {
  const {
    section, renderDeleteVideoButton, downloadFile, renderDeleteDocumentButton, loading,
  } = props;
  // console.log(section);
  const { subsections, id } = section;
  // console.log(subsections);
  const alwaysFullWidth = {
    lg: 24, md: 24, sm: 24, xs: 24,
  };
  const { intl: { formatMessage } } = props;
  const [searchType, setSearchType] = useState(null);
  const [sortUpdate, setSortUpdate] = useState(true);
  const [singleData, setSingleData] = useState(null);
  // const deleteSection = (id) => dispatch(SupportActions.deleteSection({ id }));

  /**
  * Adapts the Upload Log Table Videos
  * @param {array} data array
  * @returns {array} data
  */
  const videosAdapter = (data) => {
    const videosData = [];
    data.forEach((video) => {
      videosData.push({
        key: video.id,
        name: video.video_description,
        url: video.youtube_path,
        lastUpdated: video.last_updated,
        video,
      });
    });

    const compareFirstReturn = sortUpdate ? -1 : 1;
    const compareSecondReturn = sortUpdate ? 1 : -1;
    /**
     * Sort function
     * @param {object} a current object
     * @param {object} b next object
     * @returns {num} sort
     */
    const compare = (a, b) => {
      if (a.lastUpdated > b.lastUpdated) {
        return compareFirstReturn;
      }
      if (a.lastUpdated < b.lastUpdated) {
        return compareSecondReturn;
      }
      return 0;
    };

    return videosData.sort(compare);
  };

  const videoColumns = [
    {
      title: <IntlMessages id="support.columnTableName" />,
      key: 'key',
      dataIndex: 'video',
      render: (url) => (
        <div className="download-file">
          <PlayCircleOutlined />
          <a href={url.youtube_path} className="video-columns">
            {url.video_description}
          </a>
        </div>
      ),
    },
    {
      title: <IntlMessages id="support.lastUpdated" />,
      key: 'key',
      dataIndex: 'lastUpdated',
      render: (key) => (new Date(key).toLocaleDateString('en-US')),
    },
    {
      title: 'Delete',
      key: 'key',
      render: (video) => renderDeleteVideoButton(video.key),
    },
  ];

  /**
  * Adapts the Upload Log Table Documents
  * @param {array} data data
  * @returns {array} data
  */
  const documentsAdapter = (data) => {
    const adaptedData = [];
    if (singleData) {
      const filterData = data.filter((document) => document.name === singleData);
      filterData.forEach((document) => {
        adaptedData.push({
          key: document.id,
          name: document.name,
          lastUpdated: document.last_updated,
          document,
        });
      });
    } else {
      data.forEach((document) => {
        adaptedData.push({
          key: document.id,
          name: document.name,
          lastUpdated: document.last_updated,
          document,
        });
      });
    }
    const compareFirstReturn = sortUpdate ? -1 : 1;
    const compareSecondReturn = sortUpdate ? 1 : -1;
    /**
     * Sort function
     * @param {object} a current object
     * @param {object} b next object
     * @returns {num} sort
     */
    const compare = (a, b) => {
      if (a.lastUpdated > b.lastUpdated) {
        return compareFirstReturn;
      }
      if (a.lastUpdated < b.lastUpdated) {
        return compareSecondReturn;
      }
      return 0;
    };
    return adaptedData.sort(compare);
  };

  const documentColumns = [
    {
      title: <IntlMessages id="support.columnTableName" />,
      key: 'key',
      dataIndex: 'document',
      render: (doc) => downloadFile(doc.id, doc.name),
    },
    {
      title: <IntlMessages id="support.lastUpdated" />,
      key: 'lastUpdated',
      dataIndex: 'lastUpdated',
      render: (lastUpdated) => (new Date(lastUpdated).toLocaleDateString('en-US')),
    },
    {
      title: 'Delete',
      key: 'key',
      dataIndex: 'key',

      render: (document) => (renderDeleteDocumentButton(document)),
    },
  ];

  /**
   * Handles subsection
   * @param {number} id parent_id
   * @returns {JSX} render Subsection
   */
  const renderSubsection = (id) => (
    <Subsection
      id={id}
      renderDeleteVideoButton={renderDeleteVideoButton}
      downloadFile={downloadFile}
      renderDeleteDocumentButton={renderDeleteDocumentButton}
      loading={loading}
    />
  );

  /**
  * Handles filter options
  * @param {array} documents array with objects
  * @param {array} videos array with objects
  * @returns {JSX} render filter options
  */
  const renderFilterOptions = (documents, videos) => {
    /**
    * Handles handleChange
    * @param {string} value selected value
    * @returns {function} state function
    */
    const handleNameChange = (value) => {
      setSingleData(value);
    };
    /**
    * Handles handleChange
    * @param {string} value selected value
    * @returns {function} state function
    */
    const handleChange = (value) => {
      setSearchType(value);
    };
    const searchByName = searchType === 0 ? videos : searchType === 1 ? documents : null;
    const docType = DOCUMENT_TYPE
      .map((type, index) => <Option key={index} value={index}>{formatMessage({ id: `support.${type}` })}</Option>);
    return (
      <div className="filter-options">
        <Select
          placeholder="Search type"
          className="top-filter-popup"
          onChange={(value) => handleChange(value)}
        >
          {docType}
        </Select>
        <Select placeholder="Search video or document" showSearch disabled={!((searchType === 0 || searchType === 1))} onChange={(value) => handleNameChange(value)}>
          {searchByName && searchByName.map((item) => {
            const { name, video_description, id } = item;
            return (
              <Option key={id} value={name || video_description}>
                {name || video_description}
              </Option>
            );
          })}
        </Select>
        <Button type="primary" onClick={() => setSortUpdate(!sortUpdate)}>Sort by Update</Button>
      </div>
    );
  };
  return (
    <Collapse className="section-titles collapses" accordion defaultActiveKey={[section.name.indexOf]}>
      <PicLeftOutlined className="picleft" />
      <Panel className="section-titles" header={section.name} key={section.name.indexOf}>
        {renderFilterOptions(section.documents, section.videos, section.id)}
        <Row {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" key={section.id} gutter={16}>
          <Col {...alwaysFullWidth}>
            {(searchType === 1 || searchType === null)
              && (
              <Collapse accordion defaultActiveKey={[section.name.indexOf]} className="collapses">
                <FileTextOutlined className="picleft" />
                <Panel header="Documents" key={section.name.indexOf}>
                  <Table
                    bordered
                    className="gx-table-responsive"
                    columns={documentColumns}
                    dataSource={documentsAdapter(section.documents)}
                    pagination={{ pageSize: 20 }}
                    loading={loading}
                  />
                </Panel>
              </Collapse>
              )}
            {(!searchType || searchType === 0)
              && (
              <Collapse accordion defaultActiveKey={[section.name.indexOf]} className="collapses">
                <PlayCircleOutlined className="picleft" />
                <Panel header="Videos" key={section.name.indexOf}>
                  <Table
                    bordered
                    className="gx-table-responsive"
                    columns={videoColumns}
                    dataSource={videosAdapter(section.videos)}
                    pagination={{ pageSize: 20 }}
                    loading={loading}
                  />
                </Panel>
              </Collapse>
              )}
          </Col>
        </Row>
        {subsections ? renderSubsection(id) : null }
      </Panel>
    </Collapse>
  );
};

Section.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(withRouter(Section));
