import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { bindActionCreators } from 'redux';
import {
  Card,
  Row,
  Col,
  Select,
  Input,
  Form,
} from 'antd';
import * as ssoProviderActions from '@uhe_actions/system/SsoProvidersActions';
import { MFA_OPTIONS } from '@constants/UHESettings';

import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';

import EditHeader from '@components/uhe/configuration/EditHeader';
import { setSubtitle } from '@uhe_actions/SubtitleActions';

const { Option } = Select;

const optionMfa = MFA_OPTIONS.map((item, index) => (
  <Option key={index}>{item}</Option>
));

class ManageSsoProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssoProvider: {
        id: null,
        name: '',
        nextUri: null,
        mfa: '',
        version: null,
        mfaGracePeriodSeconds: 'null',
      },
    };

    this.goBack = this.goBack.bind(this);
    this.renderHeadLine = this.renderHeadLine.bind(this);
    this.saveSsoProvider = this.saveSsoProvider.bind(this);
    this.handlePrefixChange = this.handlePrefixChange.bind(this);
    //this.onChangeVersionHandler = this.onChangeVersionHandler(this);

    this.isNew = this.props.location.pathname.indexOf('/new') !== -1;
    if (this.isNew) {
      if (this.props.subtitle.langId !== 'sso.addTitle') {
        this.props.setSubtitle('sso.addTitle');
      }
    } else if (this.props.subtitle.langId !== "sso.editTitle") {
      this.props.setSubtitle('sso.editTitle');
    }

    this.data = [];

    this.history = this.props.history;
    this.qParams = new URLSearchParams(this.history.location.search);

    this.onPageChange(1);
  }

  /**
   * @description Update local state
   * @returns {void}
   */
  componentDidMount() {
    const urlId = this.props.match.params.id;
    if (urlId && urlId !== this.props.ssoProvider.id) {
      this.props.actions.saveSsoProviderById({ id: urlId });
    }
    if (!this.isNew) {
      this.props.actions.saveSsoProviderById({ id: urlId });
    }
  }

  /**
   * @description Watch for empty data and if is empty set new state data
   * @param {Object} prevProps
   * @return {Object}
   */
  componentDidUpdate(prevProps) {
    const { savedSsoProviderId } = this.props;

    if (prevProps.error !== this.props.error) {
      // handle system error
      if (this.props.error.code === 404 && this.props.error.code === 409 && !this.isNew) {
        this.props.history.push('/system/settings/sso-providers');
      }
    }

    if (this.isNew && savedSsoProviderId !== prevProps.savedSsoProviderId) {
      this.props.history.push(`/system/settings/sso-providers/edit/${savedSsoProviderId}`);
      return;
    }

    if (!this.isNew && prevProps.ssoProvider && this.props.ssoProvider) {
      if ((prevProps.ssoProvider.id !== this.props.ssoProvider.id)
       || (prevProps.ssoProvider.saved !== this.props.ssoProvider.saved)) {
        this.setState({ ssoProvider: this.props.ssoProvider });
      }
    }
    if (prevProps.data.length === 0 && this.props.data.length > 0) {
      this.setState({ data: this.props.data });
    }
  }

  /**
   * @description Handle change in input
   * @param {Object} event
   */
  onChangeHandler(key, event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    this.setState({
      ssoProvider: {
        ...this.state.ssoProvider,
        ...this.state.ssoProvider,
        [key]: event.target.value,
      },
    });
  }

  /**
   * @description Event for change value in input fields
   * @param {string} key
   * @param {Object} event
  */
  onChangeVersionHandler(event) {
    if (event && event.persist && event.preventDefault) {
      event.persist();
      event.preventDefault();
    }
    this.setState({
      ssoProvider: {
        ...this.state.ssoProvider,
        ...this.state.ssoProvider,
        version: event.target.value,
      },
    });
  }

  /**
   * @description Change data after filtering
   * @param {number} page
   * @return {Void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    this.filter = filter;
  }

  /**
   * @description Save Changes on Save Button Click in the header
   * @param {Object} event
   * @returns {void}
   */
  saveSsoProvider(event) {
    event.persist();
    event.preventDefault();
    const { actions } = this.props;
    const { ssoProvider } = this.state;
    actions.saveSsoProvider({ body: ssoProvider, id: this.props.match.params.id });
  }

  /**
   * @description Return to previous page
   * @returns {void}
   */
  goBack() {
    const { history } = this.props;
    history.push('/system/settings/sso-providers');
  }

  handlePrefixChange(key) {
    const { ssoProvider } = this.state;
    ssoProvider.mfa = optionMfa[key].props.children;
    this.setState({ ssoProvider });
  }

  /**
   * @description Render title, back and save buttons
   * @returns {JSX}
   */
  renderHeadLine() {
    const { editMode } = this.props;
    const { loading } = this.props;
    const titleKey = editMode
      ? 'configuration.users.addUser'
      : 'configuration.users.editUser';
    return (
      <EditHeader
        goBack={this.goBack}
        save={this.saveSsoProvider}
        loading={loading}
        titleKey={titleKey}
      />
    );
  }

  render() {
    const { isFetching } = this.props;
    const { ssoProvider } = this.state;
    const { Option } = Select;
    return (
      <div className="manage-ssoProvider-wrapper">
        <Form ref={this.formRef} name="manage-ssoProvider" autoComplete={Math.random()}>
          {this.renderHeadLine()}
          <Row gutter={16}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
                loading={isFetching}
              >
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.name" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="sso.validation.name" />,
                        },
                      ]}
                    >
                      <Input
                        value={ssoProvider.name}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.name' })}
                        autoComplete={Math.random()}
                        title={this.props.intl.formatMessage({ id: 'sso.name' })}
                        onChange={(event) => { this.onChangeHandler('name', event); }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.version" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item>
                      <Input
                        value={ssoProvider.version}
                        autoComplete={Math.random()}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.version' })}
                        title={this.props.intl.formatMessage({ id: 'sso.version' })}
                        onChange={(e) => { this.onChangeVersionHandler(e); }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.mfa" />
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={4} sm={5} xs={9}>
                    <Form.Item>
                      <Select
                        value={ssoProvider.mfa}
                        onChange={this.handlePrefixChange}
                        className="top-filter-popup"
                      >
                        {optionMfa}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.mfaPeriod" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item>
                      <Input
                        autoComplete={Math.random()}
                        value={ssoProvider.mfaGracePeriodSeconds}
                        min={0}
                        max={9999}
                        placeholder=""
                        title={this.props.intl.formatMessage({ id: 'sso.mfaPeriod' })}
                        onChange={(event) => { this.onChangeHandler("mfaGracePeriodSeconds", event); }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="gx-mb-3" gutter={16}>
                  <Col className="gx-d-flex gx-align-items-center" lg={6} md={6} sm={24} xs={24}>
                    <div>
                      <IntlMessages id="sso.nextUri" />
                    </div>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Form.Item>
                      <Input
                        type="text"
                        value={ssoProvider.nextUri}
                        placeholder={this.props.intl.formatMessage({ id: 'sso.nextUri' })}
                        autoComplete={Math.random()}
                        title={this.props.intl.formatMessage({ id: 'sso.nextUri' })}
                        onChange={(event) => { this.onChangeHandler('nextUri', event); }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

ManageSsoProvider.defaultProps = {
  ssoProvider: {},
  data: [],
  loading: true,
};

ManageSsoProvider.propTypes = {
  ssoProvider: PropTypes.object,
  data: PropTypes.array,
  setSubtitle: PropTypes.func,
};

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({ SsoProvidersSettings, subtitle, common }) => {
  const { savedSsoProviderId, editTable } = SsoProvidersSettings;

  return {
    subtitle,
    savedSsoProviderId,
    error: common.error,
    loading: SsoProvidersSettings.loading,
    ssoProvider: SsoProvidersSettings.selectedSsoProvider || {},
    data: editTable,
  };
};

/**
 * @description Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  actions: bindActionCreators(ssoProviderActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(ManageSsoProvider)));
