import {
  CONFIGURATION_ORGANIZATIONS_FETCH_SUCCESS,
  CONFIGURATION_ORGANIZATIONS_FETCH_DATA,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATIONS_SUCCESS,
  SAVE_ORGANIZATION_SUCCESS,
  SAVE_ORGANIZATION_REQUEST,
  CLEAR_ORGANIZATIONS_LOADING,
  FETCH_DATA_SUCCESS_CUSTOMERS,
  FETCH_DATA_CUSTOMERS,
  FETCH_DATA_SUCCESS_FACILITY,
  FETCH_DATA_FACILITY
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  loading: false,
  saved: false,
  table: {
    list: [],
    page: {},
  },
  selectedOrganizationBranding: {
    banner_color: null,
    font_color: null,
    attachment: '',
  },
  selectedOrganization: {
    id: '',
    name: '',
    status: '',
    branding: {
      banner_color: '',
      font_color: '',
      attachment: null,
    },
    advanced_reports: {
      active: 0,
      username: '',
      dashboard: '',
    },
  },
  organizationsFullList: [],
  
  customerTable: {
    list: [],
    page: {}
  },
  facilityTable: {
    list: [],
    page: {}
  }
};
/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = { ...INIT_STATE }, action) => {
  let newState, selectedOrganization;

  switch (action.type) {

    case CONFIGURATION_ORGANIZATIONS_FETCH_SUCCESS:
      newState = { table: action.data, loading: false, saved: false };
      return Object.assign({}, state, newState);

    case SAVE_ORGANIZATION_REQUEST:
    case CONFIGURATION_ORGANIZATIONS_FETCH_DATA:
      newState = { loading: true, saved: false };
      return Object.assign({}, state, newState);

    case FETCH_ORGANIZATION_SUCCESS:
      selectedOrganization = {
        ...INIT_STATE.selectedOrganization,
        ...action.payload,
      };
      newState = { selectedOrganization, loading: false, saved: false };
      return Object.assign({}, state, newState);

    case SAVE_ORGANIZATION_SUCCESS:
      selectedOrganization = action.payload.new
        ? { ...INIT_STATE.selectedOrganization, id: action.payload.id }
        : { ...INIT_STATE.selectedOrganization, ...action.payload };
      newState = { selectedOrganization, loading: false, saved: true };
      return Object.assign({}, state, newState);

    case FETCH_ORGANIZATIONS_SUCCESS:
      const organizations = [...action.payload.list];
      newState = { organizations, loading: false, saved: false };
      return Object.assign({}, state, newState);

    case CLEAR_ORGANIZATIONS_LOADING: {
      return { ...state, loading: false };
    }
    case FETCH_DATA_SUCCESS_CUSTOMERS:
      newState = { customerTable: action.data, loading: false, saved: false };
      return { ...state, ...newState }
    case FETCH_DATA_CUSTOMERS:
      newState = { customerTable: action.data, loading: true, saved: false };
      return Object.assign({}, state, newState);

    case FETCH_DATA_SUCCESS_FACILITY:
      newState = { facilityTable: action.data, loading: false, saved: false };
      return { ...state, ...newState }

    case FETCH_DATA_FACILITY:
      newState = { facilityTable: action.data, loading: true, saved: false };
      return Object.assign({}, state, newState);
    default:
      return state;
  }
};
