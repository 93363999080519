import {
  SUPPORT_VIDEO_FETCH_DATA,
  SUPPORT_VIDEO_FETCH_SUCCESS,
  CREATE_SUPPORT_VIDEO_REQUEST,
  CREATE_SUPPORT_VIDEO_SUCCESS,
  DELETE_SUPPORT_VIDEO_REQUEST,
  DELETE_SUPPORT_VIDEO_SUCCESS,
  SUPPORT_DOCUMENT_FETCH_DATA,
  SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS,
  CREATE_SUPPORT_DOCUMENT_REQUEST,
  CREATE_SUPPORT_DOCUMENT_SUCCESS,
  UPDATE_SUPPORT_DOCUMENT_REQUEST,
  UPDATE_SUPPORT_DOCUMENT_SUCCESS,
  SUPPORT_SECTION_FETCH_DATA,
  SUPPORT_SECTION_FETCH_SUCCESS,
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
  DELETE_SUPPORT_DOCUMENT_REQUEST,
  DELETE_SUPPORT_DOCUMENT_SUCCESS,
  CLEAR_SUPPORT_STATE,
  CREATE_SUPPORT_SECTION_REQUEST,
  CREATE_SUPPORT_SECTION_SUCCESS,
  DELETE_SUPPORT_SECTION_REQUEST,
  DELETE_SUPPORT_SECTION_SUCCESS,
  SUPPORT_SUBSECTION_REQUEST,
  SUPPORT_SUBSECTION_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * Fetch subsection data
 * @param {object} payload subsection data
 * @return {Object} action
 */
export const getSubsectionDataSuccess = (payload) => ({
  type: SUPPORT_SUBSECTION_SUCCESS,
  payload,
});

/**
 * Fetch subsection video data
 * @param {number} payload parent_id
 * @return {Object} action
 */
export const getSubsectionData = (payload) => ({
  type: SUPPORT_SUBSECTION_REQUEST,
  payload,
});

/**
 * @description Fetch support video data
 * @return {Object}
 */
export const supportVideoOnFetchData = () => ({
  type: SUPPORT_VIDEO_FETCH_DATA,

});

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const fetchSupportVideoDataSuccess = (data) => ({
  type: SUPPORT_VIDEO_FETCH_SUCCESS,
  data,
});

/**
 * @description Handle CREATE_SUPPORT_VIDEO_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveSupportVideo = (data) => ({
  type: CREATE_SUPPORT_VIDEO_REQUEST,
  payload: { ...data },
});

/**
 * @description Handle CREATE_SUPPORT_VIDEO_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveSupportVideoSuccess = (payload) => ({
  type: CREATE_SUPPORT_VIDEO_SUCCESS,
  payload: { ...payload },
});

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteVideo = (data) => ({
  type: DELETE_SUPPORT_VIDEO_REQUEST,
  payload: data,
});

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteVideoSuccess = (data) => ({
  type: DELETE_SUPPORT_VIDEO_SUCCESS,
  payload: data,
});

/**
 * @description Fetch support video data
 * @return {Object}
 */
export const supportDocumentOnFetchData = () => ({
  type: SUPPORT_DOCUMENT_FETCH_DATA,

});

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const fetchSupportDocumentDataSuccess = (data) => ({
  type: SUPPORT_DOCUMENT_FETCH_DATA_SUCCESS,
  data,
});

/**
 * @description Handles CREATE_SUPPORT_DOCUMENT_REQUEST Action
 * @returns {Object}
 */
export const uploadDocument = (file, name, sectionId) => ({
  type: CREATE_SUPPORT_DOCUMENT_REQUEST,
  payload: { file, name, sectionId },
});

/**
 * @description Handles CREATE_SUPPORT_DOCUMENT_SUCCESS Action
 * @returns {Object}
 */
export const uploadDocumentSuccess = (uploadedDocumentId, filename, name, sectionId) => ({
  type: CREATE_SUPPORT_DOCUMENT_SUCCESS,
  payload: {
    uploadedDocumentId, filename, name, sectionId,
  },
});

/**
 * @description Fetch support video data
 * @return {Object}
 */
export const supportSectionOnFetchData = () => ({
  type: SUPPORT_SECTION_FETCH_DATA,

});

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const supportSectionOnFetchDataSuccess = (data) => ({
  type: SUPPORT_SECTION_FETCH_SUCCESS,
  data,
});

/**
 * @description Fetch success
 * @param  {Number} id
 * @param  {String} name
 * @return {Object}
 */
export const downloadPdf = (id, name) => ({
  type: DOWNLOAD_PDF_REQUEST,
  payload: {
    id,
    name,
  },
});

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const downloadPdfSuccess = (data) => ({
  type: DOWNLOAD_PDF_SUCCESS,
  data,
});

/**
 * @description Handle SAVE_SUPPORT_DOCUMENT Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveSupportDocument = (id, name) => ({
  type: UPDATE_SUPPORT_DOCUMENT_REQUEST,
  payload: {
    id,
    name,
  },
});

/**
 * @description Fetch success
 * @param  {Object} data
 * @return {Object}
 */
export const saveDocumentSuccess = (data) => ({
  type: UPDATE_SUPPORT_DOCUMENT_SUCCESS,
  data,
});

/**
 * @description DELETE request document
 * @param {Object} data
 * @return {Promise}
 */
export const deleteDocument = (data) => ({
  type: DELETE_SUPPORT_DOCUMENT_REQUEST,
  payload: data,
});

/**
 * @description DELETE request success
 * @param {number} id
 * @returns {Action}
 */
export const deleteDocumentSuccess = (id) => ({
  type: DELETE_SUPPORT_DOCUMENT_SUCCESS,
  payload: { id },
});

/**
 * @description Clear State
 */
export const clearSupportState = () => ({
  type: CLEAR_SUPPORT_STATE,
});

/**
 * @description Add section
 * @param {Object} data
 * @returns {Object}
 */
export const addSection = (data) => ({
  type: CREATE_SUPPORT_SECTION_REQUEST,
  payload: { ...data },
});

/**
 * @description Add section success action
 * @param {Object} payload
 * @returns {Object}
 */
export const addSectionSuccess = (payload) => ({
  type: CREATE_SUPPORT_SECTION_SUCCESS,
  payload: { ...payload },
});

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteSection = (data) => ({
  type: DELETE_SUPPORT_SECTION_REQUEST,
  payload: data,
});

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deleteSectionSuccess = (data) => ({
  type: DELETE_SUPPORT_SECTION_SUCCESS,
  payload: data,
});
