import {
  CONFIGURATION_CUSTOMERS_FETCH_DATA,
  CONFIGURATION_CUSTOMERS_FETCH_SUCCESS,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_BRANDING_REQUEST,
  FETCH_BRANDING_SUCCESS,
  SAVE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_REQUEST,
  SAVE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_SUCCESS,
  SET_REDIRECT_URL,
  CLEAN_UP_SELECTED_CUSTOMER,
  CLEAR_CUSTOMER_LOADING,
  FETCH_INDEMAND_INTERPRETER,
  FETCH_INDEMAND_INTERPRETER_SUCCESS,
  FETCH_STRATUS_INTERPRETER,
  FETCH_STRATUS_INTERPRETER_SUCCESS,
  DELETE_STRATUS_INTEPRETER_REQUEST,
  DELETE_STRATUS_INTEPRETER_SUCCESS,
  UPLOAD_INTERPRETER_REQUEST,
  UPLOAD_INTERPRETER_SUCCESS,
  SAVE_STRATUS_INTERPRETER_REQUEST,
  SAVE_STRATUS_INTERPRETER_SUCCESS,
  CONFIGURATION_CUSTOMERS_FETCH_DEVICES,
  CONFIGURATION_CUSTOMERS_FETCH_DEVICES_SUCCESS,
  BULK_ACTION_REQUEST,
  BULK_ACTION_REQUEST_SUCCESS,
  PENDING_ACTION_DATA,
  PENDING_ACTION_DATA_SUCCESS,
  DELETE_PENDING_ACTION_REQUEST,
  DELETE_PENDING_ACTION_SUCCESS,
  FETCH_INTERPRETER_OPTIONS_REQUEST,
  FETCH_INTERPRETER_OPTIONS_SUCCESS,
  FETCH_INTERPRETER_REQUEST,
  FETCH_INTERPRETER_SUCCESS,
  UPLOAD_JAMF_DATA_REQUEST,
  UPLOAD_JAMF_DATA_REQUEST_SUCCESS,
  NETWORK_CERTIFICATE_REPORT_REQUEST,
  NETWORK_CERTIFICATE_REPORT_SUCCESS,
  FETCH_CSV_REPORT_REQUEST,
  MOVE_DEVICE_REQUEST,
  MOVE_DEVICE_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * @param  {number} page
 * @param  {string} sorting
 * @return {Object}
 */
export const customersOnFetchData = (page, sorting, filter) => {
  return {
    type: CONFIGURATION_CUSTOMERS_FETCH_DATA,
    page,
    sorting,
    filter,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const customersFetchDataSuccess = (data) => {
  return {
    type: CONFIGURATION_CUSTOMERS_FETCH_SUCCESS,
    data,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchCustomer = (payload) => {
  return {
    type: FETCH_CUSTOMER_REQUEST,
    id: payload.id,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchCustomerSuccess = (payload) => {
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload,
  };
};
/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchBranding = (payload) => {
  return {
    type: FETCH_BRANDING_REQUEST,
    id: payload.id,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchBrandingSuccess = (payload) => {
  return {
    type: FETCH_BRANDING_SUCCESS,
    payload,
  };
};

export const deleteCustomer = ({ id, page, sorting, filter }) => {
  return {
    type: DELETE_CUSTOMER_REQUEST,
    id,
    page,
    sorting,
    filter,
  };
};

export const deleteCustomerSuccess = (data) => {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    payload: data,
  };
};
/**
 * @param  {Object} payload
 * @return {Object}
 */
export const saveCustomer = (payload) => {
  return {
    type: SAVE_CUSTOMER_REQUEST,
    payload,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const saveCustomerSuccess = (payload) => {
  return {
    type: SAVE_CUSTOMER_SUCCESS,
    payload,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const setRedirectUrl = (payload) => {
  return {
    type: SET_REDIRECT_URL,
    payload,
  };
};
/**
 * @param  {Object} payload
 * @return {Object}
 */
export const cleanUpSelectedCustomer = () => {
  return {
    type: CLEAN_UP_SELECTED_CUSTOMER,
  };
};

/**
 * @description Handles CLEAR_CUSTOMER_LOADING Action
 * @returns {Object}
 */
export const clearLoading = () => {
  return {
    type: CLEAR_CUSTOMER_LOADING,
  };
};

/**
 * @description Handles FETCH_INDEMAND_INTERPRETER Action
 * @returns {Object}
 */
export const fetchIndemandInterpreter = (id, page) => {
  return {
    type: FETCH_INDEMAND_INTERPRETER,
    id,
    page,
  };
};

/**
 * @description Handles FETCH_INDEMAND_INTERPRETER_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchIndemandInterpreterSuccess = (data) => {
  return {
    type: FETCH_INDEMAND_INTERPRETER_SUCCESS,
    data,
  };
};

/**
 * @description Handles FETCH_STRATUS_INTERPRETER Action
 * @param {number} id
 * @returns {Object}
 */
export const fetchStratusInterpreter = (interpreterId, customerId, page) => {
  return {
    type: FETCH_STRATUS_INTERPRETER,
    payload: {
      interpreterId,
      customerId,
      page,
    },
  };
};

/**
 * @description Handles FETCH_STRATUS_INTERPRETER_SUCCESS Action
 * @param {Object} data
 * @returns {Object}
 */
export const fetchStratusInterpreterSuccess = (data) => {
  return {
    type: FETCH_STRATUS_INTERPRETER_SUCCESS,
    data,
  };
};

/**
 * @description Handles DELETE_STRATUS_INTEPRETER_REQUEST Action
 * @param {number} interpreterId
 * @param {number} customerId
 * @param {number} mappingId
 * @returns {Object}
 */
export const deleteStratusInterpreter = (
  interpreterId,
  customerId,
  mappingId
) => {
  return {
    type: DELETE_STRATUS_INTEPRETER_REQUEST,
    payload: {
      interpreterId,
      customerId,
      mappingId,
    },
  };
};

/**
 * @description Handles DELETE_STRATUS_INTEPRETER_SUCCESS Action
 * @returns {Object}
 */
export const deleteStratusInterpreterSuccess = (payload) => {
  return {
    type: DELETE_STRATUS_INTEPRETER_SUCCESS,
    payload,
  };
};

/**
 * @description Handles UPLOAD_INTERPRETER_REQUEST Action
 * @param {number} interpreterId
 * @param {number} customerId
 * @param {Object} file
 * @returns {Object}
 */
export const uploadInterpreter = (interpreterId, customerId, formData) => {
  return {
    type: UPLOAD_INTERPRETER_REQUEST,
    payload: {
      interpreterId,
      customerId,
      formData,
    },
  };
};

/**
 * @description Handles UPLOAD_INTERPRETER_SUCCESS Action
 * @returns {Object}
 */
export const uploadInterpreterSuccess = (payload) => {
  return {
    type: UPLOAD_INTERPRETER_SUCCESS,
    payload,
  };
};

/**
 * @description Handles SAVE_STRATUS_INTERPRETER_REQUEST Action
 * @param {number} interpreterId
 * @param {number} customerId
 * @param {number} mappingId
 * @param {Object} data
 * @returns {Object}
 */
export const saveStratusInterpreter = (
  interpreterId,
  customerId,
  mappingId,
  data
) => {
  return {
    type: SAVE_STRATUS_INTERPRETER_REQUEST,
    payload: {
      interpreterId,
      customerId,
      mappingId,
      data,
    },
  };
};

/**
 * @description Handles SAVE_STRATUS_INTERPRETER_SUCCESS Action
 * @returns {Object}
 */
export const saveStratusInterpreterSuccess = () => {
  return {
    type: SAVE_STRATUS_INTERPRETER_SUCCESS,
  };
};

/**
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @param {number} id
 * @return {Object}
 */
export const customersOnFetchDevicesData = (page, sorting, filter, id) => {
  return {
    type: CONFIGURATION_CUSTOMERS_FETCH_DEVICES,
    id,
    page,
    sorting,
    filter,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const customersOnFetchDevicesDataSuccess = (data) => {
  return {
    type: CONFIGURATION_CUSTOMERS_FETCH_DEVICES_SUCCESS,
    data,
  };
};

/**
 * @description Handle BULK_ACTION_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const addBulkAction = (data) => {
  return {
    type: BULK_ACTION_REQUEST,
    payload: { ...data },
  };
};

/**
 * @description Handle BULK_ACTION_REQUEST_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const addBulkActionSuccess = (payload) => {
  return {
    type: BULK_ACTION_REQUEST_SUCCESS,
    payload: { ...payload },
  };
};

/**
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @param {number} id
 * @return {Object}
 */
export const pendingActionsData = (page, sorting, filter, id) => {
  return {
    type: PENDING_ACTION_DATA,
    id,
    page,
    sorting,
    filter,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const pendingActionsDataSuccess = (data) => {
  return {
    type: PENDING_ACTION_DATA_SUCCESS,
    data,
  };
};

/**
 * @description DELETE request
 * @param {Object} data
 * @return {Promise}
 */
export const deletePendingAction = (data) => {
  return {
    type: DELETE_PENDING_ACTION_REQUEST,
    payload: data
  };
};

/**
 * @description DELETE request success
 * @param {Object} data
 * @return {Promise}
 */
export const deletePendingActionSuccess = (data) => {
  return {
    type: DELETE_PENDING_ACTION_SUCCESS,
    payload: data,
  };
};

/**
 * @returns {{type}}
 */
export const fetchInterpreterOptions = () => ({
  type: FETCH_INTERPRETER_OPTIONS_REQUEST,
});

/**
 * @param {{}[]} options
 * @returns {{payload, type}}
 */
export const fetchInterpreterOptionsSuccess = (options) => ({
  type: FETCH_INTERPRETER_OPTIONS_SUCCESS,
  payload: options,
});

/**
 * @param {number} id Interpreter id
 * @param {{}} customerId Customer id
 * @returns {{payload: {id, customerId}, type}}
 */
export const fetchInterpreter = (id, customerId) => ({
  type: FETCH_INTERPRETER_REQUEST,
  payload: { id, customerId },
});

/**
 * @param {number} id Interpreter id
 * @param {{}} data Additional data for different types of interpreters
 * @returns {{payload: {id, interpreter}, type}}
 */
export const fetchInterpreterSuccess = (id, data) => ({
  type: FETCH_INTERPRETER_SUCCESS,
  payload: { id, data },
});

/**
 * Handles UPLOAD_DOCUMENT_REQUEST Action
 * @param {Object} file to upload
 * @returns {Object} backend message
 */
export const uploadJamfData = (file) => {
  return {
    type: UPLOAD_JAMF_DATA_REQUEST,
    payload: {
      file,
    },
  };
};

/**
 * Handles UPLOAD_DOCUMENT_SUCCESS Action
 * @returns {Object} wich is uploaded
 */
export const uploadJamfDataSuccess = () => {
  return {
    type: UPLOAD_JAMF_DATA_REQUEST_SUCCESS,
  };
};

/**
 * Action Creator - Network Certificate Report for Specific Customer
 * @param {number} customerId Customer Id
 * @param {number} page Pagination Page
 * @returns {object} Report Data
 */
export const getNetworkCertificateReport = (customerId, page, sorting, filter) => ({
  type: NETWORK_CERTIFICATE_REPORT_REQUEST,
  customerId,
  page,
  sorting,
  filter,
});

/**
 * Action Creator - Network Certificate Report for Specific Customer
 * @param {object} payload Customer Network Certificate Report Data
 * @returns {object} Report Data
 */
export const getNetworkCertificateReportSuccess = (payload) => ({
  type: NETWORK_CERTIFICATE_REPORT_SUCCESS,
  payload,
});

/**
 * Handles FETCH_CSV_REPORT Action
 * @param {number} customerId Customer ID
 * @param {number} bulkId Bulk ID
 * @returns {Object} CSV Report
 */
export const fetchCSVReport = (customerId, bulkId) => ({
  type: FETCH_CSV_REPORT_REQUEST,
  customerId,
  bulkId,
});

/**
 * Handles MOVE_DEVICE_SUCCESS Action
 * @param {number} payload data
 * @returns {Object} device data
 */
export const moveDeviceRequest = (data) => ({
  type: MOVE_DEVICE_REQUEST,
  payload: { ...data },
});

/**
 * Handles MOVE_DEVICE_REQUEST Action
 * @param {number} payload data
 * @returns {Object} device data
 */
export const moveDeviceSuccess = (payload) => ({
  type: MOVE_DEVICE_SUCCESS,
  payload: { ...payload },
});
