import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Card, Row, Col, Input, Form,
} from 'antd';
import {
  saveTwilio,
  getTwilio,
  updateTwilio,
  getTwilioSuccess,
} from '@uhe_actions/system/SystemActions';
import IntlMessages from 'util/IntlMessages';
import { goBackTo } from '@util/UheHelper';
import EditHeader from '@components/uhe/configuration/EditHeader';
import { setSubtitle } from '@uhe_actions/SubtitleActions';

import { onGetOptions } from '@uhe_actions/filters/ListingsTopFilterActions';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/**
 * ManageTwilio Class Component
 */
class ManageTwilio extends Component {
  formRef = React.createRef();

  /**
   * Constructor
   * @param {*} props values
   */
  constructor(props) {
    super(props);
    const {
      location,
      match,
      subtitle,
      setSubtitleAction,
      intl,
    } = this.props;
    this.isNew = location.pathname.indexOf('/new') !== -1;
    this.urlId = match.params.id;

    this.intl = intl;

    this.state = {
      twilio: {
        name: '',
        dial_code: '',
        flag_code: '',
        format: '',
      },
    };

    if (this.isNew) {
      if (subtitle.langId !== 'system.newTwilio') {
        setSubtitleAction('system.newTwilio');
      }
    } else if (subtitle.langId !== 'system.editTwilio') {
      setSubtitleAction('system.editTwilio');
    }
  }

  /**
   * Update local state
   * @returns {void}
   */
  componentDidMount() {
    const { getTwilioAction } = this.props;

    if (!this.isNew) {
      getTwilioAction(this.urlId);
    }
  }

  /**
   * Watch for empty data and if is empty set new state data
   * @param {Readonly<T>} prevProps data
   * @return {Object} data
   */
  componentDidUpdate(prevProps) {
    const {
      twilioId,
      history,
      twilio,
    } = this.props;

    if (this.isNew && twilioId !== prevProps.twilioId) {
      history.push(`/system/settings/system/twilio/edit/${twilioId}`);
      this.isNew = false;
    }

    if (Object.keys(prevProps.twilio).length === 0 && Object.keys(twilio).length > 0) {
      this.setState({ twilio });
    }
  }

  /**
   * Cleanup Method
   * @returns {void}
   */
  componentWillUnmount() {
    const { getTwilioSuccessAction } = this.props;

    getTwilioSuccessAction({});
  }

  /**
   * Event in input field
   * @param {Object} event in input field
   * @param {String} key property
   * @returns {String} input value
   */
  onChangeHandler(event, key) {
    const { twilio } = this.state;
    const newState = { ...twilio, [key]: event.target.value };
    this.setState({
      twilio: newState,
    });
  }

  /**
    * Save Twilio
    * @returns {void}
    */
  saveTwilio = () => {
    this.formRef.current
      .validateFields(['name', 'organization'])
      .then(() => {
        const {
          saveTwilioAction,
          setSubtitleAction,
          updateTwilioAction,
        } = this.props;
        const { twilio } = this.state;

        if (this.isNew) {
          saveTwilioAction({ twilio });
        } else {
          updateTwilioAction({
            body: {
              name: twilio.name,
              dial_code: twilio.dial_code,
              flag_code: twilio.flag_code,
              format: twilio.format,
            },
            id: this.urlId,
          });
        }
        setSubtitleAction('system.editTwilio');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Render title, back and save buttons
   * @returns {JSX} buttons
   */
  renderHeadLine = () => {
    const { lastLocation, history, loading } = this.props;

    return (
      <EditHeader
        save={this.saveTwilio}
        goBack={goBackTo('/system/settings/system/twilio', lastLocation?.pathname, history)}
        backToPreviousPage={this.onBackButtonClick}
        loading={loading}
      />
    );
  }

  /**
   * Handles Back Button Functionality && Clears Input/Local State if necessary
   * @returns {void}
   */
  onBackButtonClick = () => {
    if (!this.isNew) {
      this.isNew = true;
      this.formRef.current.setFieldsValue({
        countryName: '',
      });

      this.setState({
        twilio: {
          countryName: '',
        },
      });
    }

    window.history.back();
  }

  /**
  * Renders ManageOrganization Component
  * @returns {JSX.Element} ManageOrganization
  */
  render() {
    const {
      intl,
      loading,
      twilio,
    } = this.props;

    return (
      <div className="manage-customer-wrapper">
        <Form ref={this.formRef} name="manage-organizations" {...layout} autoComplete="off">
          {this.renderHeadLine()}
          <Row gutter={16}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
                title={<IntlMessages id="system.twilioDetails" />}
                loading={loading}
              >
                <Row lg={24} md={24} sm={24} xs={24} gutter={16}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row"
                      label={<IntlMessages id="uhe.table.countryName" />}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'uhe.table.countryName' }),
                        },
                      ]}
                      initialValue={!this.isNew ? twilio.name : ''}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="name"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'system.countryNameEg' })}
                        title={intl.formatMessage({ id: 'uhe.table.countryName' })}
                        onChange={(event) => this.onChangeHandler(event, 'name')}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row"
                      label={<IntlMessages id="uhe.table.dialingCode" />}
                      name="dial_code"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'uhe.table.dialingCode' }),
                        },
                      ]}
                      initialValue={!this.isNew ? twilio.dial_code : ''}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="dial_code"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'system.dialingCodeEg' })}
                        title={intl.formatMessage({ id: 'uhe.table.dialingCode' })}
                        onChange={(event) => this.onChangeHandler(event, 'dial_code')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row lg={24} md={24} sm={24} xs={24} gutter={16}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row"
                      label={<IntlMessages id="uhe.table.isoCode" />}
                      name="flag_code"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'uhe.table.isoCode' }),
                        },
                      ]}
                      initialValue={!this.isNew ? twilio.flag_code : ''}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="flag_code"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'system.isoCodeEg' })}
                        title={intl.formatMessage({ id: 'uhe.table.isoCode' })}
                        onChange={(event) => this.onChangeHandler(event, 'flag_code')}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      className="align-item-city form-item-row"
                      label={<IntlMessages id="uhe.table.format" />}
                      name="format"
                      rules={[
                        {
                          required: true,
                          message: this.intl.formatMessage({ id: 'uhe.table.format' }),
                        },
                      ]}
                      initialValue={!this.isNew ? twilio.format : ''}
                    >
                      <Input
                        autoComplete={Math.random()}
                        name="format"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'system.formatEg' })}
                        title={intl.formatMessage({ id: 'uhe.table.format' })}
                        onChange={(event) => this.onChangeHandler(event, 'format')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {this.renderHeadLine()}
        </Form>
      </div>
    );
  }
}

ManageTwilio.defaultProps = {
  history: PropTypes.object,
  subtitle: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  intl: PropTypes.shape(),
  twilioId: {},
  twilio: {},
  lastLocation: {},
};

ManageTwilio.propTypes = {
  history: PropTypes.shape(),
  lastLocation: PropTypes.shape(),
  intl: PropTypes.shape(),
  setSubtitleAction: PropTypes.func.isRequired,
  subtitle: PropTypes.shape(),
  match: PropTypes.shape(),
  location: PropTypes.shape(),
  onGetOrganizationOptions: PropTypes.func.isRequired,
  twilioId: PropTypes.shape(),
  twilio: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
  getTwilioAction: PropTypes.func.isRequired,
  getTwilioSuccessAction: PropTypes.func.isRequired,
  saveTwilioAction: PropTypes.func.isRequired,
  updateTwilioAction: PropTypes.func.isRequired,
};

/**
 * Maps Global State to Component's Props
 * @returns {Object} data
 */
const mapStateToProps = ({
  subtitle,
  common,
  ConfigurationUsers,
  SystemSettingsSystem,
}) => {
  const {
    twilioId, selectedTwilio, loading,
  } = SystemSettingsSystem;
  return {
    error: common.error,
    subtitle,
    loggedUser: ConfigurationUsers.ownUser,
    twilioId,
    twilio: selectedTwilio,
    loading,
  };
};

/**
 * Returns Object Which Dispatch Actions to the Store
 * @param {function} dispatch data
 * @returns {Object} data
 */
const mapDispatchToProps = (dispatch) => ({
  setSubtitleAction: (langId) => dispatch(setSubtitle(langId)),
  onGetOrganizationOptions: (id) => dispatch(onGetOptions('organization', id)),
  saveTwilioAction: (data) => dispatch(saveTwilio(data)),
  getTwilioAction: (id) => dispatch(getTwilio(id)),
  updateTwilioAction: (id) => dispatch(updateTwilio(id)),
  getTwilioSuccessAction: (data) => dispatch(getTwilioSuccess(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLastLocation(injectIntl(withRouter(ManageTwilio))));
