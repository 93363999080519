import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Renders Unit cell in table
 * @param {Object} content Content
 * @param {Object} location Location
 * @return {JSX} Return unit cell content
 */
const UnitCell = ({ content, location }) => {
  const {
    id, organizationId, customerId, facilityId, name,
  } = content;
  const { pathname } = location;
  let locationToNavigate = '';

  if (pathname && pathname.includes('/non-uhe-units')) {
    locationToNavigate = '/beds-carts/non-uhe-units';
  } else if (pathname && pathname.includes('/mobile-patients')) {
    locationToNavigate = '/mobile-patients';
  } else {
    locationToNavigate = '/beds-carts/uhe-units';
  }

  return (
    <div className="uhe-table-td">
      <Link to={`/configuration/units/edit/${id}`}>
        <span>{name}</span>
      </Link>
      <Link
        to={`/configuration${locationToNavigate}?topf_organization=${organizationId}&topf_customer=${customerId}&topf_facility=${facilityId}&topf_unit=${id}`}
        className="search-icon"
      >
        <i className="icon icon-search-new" />
      </Link>
    </div>
  );
};

UnitCell.defaultProps = {
  content: {
    name: '',
  },
  location: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
};

UnitCell.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number,
    organizationId: PropTypes.number,
    customerId: PropTypes.number,
    facilityId: PropTypes.number,
    name: PropTypes.string,
  }),
  location: PropTypes.objectOf(PropTypes.string),
};

export default UnitCell;
