import React from 'react';
import { Link } from 'react-router-dom';

const CustomerCell = ({ content, showIco }) => {
  const { id, organizationId, name } = content;


  if (organizationId === null) {
    return (
      <div className="uhe-table-td">
        <Link to={`/configuration/customers/edit/${id}`} title={name}>
          <span>{name}</span>
        </Link>
      </div>
    );
  } else {
    if (name === null) {
      return (
        <div className="uhe-table-td">
          <Link to={`/configuration/customers/edit/${id}`} title={name}>
            <span>{name}</span>
          </Link>
        </div>
      );
    } else
      if (showIco == true) {
        return (
          <div className="uhe-table-td">
              <span>{name}</span>
          </div>
        );
      }
      else {
        return (
          <div className="uhe-table-td">
            <Link to={`/configuration/customers/edit/${id}`} title={name}>
              <span>{name}</span>
            </Link>
            <Link to={`/configuration/facilities?topf_organization=${organizationId}&topf_customer=${id}`} className="search-icon">
              {}
              <i className="icon icon-search-new" />
            </Link>
          </div>
        );
      }
  }
};

export default CustomerCell;
