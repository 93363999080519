import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import lodash from 'lodash';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper from 'util/UheHelper';

import IntlMessages from 'util/IntlMessages';
import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import RestManager from '@util/RestManager';

import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import FacilityCell from '@components/tables/cells/FacilityCell';
import UnitCell from '@components/tables/cells/UnitCell';
import UheUnitCell from '@components/tables/cells/UheUnitCell';
import ActionsCell from '@components/tables/cells/ActionsCell';
import ExportCsv from '@components/ExportCsv/ExportCsv';

import { deleteUheBedCart } from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import { onFetchData } from '@uhe_actions/monitoring/UHEActions';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import {
  LISTING_TABLES_PAGE_SIZE,
  TOP_FILTER_PREFIX,
  TABLE_FILTER_PREFIX,
  APP_PAGES_CONTEXT, IS_EDIT_PAGE_REGEX,
} from '@constants/UHESettings';
import { shouldRenderUheUnitsTable, shouldShowExportButton } from '@util/UheRoleChecker';
import { withLastLocation } from 'react-router-last-location';
import {
  getColumnSorting, setTableData, getCurrentSort, saveDefaultSortOnClick,
} from '@util/UheHelper';

import { shouldResetPaginationSuccess } from '@actions';

/**
 * BedsCarts Class Component (APS Menu Item)
 */
class BedsCarts extends Component {
  /**
   * Adapt data returned from the server
   * @param  {Array<Object>} data UheUnits Table Data
   * @return {Array<Object>} Table Cells Data
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization_id,
          name: value.organization_name,
        },
        customer: {
          id: value.customer_id,
          organizationId: value.organization_id,
          name: value.customer_name,
        },
        facility: {
          id: value.facility_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          name: value.facility_name,
        },
        unit: {
          id: value.unit_id,
          organizationId: value.organization_id,
          customerId: value.customer_id,
          facilityId: value.facility_id,
          name: value.unit_name,
        },
        bedCart: {
          id: value.device_id,
          name: value.cart_name,
          customerId: value.customer_id,
        },
        deviceId: value.device_id,
        parentPartSerialNumber: value.machine_parent_part_serial_number,
        type: value.type,
        actions: {
          device_id: value.device_id,
          customerId: value.customer_id,
        },
      });
    });

    return adaptedData;
  }

  /**
   * BedsCarts Constructor
   * @param {object} props BedsCarts Props
   */
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.setTableData = setTableData.bind(this);
    const { history, subtitle, pagination, setSubtitle } = this.props;
    this.state = {
      tableData: {
        organization: {
          currSort: 'asc',
          isClicked: false,
        },
        customer: {
          currSort: 'asc',
          isClicked: false,
        },
        facility: {
          currSort: 'asc',
          isClicked: false,
        },
        unit: {
          currSort: 'asc',
          isClicked: false,
        },
        bedCart: {
          currSort: 'asc',
          isClicked: false,
        },
        deviceId: {
          currSort: 'asc',
          isClicked: false,
        },
        parentPartSerialNumber: {
          currSort: 'asc',
          isClicked: false,
        },
        type: {
          currSort: 'asc',
          isClicked: false,
        }
      }
    }
    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}organization`]: 'organization_id',
      [`${TOP_FILTER_PREFIX}customer`]: 'customer_id',
      [`${TOP_FILTER_PREFIX}facility`]: 'facility_id',
      [`${TOP_FILTER_PREFIX}unit`]: 'unit_id',
    };

    this.tableFilterMap = {
      organization: 'organization_name',
      customer: 'customer_name',
      facility: 'facility_name',
      unit: 'unit_name',
      bedCart: 'cart_name',
      deviceId: 'device_id',
      parentPartSerialNumber: 'machine_parent_part_serial_number',
      type: 'type',
    };

    this.numberInputs = {
      deviceId: 'number',
    };

    this.filterTypes = {};
    this.data = [];
    this.columns = [];
    this.tableKeys = Object.keys(this.tableFilterMap);
    const { location } = props;

    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      const { tableData } = this.state;
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            tableData={tableData}
            setTableData={this.setTableData}
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
            triggerCharsNum={value === 'deviceId' ? 0 : undefined}
            showFilter={!(value === 'actions')}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: value ? getColumnSorting(value, location) : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'unit',
      },
    ];

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.defaultSorted = true;

    if (
      subtitle && subtitle.langId !== 'sidebar.APS'
    ) {
      setSubtitle('sidebar.APS');
    }
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const {
      lastLocation,
      pagination,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag) {
      this.onPageChange(1);
      resetPaginationSuccess();
      return;
    }

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * Updates table on location change
   * @param {Object} prevProps previous props
   * @returns {void} void
   */
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }
  }

  /**
   * @param  {number} page
   * @return {void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];
    const defaultSort = ['organization_name,asc', 'customer_name,asc', 'facility_name,asc', 'unit_name,asc', 'cart_name,asc', 'device_id,asc', 'machine_parent_part_serial_number,asc', 'type,asc'];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);
      if (filterParam) {
        filter.push(`${value}=${filterParam}`);
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);
      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else if (this.numberInputs[key]) {
          filter.push(`${value}=${encodeURIComponent(Number(filterParam))}`);
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = defaultSort;
    } else if (currSort.length) {
      const customSort = getCurrentSort(currSort, this.tableFilterMap);
      const nonCustomFilteredColumns = saveDefaultSortOnClick(defaultSort, customSort);
      sort = [...customSort, ...nonCustomFilteredColumns];
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;

    this.props.onFetchData(page - 1, sort, filter);
  }

  /**
  * @description Get endpoint url for data export
  */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter
      && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting
      && this.sorting.length ? `&sort=${this.sorting.join('&sort=')}` : '';
    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;
    return `${BASE_URL}${ENDPOINTS.monitoring.UHETable}/csv?${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];
    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  /**
   * Use different cell type depending on the column
   * @param  {Object} content Content
   * @param  {string} key Key
   * @return {ReactElement|Object} Return cells from the table
   */
  cellRenderer(content, key) {
    let cell;
    const {
      intl, history, loggedUser, location, onDeleteUheBedCart,
    } = this.props;

    switch (key) {
      case 'organization':
        cell = <OrganizationCell content={content} />;
        break;
      case 'customer':
        cell = <CustomerCell content={content} />;
        break;
      case 'facility':
        cell = <FacilityCell content={content} />;
        break;
      case 'unit':
        cell = <UnitCell content={content} location={location} />;
        break;
      case 'bedCart':
        cell = <UheUnitCell content={content} />;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={APP_PAGES_CONTEXT.uheUnits}
            loggedUser={loggedUser}
            intl={intl}
            content={content}
            editLink={`/configuration/beds-carts/uhe-units/edit/${content.device_id}/${content.customerId}`}
            deleteAction={() => {
              onDeleteUheBedCart(
                content.device_id,
                this.currentPage,
                this.sort,
                this.filter,
                history,
              );
            }}
          />
        );
        break;

      default:
        cell = content;
    }

    return cell;
  }

  /**
* @description Render Add Button
* @returns {JSX}
*/
  renderAddButton = () => {
    const { isCaregilitySystemAdmin, isOrganizationAdmin, isCustomerAdmin, isFacilityAdmin, isTechnicianAdmin } = this.props.loggedUser;

    if (isCaregilitySystemAdmin || isOrganizationAdmin || isCustomerAdmin || isFacilityAdmin || isTechnicianAdmin) {
      return (
        <Link
          to="/configuration/beds-carts/uhe-units/new"
          shape="circle"
          className="page-icons page-icon-plus"
        >
          <i className="icon icon-add" />
          <span><IntlMessages id="common.addText" /></span>
        </Link>
      )
    }

    return null;
  }

  /**
   * Renders Export CSV Button
   * @returns {JSX} Export CSV Button
   */
  renderExportCsvButton = () => {
    if (shouldShowExportButton()) {
      return (
        <ExportCsv
          getCsvUrl={UheHelper.getCsvUrl(this.filter, this.sorting, ENDPOINTS.ExportCsv.uhe)}
        />
      );
    }
  }

  /**
   * Renders UheUnits Table
   * @returns {JSX} UheUnits Table
   */
  renderTable = () => {
    const {
      pagination, data, loading, loggedUser,
    } = this.props;
    const { location } = this.props;
    pagination.onChange = this.onPageChange;

    if (loggedUser.roles) {
      if (shouldRenderUheUnitsTable()) {
        this.tableFilterMap.actions = 'actions';

        this.tableKeys = Object.keys(this.tableFilterMap);
        if (this.columns.length <= 8) {
          this.tableKeys.filter(key => key === 'actions').forEach((value, index) => {
            const filter = this.filterTypes[value] || {};
            const { tableData } = this.state;
            this.columns.push({
              title: (cellData) => (
                <ListingsTableInputFilter
                  filterType={filter.type}
                  filterOptions={filter.options}
                  tableData={tableData}
                  setTableData={this.setTableData}
                  cellData={cellData}
                  title={`uhe.table.${value}`}
                  dataKey={value}
                  triggerCharsNum={value === 'deviceId' ? 0 : undefined}
                  showFilter={!(value === 'actions')}
                />
              ),
              sorter: (value === 'actions') ? false : { multiple: index },
              defaultSortOrder: value ? getColumnSorting(value, location) : false,
              align: index > 3 ? 'center' : 'left',
              minWidth: 200,
              dataIndex: value,
              render: (content) => this.cellRenderer(content, value),
            });
          });
        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive"
          columns={this.columns}
          dataSource={BedsCarts.dataAdapter(data)}
          onChange={this.handleTableChange}
          pagination={pagination}
          loading={loading}
        />
      );
    }

    return null;
  }

  /**
   * Renders UheUnits Component
   * @returns {JSX} UheUnits Component
   */
  render() {
    const { pagination, data, loading } = this.props;
    pagination.onChange = this.onPageChange;

    return (
      <div className="beds-carts">
        <Card className="filter-boxes gx-card">
          <ListingsTopFilter filters={this.topFilters} />
        </Card>

        <div>
          <div className="uhe-table-header">
            <div className="buttons-container">
              {this.renderAddButton()}
              {this.renderExportCsvButton()}
            </div>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

BedsCarts.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
  shouldResetPaginationFlag: false,
};

BedsCarts.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  intl: PropTypes.object,
  history: PropTypes.object,
  subtitle: PropTypes.object,
  location: PropTypes.object,
  onFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
  lastLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: undefined,
    key: PropTypes.string,
  }),
  loggedUser: PropTypes.shape().isRequired,
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
  onDeleteUheBedCart: PropTypes.func.isRequired,
};

/**
 * mapStateToProps function
 * @param {Object} ConfigurationUnits object
 * @param {Object} subtitle object
 * @param {Object} ConfigurationUsers object
 * @param {Object} common object
 * @returns {Object} object
 */
const mapStateToProps = ({
  MonitoringUHE,
  subtitle,
  ConfigurationUsers,
  common,
}) => {
  const { list = [], page = {} } = MonitoringUHE.table || {};
  const { loading } = MonitoringUHE;
  const { shouldResetPagination } = common;

  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list, pagination, loading, subtitle,
    loggedUser: ConfigurationUsers.ownUser,
    shouldResetPaginationFlag: shouldResetPagination,
  };
};

/**
 * mapDispatchToProps function
 * @param {Function} dispatch dispatch
 * @returns {Object} object
 */
const mapDispatchToProps = (dispatch) => ({
  onDeleteUheBedCart: (device_id, page, sorting, filter, history) => dispatch(deleteUheBedCart({
    device_id, page, sorting, filter, history,
  })),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  onFetchData: (page, sort, filter) => dispatch(onFetchData(page, sort, filter)),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withLastLocation(withRouter(BedsCarts))));
