import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Input, Cascader, Form, Select, Row, Col,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import { BEDCART_CALL_FORMATS } from '@constants/UHESettings';

const { Option } = Select;

/**
 * @description Renders Device Type Card in ManageNonUheBedCart file
 * @param {Object} bedCart
 * @param {boolean} loading
 * @param {function} onChangeDeviceType
 * @param {Array} deviceTypes
 * @param {function} onChangeCallFormat
 * @param {onChangeAddress} onChangeCallFormat
 * @param {Object} intl
 * @returns {JSX}
 */
const DeviceTypeCard = ({
  bedCart,
  loading,
  onChangeDeviceType,
  deviceTypes,
  onChangeCallFormat,
  onChangeAddress,
  intl,
}) => {
  const callFormats = BEDCART_CALL_FORMATS.map((callFormat, index) => (
    <Option key={index} title={callFormat}>
      {callFormat}
    </Option>
  ));

  return (
    <Card
      loading={loading}
      className="gx-card"
      title={<IntlMessages id="configuration.bedsCarts.deviceDetails" />}
    >
      <Row>
        <Col span={23}>
          <Form.Item
            label={<IntlMessages id="configuration.bedsCarts.deviceType" />}
            name="endpoint_type"
          >
            <Cascader
              expandTrigger="hover"
              size="large"
              key="deviceTypes"
              options={deviceTypes || []}
              fieldNames={{ label: 'value', value: 'value' }}
              value={[bedCart.endpoint_type]}
              onChange={onChangeDeviceType}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={23}>
          <Form.Item
            label={<IntlMessages id="configuration.bedsCarts.callFormat" />}
          >
            <Select
              value={bedCart.dial_out_type}
              onChange={(i) => onChangeCallFormat(callFormats[i], 'dial_out_type')}
            >
              {callFormats}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={23}>
          <Form.Item
            label={<IntlMessages id="configuration.bedsCarts.sipAddress" />}
            rules={[
              {
                required: true,
                type: 'email',
                message: intl.formatMessage({
                  id: 'common.error_email',
                }),
              },
            ]}
            name="alias"
          >
            <Input
              autoComplete={Math.random()}
              name="alias"
              value={bedCart.alias}
              onChange={(e) => onChangeAddress(e, 'alias')}
              longdecs={intl.formatMessage({
                id: 'configuration.bedsCarts.descriptions.address',
              })}
              placeholder={intl.formatMessage({
                id: 'configuration.bedsCarts.addrPlaceholder',
              })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={23}>
          <Form.Item
            label={<IntlMessages id="configuration.bedsCarts.acknowledged.notes" />}
            name="notes"
          >
            <Input
              autoComplete={Math.random()}
              name="notes"
              value={bedCart.notes}
              onChange={(e) => onChangeAddress(e, 'notes')}
              placeholder={intl.formatMessage({
                id: 'configuration.bedsCarts.acknowledged.notes',
              })}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

DeviceTypeCard.propTypes = {
  bedCart: PropTypes.object,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  deviceTypes: PropTypes.array,
  onChangeDeviceType: PropTypes.func,
  onChangeCallFormat: PropTypes.func,
  onChangeAddress: PropTypes.func,
};

export default injectIntl(DeviceTypeCard);
