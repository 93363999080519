import React from 'react';
import { Card, Select, Form } from 'antd';
import IntlMessages from 'util/IntlMessages';

const { Option } = Select;
const optionItems = [];

for (let i = 1; i <= 20; i++) {
  optionItems.push(<Option key={i}>{i}</Option>);
}

/**
 * @description Renders Setting Options Card in ManageUheBedCart file
 * @param {Object} bedCart
 * @param {function} onChangeOption
 * @param {boolean} loading
 * @returns {JSX}
 */
const SettingOptionsCard = ({ bedCart, onChangeOption, loading, layout }) => {
  return (
    <Card
      className="gx-card options-card"
      title={<IntlMessages id="configuration.bedsCarts.options.title" />}
      loading={loading}
    >
      <Form.Item
        {...layout}
        className="gx-align-items-center setting-options-label"
        label={<IntlMessages id="configuration.bedsCarts.options.mic" />}
      >
        <Select
          name="default_input"
          value={bedCart.default_input}
          onChange={(e) => {
            onChangeOption(e, "default_input");
          }}
        >
          {optionItems}
        </Select>
      </Form.Item>
      <Form.Item
        {...layout}
        className="gx-align-items-center setting-options-label"
        label={<IntlMessages id="configuration.bedsCarts.options.speaker" />}
      >
        <Select
          value={bedCart.default_output}
          onChange={(e) => {
            onChangeOption(e, "default_output");
          }}
        >
          {optionItems}
        </Select>
      </Form.Item>
      <Form.Item
        {...layout}
        className="gx-align-items-center setting-options-label"
        label={<IntlMessages id="configuration.bedsCarts.options.bell" />}
      >
        <Select
          value={bedCart.default_bell}
          onChange={(e) => {
            onChangeOption(e, "default_bell");
          }}
        >
          {optionItems}
        </Select>
      </Form.Item>
      <Form.Item
        {...layout}
        className="gx-align-items-center setting-options-label"
        label={<IntlMessages id="configuration.bedsCarts.options.camSpeed" />}
      >
        <Select
          value={bedCart.default_speed}
          onChange={(e) => {
            onChangeOption(e, "default_speed");
          }}
        >
          {optionItems}
        </Select>
      </Form.Item>
    </Card>
  );
};

export default SettingOptionsCard;
