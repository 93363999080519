/* eslint-disable no-unneeded-ternary */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'styles/pages/support.less';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  Card, Col, Row, Button, Form, Input, Popconfirm, Typography, Select, Upload,
} from 'antd';
import {
  ExclamationCircleOutlined, FileTextOutlined, UploadOutlined,
} from '@ant-design/icons';
import { setSubtitle as setSubtitleAction } from '@uhe_actions/SubtitleActions';
import * as SupportActions from '@uhe_actions/system/SupportActions';
import { getLoading, getSectionsWithMappedDocuments } from '@uhe_selectors/system/SupportSelectors';
import {
  shouldBeAbleToEditOnSupportPage,
  shouldBeAbleToViewSupportSectionsSection,
} from '@util/UheRoleChecker';
import { DOCUMENT_TYPE } from '@constants/UHESettings';
import SubsectionOptions from '@routes/System/Settings/SubsectionOptions';
import Section from '@routes/System/Settings/Section';

const { Title } = Typography;
const { Option } = Select;

const uploadVideoRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
const alwaysFullWidth = {
  lg: 24, md: 24, sm: 24, xs: 24,
};

const Support = React.memo((props) => {
  const supportDocumentsUploadFormRef = React.useRef();
  const addSectionFormRef = React.useRef();

  const subtitle = useSelector((state) => state.subtitle);
  const loading = useSelector(getLoading);
  const sectionsWithMappedDocuments = useSelector(getSectionsWithMappedDocuments);
  const dispatch = useDispatch();
  const setSubtitle = (langId) => dispatch(setSubtitleAction(langId));
  const supportVideoOnFetchData = () => dispatch(SupportActions.supportVideoOnFetchData());
  const saveSupportVideo = (data) => dispatch(SupportActions.saveSupportVideo(data));
  const deleteVideo = (id) => dispatch(SupportActions.deleteVideo({ id }));
  const supportDocumentOnFetchData = () => dispatch(SupportActions.supportDocumentOnFetchData());
  const uploadDocument = (file, name, sectionId) => dispatch(SupportActions.uploadDocument(file, name, sectionId));
  const supportSectionOnFetchData = () => dispatch(SupportActions.supportSectionOnFetchData());
  const downloadPdf = (id, name) => dispatch(SupportActions.downloadPdf(id, name));
  const deleteDocument = (id) => dispatch(SupportActions.deleteDocument(id));
  const clearSupportState = () => dispatch(SupportActions.clearSupportState());
  const addSection = (data) => dispatch(SupportActions.addSection(data));
  const deleteSection = (id) => dispatch(SupportActions.deleteSection({ id }));
  const getSubsectionData = (id) => dispatch(SupportActions.getSubsectionData(id));

  const [documentType, setDocumentType] = useState(null);
  const [isOption, setIsOption] = useState(false);
  const [subsectionID, setSubsectionID] = useState(null);

  const { intl: { formatMessage } } = props;

  useEffect(() => {
    clearSupportState();
    supportVideoOnFetchData();
    supportDocumentOnFetchData();
    supportSectionOnFetchData();

    if (subtitle && subtitle.langId !== 'sidebar.support') {
      setSubtitle('sidebar.support');
    }

    return () => clearSupportState();
  }, []);

  /**
   *  Save new video data
   * @param {string} path Youtube path
   * @param {string} description description
   * @returns {void}
   */
  const onSubmitUploadVideo = async ({
    path, description, sectionId,
  }) => {
    const currentID = subsectionID ? subsectionID : sectionId;
    try {
      await supportDocumentsUploadFormRef.current.validateFields();
      saveSupportVideo({
        youtube_path: path,
        video_description: description,
        section: {
          id: currentID,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
  * Handles downloadFile function
  * @param {string} id id of the file
  * @param {string} name name of te file
  * @returns {JSX} render button
  */
  const downloadFile = (id, name) => (
    <div className="download-file">
      <FileTextOutlined />
      <Button
        className="document-link"
        title={name}
        onClick={() => downloadPdf(id, name)}
      >
        {name}
      </Button>
    </div>
  );

  /**
  * Handles new section creation
  * @param {string} sectionTitle Name of the new section
  * @returns {Promise<void>} promise
  */
  const onSubmitAddSection = async ({ sectionTitle, currentSection }) => {
    try {
      await addSectionFormRef.current.validateFields();
      addSection({ name: sectionTitle, parent_section: { id: parseInt(currentSection) } });
    } catch (error) {
      console.log(error);
    }
  };

  /**
  * Handles new document upload
  * @param {File} file PDF file document
  * @param {string} name Name of the document
  * @param {number} sectionId Section which the document will belong
  * @returns {Promise<void>} promise
  */
  const onSubmitNewSupportDocument = async ({ file, name, sectionId }) => {
    const currentID = subsectionID ? subsectionID : sectionId;
    try {
      await supportDocumentsUploadFormRef.current.validateFields();
      uploadDocument(file, name, currentID);
    } catch (error) {
      console.log(error);
    }
  };

  /**
  * Render Delete Video Button
  * @param {string} videoId video id
  * @returns {JSX.Element|null} render or not PopConfirm
  */
  const renderDeleteVideoButton = (videoId) => {
    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }

    return (
      <Popconfirm
        title={formatMessage({ id: 'configuration.customer.confirm_delete' })}
        onConfirm={() => deleteVideo(videoId)}
      >
        <Button className="support-delete">
          <IntlMessages id="common.delete" />
        </Button>
      </Popconfirm>
    );
  };
  /**
 * handleSubsection function
 * @param {number} value subsection id
 * @returns {void}
 */
  const handleSubsection = (value) => {
    getSubsectionData(value);
    setIsOption(true);
  };

  /**
 * handleSubsectionID function
 * @param {number} id subsection id
 * @returns {void}
 */
  const handleSubsectionID = (id) => {
    setSubsectionID(id);
  };
  /**
   * Render Upload Support Documents Form or new video data
   * @returns {JSX.Element|null} Support Documents Form
   */
  const renderSupportDocumentsUploadForm = () => {
    const docType = DOCUMENT_TYPE
      .map((type, index) => <Option key={index} value={index}>{formatMessage({ id: `support.${type}` })}</Option>);

    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }
    return (
      <Form
        className="support-forms"
        ref={supportDocumentsUploadFormRef}
        onFinish={documentType === 0 ? onSubmitUploadVideo : onSubmitNewSupportDocument}
      >
        <Row className="gx-d-flex gx-align-items-center" gutter={16}>
          <Col lg={13} md={13} sm={24} xs={24}>
            <Form.Item
              name="documentType"
            >
              <Select
                placeholder={formatMessage({ id: 'support.docType' })}
                className="top-filter-popup"
                onChange={(value) => setDocumentType(value)}
              >
                {docType}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={13} md={13} sm={24} xs={24}>
            <Form.Item
              name="sectionId"
              rules={[
                {
                  required: true,
                  message: formatMessage({ id: 'support.selectName' }),
                },
              ]}
            >
              <Select
                placeholder={formatMessage({ id: 'support.sectionName' })}
                className="top-filter-popup"
                onChange={(value) => handleSubsection(value)}
              >
                {sectionsWithMappedDocuments
                  .map((d) => <Option key={d.id} value={d.id}>{d.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item>
              {isOption
              && <SubsectionOptions handleSubsectionID={handleSubsectionID} />}
            </Form.Item>
          </Col>
        </Row>
        {documentType === 0 && (
          <Row className="gx-d-flex gx-align-items-center" gutter={16}>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item
                name="path"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'configuration.users.emptyField' }),
                  },
                  {
                    pattern: uploadVideoRegex,
                    message: formatMessage({ id: 'support.validVideo' }),
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({ id: 'support.inputURL' })}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'configuration.users.emptyField' }),
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({ id: 'support.inputDescription' })}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item>
                <Button htmlType="submit" className="sendButton">
                  <IntlMessages id="sidebar.dataEntry.upload" />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
        {documentType === 1 && (
          <Row className="gx-d-flex gx-align-items-center" gutter={16}>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'support.nameMessage' }),
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({ id: 'support.documentName' })}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item
                name="file"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'support.selectFile' }),
                  },
                ]}
                getValueFromEvent={(e) => e.file.originFileObj}
              >
                <Upload
                  className="upload_document"
                  accept="application/pdf"
                  beforeUpload={() => false}
                  showUploadList
                >
                  <Button icon={<UploadOutlined />}>
                    &nbsp;
                    <IntlMessages id="support.selectFile" />
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item>
                <Button htmlType="submit" className="sendButton">
                  <IntlMessages id="sidebar.dataEntry.upload" />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    );
  };

  /**
  * Render Delete Document Button
  * @param {string} documentId document id
  * @returns {JSX.Element|null} render or not Popconfirm
  */
  const renderDeleteDocumentButton = (documentId) => {
    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }
    return (
      <Popconfirm
        title={formatMessage({ id: 'configuration.customer.confirm_delete' })}
        onConfirm={() => deleteDocument(documentId)}
      >
        <Button className="support-delete">
          <IntlMessages id="common.delete" />
        </Button>
      </Popconfirm>
    );
  };

  /**
  * Render Add Section Button
  * @returns {JSX.Element|null} render or not Row
  */
  const renderAddSectionForm = () => {
    if (!shouldBeAbleToEditOnSupportPage()) {
      return null;
    }
    return (
      <Row className="baseLineAlignedItems" gutter={16}>
        <Col {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" gutter={24}>
          <Form
            className="support-forms"
            ref={addSectionFormRef}
            onFinish={onSubmitAddSection}
          >
            <Row gutter={16}>
              <Col lg={8} md={8} sm={24} xs={24}>
                <Form.Item
                  className="section-name required-fields"
                  name="sectionTitle"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: 'support.nameMessage' }),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder={formatMessage({ id: 'support.sectionName' })}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={24} xs={24}>
                <Form.Item name="currentSection" rules={[{ required: true }]}>
                  <Select>
                    {sectionsWithMappedDocuments.map((section) => {
                      const { name, id } = section;
                      return (
                        <Option key={id}>{name}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={24} xs={24}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="sendButton"
                  >
                    <IntlMessages id="support.add" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  };

  /**
   * Renders support documents and videos read/last updated/delete section
   * @returns {JSX.Element|null} Support Documets JSX
   */
  const renderSupportDocuments = () => sectionsWithMappedDocuments.map((section) => {
    if (!section.documents?.length && !section.videos?.length) {
      return null;
    }
    return (
      <Section
        section={section}
        renderDeleteVideoButton={renderDeleteVideoButton}
        downloadFile={downloadFile}
        renderDeleteDocumentButton={renderDeleteDocumentButton}
        loading={loading}
      />
    );
  });

  /**
  * Renders sections
  * @returns {JSX.Element|null} render or not sections
  */
  const renderSections = () => {
    /**
     * Handles renderSectionData function
     * @param {object} section section object
     * @returns {JSX} render Title
     */
    const renderSectionData = (section) => (
      <>
        <Title className="support-title" level={4}>
          {section.name}
          :
        </Title>
      </>
    );

    return sectionsWithMappedDocuments
      .map((section) => (
        <Row gutter={16} key={section.id} gutter={section.documents?.length !== 0 ? 24 : 16}>
          <Col span={8} {...alwaysFullWidth}>
            {section.documents?.length !== 0 || section.videos.length !== 0
              ? (
                <>
                  <ExclamationCircleOutlined />
                  {renderSectionData(section)}

                  <Card className="cards" bordered>
                    <div>Documents:</div>
                    <span className="support-description">
                      {`${section.documents?.length} ${formatMessage({ id: 'support.associated' })}`}
                    </span>
                  </Card>
                  <Card className="cards">
                    <div>Videos:</div>
                    <span className="support-description">
                      {`${section.videos?.length} ${formatMessage({ id: 'support.associated' })}`}
                    </span>
                  </Card>
                </>
              )
              : (
                <Popconfirm
                  title={formatMessage({ id: 'configuration.customer.confirm_delete' })}
                  onConfirm={() => deleteSection(section.id)}
                >
                  <Button className="delete-icon support_delete">
                    <i className="icon icon-trash" />
                  </Button>
                  {renderSectionData(section)}
                  <Card className="cards">
                    <div>Documents:</div>
                    <span className="support-description">
                      {`${section.documents?.length} ${formatMessage({ id: 'support.associated' })}`}
                    </span>
                  </Card>
                  <Card className="cards">
                    <div>Videos:</div>
                    <span className="support-description">
                      {`${section.videos?.length} ${formatMessage({ id: 'support.associated' })}`}
                    </span>
                  </Card>
                </Popconfirm>
              )}
          </Col>
        </Row>
      ));
  };

  return (
    <>
      <Card
        className="gx-card customer-edit-branding-card"
        loading={loading}
      >
        {renderSupportDocumentsUploadForm()}
        {renderSupportDocuments()}
      </Card>
      {shouldBeAbleToViewSupportSectionsSection() && (
        <Card
          className="gx-card customer-edit-branding-card"
          title={<IntlMessages id="support.sections" />}
          loading={loading}
        >
          {renderAddSectionForm()}
          {renderSections()}
        </Card>
      )}
    </>
  );
});

Support.displayName = 'Support';

Support.defaultProps = {
};

Support.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(withRouter(Support));
