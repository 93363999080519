import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {
  Card, Table, Form, Input, Modal, Popover,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import RestManager from '@util/RestManager';
import { BASE_URL, ENDPOINTS } from '@constants/UHEEndpoints';
import UheHelper, { convertTimestampToDateTime, getCurrentSort } from 'util/UheHelper';
import {
  shouldShowUsersActionsCell,
  shouldBeAbleToExport,
  shouldBeAbleToImport,
  shouldBeAbleToAdd,
} from '@util/UheRoleChecker';

import ListingsTopFilter from '@filters/ListingsTopFilter';
import ListingsTableInputFilter from '@filters/ListingsTableInputFilter';
import OrganizationCell from '@components/tables/cells/OrganizationCell';
import CustomerCell from '@components/tables/cells/CustomerCell';
import AddressCell from '@components/tables/cells/AddressCell';
import ActionsCell from '@components/tables/cells/ActionsCell';

import {
  usersOnFetchData, deleteUser, fetchImpersonateToken, resetUserPassword, changeUserPassword,
} from '@uhe_actions/configuration/users/UsersActions';
import {
  LISTING_TABLES_PAGE_SIZE, TOP_FILTER_PREFIX, TABLE_FILTER_PREFIX, USER_ROLES, APP_PAGES_CONTEXT, IS_EDIT_PAGE_REGEX,
} from '@constants/UHESettings';
import { setSubtitle } from '@uhe_actions/SubtitleActions';
import { AddButton, ImportButton, ExportButton } from '@components/uhe/listings/Buttons';
import ButtonsContainer from '@components/uhe/listings/ButtonsContainer';
import ChangePasswordModal from '@components/uhe/listings/ChangePasswordModal';
import { withLastLocation } from 'react-router-last-location';
import { getColumnSorting, setTableData } from '@util/UheHelper';
import { shouldResetPaginationSuccess } from '@actions';

/**
 * @description Renders Users table
 */
class Users extends React.Component {
  static pageContext = APP_PAGES_CONTEXT.users;

  /**
   * @description Adapt data returned by the server
   * @param  {Array<Object>} data
   * @return {Object}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        organization: {
          id: value.organization.id,
          name: value.organization.name,
        },
        customer: {
          id: value.customer.id,
          organizationId: value.organization.id,
          name: value.customer.name,
        },
        firstName: value.first_name !== null ? value.first_name : '',
        lastName:
          value.last_name !== null && value.last_name !== null
            ? value.last_name
            : '',
        email: {
          id: value.id,
          name: value.email,
        },
        lastLogin: {
          lastLogin: value.last_sign_in_at,
          organization: value.organization,
        },
        accessLevel: value.admin_level,
        clinician:
          value.is_clinician === 1 ? (
            <i className="icon icon-check-circle-o check-icon" />
          ) : (
            ''
          ),
        iObserver:
          value.is_iobserver === 1 ? (
            <i className="icon icon-check-circle-o check-icon" />
          ) : (
            ''
          ),
        technitian:
          value.is_technician === 1 ? (
            <i className="icon icon-check-circle-o check-icon" />
          ) : (
            ''
          ),
        readOnly:
          value.is_readonly === 1 ? (
            <i className="icon icon-check-circle-o check-icon" />
          ) : (
            ''
          ),
        actions: {
          id: value.id,
          firstName: value.first_name,
          lastName: value.last_name,
        },
      });
    });

    return adaptedData;
  }

  changePasswordFormRef = React.createRef();

  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.setTableData = setTableData.bind(this);
    const { history, subtitle, setSubtitle, pagination } = this.props;
    this.intl = this.props.intl;
    this.state = {
      error: false,
      password: '',
      confirmPassword: '',
      tableData: {
        organization: {
          currSort: 'asc',
          isClicked: false,
        },
        firstName: {
          currSort: 'asc',
          isClicked:false,
        },
        lastName: {
          currSort: 'asc',
          isClicked:false,
        },
        email: {
          currSort: 'asc',
          isClicked:false,
        },
        lastLogin: {
          currSort: 'asc',
          isClicked:false,
        }
      }
    };
    this.topFilterMap = {
      [`${TOP_FILTER_PREFIX}usersRole`]: 'a:role',
      [`${TOP_FILTER_PREFIX}organization`]: 'a:organization.id',
      [`${TOP_FILTER_PREFIX}customer`]: 'a:customer.id',
      [`${TOP_FILTER_PREFIX}facility`]: 'a:facility.id',
      [`${TOP_FILTER_PREFIX}unit`]: 'a:unit.id',
      [`${TOP_FILTER_PREFIX}bedCart`]: 'a:bedCart.id',
    };

    this.tableFilterMap = {
      organization: 'organization.name',
      customer: 'customer.name',
      firstName: 'first_name',
      lastName: 'last_name',
      email: 'email',
      lastLogin: 'last_sign_in_at',
    };

    this.data = [];
    this.columns = [];
    this.tableKeys = [
      'organization',
      'firstName',
      'lastName',
      'email',
      'lastLogin',
      'actions',
    ];

    this.filterTypes = {
      clinician: {
        type: 'dropdown',
        options: [
          { value: '1', label: this.intl.formatMessage({ id: 'common.yes' }) },
          { value: '0', label: this.intl.formatMessage({ id: 'common.no' }) },
        ],
      },
      iObserver: {
        type: 'dropdown',
        options: [
          { value: '1', label: this.intl.formatMessage({ id: 'common.yes' }) },
          { value: '0', label: this.intl.formatMessage({ id: 'common.no' }) },
        ],
      },
      technitian: {
        type: 'dropdown',
        options: [
          { value: '1', label: this.intl.formatMessage({ id: 'common.yes' }) },
          { value: '0', label: this.intl.formatMessage({ id: 'common.no' }) },
        ],
      },
      readOnly: {
        type: 'dropdown',
        options: [
          { value: '1', label: this.intl.formatMessage({ id: 'common.yes' }) },
          { value: '0', label: this.intl.formatMessage({ id: 'common.no' }) },
        ],
      },
      lastLogin: {
        type: 'datepicker',
        options: [],
      },
    };
    const { location } = props;
    const { tableData } = this.state;
    this.tableKeys.forEach((value, index) => {
      const filter = this.filterTypes[value] || {};
      this.columns.push({
        title: (cellData) => (
          <ListingsTableInputFilter
            filterType={filter.type}
            filterOptions={filter.options}
            tableData={tableData}
            setTableData={this.setTableData}
            showFilter={
              !(
                value === 'actions'
              )
            }
            cellData={cellData}
            title={`uhe.table.${value}`}
            dataKey={value}
          />
        ),
        sorter: (value === 'actions') ? false : { multiple: index },
        defaultSortOrder: value ? getColumnSorting(value, location) : false,
        align: index > 3 ? 'center' : 'left',
        minWidth: 200,
        dataIndex: value,
        render: (content) => this.cellRenderer(content, value),
      });
    });

    this.topFilters = [
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byRole',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'usersRole',
        hasOwnValues: true,
        getValue: (index) => Object.values(USER_ROLES[index])[0],
        getIndexValue: (role) => USER_ROLES.findIndex((userRole) => Object.values(userRole)[0] === role),
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byOrganization',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'organization',
        disabled: false,
        isPrevIndependant: true,
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byCustomer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byFacility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byUnit',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'unit',
        shouldSort: true,
      },
      {
        placeholder: 'uhe.listingsTopFilter.inputLabels.byBedcart',
        fieldNames: { label: 'cart_name', value: 'device_id' },
        showSearch: true,
        key: 'bedCart',
        shouldSort: true,
      },
    ];

    this.history = history;
    this.qParams = new URLSearchParams(this.history.location.search);
    this.defaultSorted = true;

    if (
      subtitle && subtitle.langId !== 'configuration.users.title'
    ) {
      setSubtitle('configuration.users.title');
    }
  }

  /**
   * componentDidMount() is invoked immediately after
   * a component is mounted (inserted into the tree)
   * @returns {void} void
   */
  componentDidMount() {
    const {
      lastLocation,
      pagination,
      shouldResetPaginationFlag,
      resetPaginationSuccess,
    } = this.props;

    if (!IS_EDIT_PAGE_REGEX.test(lastLocation?.pathname) || shouldResetPaginationFlag) {
      this.onPageChange(1);
      resetPaginationSuccess();
      return;
    }

    this.onPageChange(pagination?.current || 1);
  }

  /**
   * Updates table on location change
   * @param {Object} prevProps previous props
   * @returns {void} void
   */
  componentDidUpdate(prevProps) {
    const { location, error, history } = this.props;

    if (location.search !== prevProps.location.search) {
      this.qParams = new URLSearchParams(location.search);
      this.onPageChange(1);
    }

    if (prevProps.error !== this.props.error) {
      // handle system error
      if (error.code === 404) {
        history.push('/configuration/users');
      }
    }
  }

  /**
   * @description Change data after filtering
   * @param {number} page
   * @return {Void}
   */
  onPageChange(page) {
    this.currentPage = page - 1;
    const currSort = this.qParams.getAll('sort') || [];
    const filter = [];

    lodash.forOwn(this.topFilterMap, (value, key) => {
      const filterParam = this.qParams.get(key);

      if (filterParam) {
        if (key === 'topf_usersRole') {
          filterParam.split(',').map((param) => filter.push(`${value}=${param}`));
        } else {
          filter.push(`${value}=${filterParam}`);
        }
      }
    });

    lodash.forOwn(this.tableFilterMap, (value, key) => {
      const filterParam = this.qParams.get(`${TABLE_FILTER_PREFIX}${key}`);

      if (filterParam) {
        if (this.filterTypes[key] && this.filterTypes[key].type === 'dropdown') {
          filter.push(`${value}=${encodeURIComponent(filterParam)}`);
        } else if (this.filterTypes[key] && this.filterTypes[key].type === 'datepicker') {
          const qParams = new URLSearchParams(this.history.location.search);
          const lastLoginRange = qParams.getAll('tf_lastLogin');
          lastLoginRange.forEach((element, index) => {
            if (index === 0) {
              filter.push(`${value}%5B=${encodeURIComponent(element * 1000)}`);
            } else {
              filter.push(`${value}%5D=${encodeURIComponent(element * 1000)}`);
            }
          });
        } else {
          filter.push(`${value}~=${encodeURIComponent(`%${filterParam}%`)}`);
        }
      }
    });

    let sort = getCurrentSort(currSort, this.tableFilterMap);

    if (this.defaultSorted && currSort.length === 0) {
      sort = ['organization.name,asc', 'first_name,asc', 'last_name,asc', 'email,asc', 'last_sign_in_at,asc'];
    } else {
      this.defaultSorted = false;
    }

    this.filter = filter;
    this.sort = sort;
    this.props.usersOnFetchData(page - 1, sort, filter);
  }

  /**
  * @description Get endpoint url for data export
  * @returns {string}
  */
  getCsvUrl = () => {
    const token = RestManager.getToken();

    const filterQueryString = this.filter && this.filter.length ? `&${this.filter.join('&')}` : '';
    const sortingQueryString = this.sorting && this.sorting.length ? `&sort=${this.sorting.join('&sort=')}` : '';
    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;

    return `${BASE_URL}${ENDPOINTS.users.saveUser}/csv?${filterQueryString}${sortingQueryString}${tokenParam}`;
  }

  /**
   * @description Check the default sort order for table
   * @returns {string}
   */
  checkSortTableOrder() {
    const { location } = this.props;
    let checkOrder = location.search.split('%2C')[1];

    if (checkOrder === 'asc') {
      checkOrder = 'ascend';
    }
    if (checkOrder === 'desc') {
      checkOrder = 'descend';
    }
    if (!checkOrder) {
      checkOrder = false;
    }

    return checkOrder;
  }

  toggleChangePasswordModal = (user = null) => {
    this.setState({ passwordChangingUser: user });
  }

  /**
   * @description Renders cell
   * @param {string} content
   * @param {string} key
   * @return {JSX}
   */
  cellRenderer(content, key) {
    let cell;
    const { loggedUser, intl, data } = this.props;

    switch (key) {
      case 'organization':
        cell = content.id ? (
          <OrganizationCell content={content} />
        ) : (
          <IntlMessages id="configuration.users.allOrganizations" />
        );
        break;
      case 'customer':
        cell = content.id ? (
          <CustomerCell content={content} />
        ) : (
          <IntlMessages id="configuration.users.allCustomers" />
        );
        break;
      case 'email':
        cell = <AddressCell editLink={`/configuration/users/edit/${content.id}`} content={content} />;
        break;
      case 'lastLogin':
        cell = <Popover content={<IntlMessages id="uhe.table.lastLoginHover" />}>{this.convertToDateAndTime(content)}</Popover>;
        break;
      case 'actions':
        cell = (
          <ActionsCell
            page={Users.pageContext}
            intl={intl}
            loggedUser={loggedUser}
            content={content}
            editLink={`/configuration/users/edit/${content.id}`}
            editPermission={`/configuration/users/edit/${content.id}/permissions`}
            data={data}
            deleteAction={() => {
              this.props.deleteUser(
                content.id,
                this.currentPage,
                this.sort,
                this.filter,
              );
            }}
            resetPasswordAction={() => {
              this.props.resetUserPassword({ body: {}, id: content.id });
            }}
            getToken={() => {
              this.props.fetchImpersonateToken(content.id);
            }}
            openChangePasswordModal={() => this.toggleChangePasswordModal({
              id: content.id, firstName: content.firstName, lastName: content.lastName,
            })}
          />
        );
        break;
      default:
        cell = content;
    }

    return cell;
  }

  /**
   * Change other users password
   * @return {function(): Promise<void>} Awaitable validation task
   */
  onSubmitChangePassword = async () => {
    const { passwordChangingUser } = this.state;
    const { changeUserPassword } = this.props;
    await this.changePasswordFormRef.current?.validateFields();
    const password = this.changePasswordFormRef.current?.getFieldValue('password');
    changeUserPassword({ body: password, id: passwordChangingUser?.id });
    this.toggleChangePasswordModal();
  };

  /**
   * Converts timestamp to Date/Time based on the timezone of the logged in user.
   * @param {object} content Cell Content
   * @return {string} Date/Time
   */
  convertToDateAndTime = (content) => {
    const { lastLogin } = content;
    const { loggedUser } = this.props;
    const { timeZone } = loggedUser;
    const formattedDateTime = convertTimestampToDateTime(lastLogin, timeZone);

    return formattedDateTime;
  }

  /**
  * @description Render table
  * @returns {JSX}
  */
  renderTable = () => {
    const {
      pagination, data, loading, loggedUser,
    } = this.props;
    pagination.onChange = this.onPageChange;
    const { location } = this.props;
    const { tableData } = this.state;

    if (loggedUser.roles) {
      const hasAdminPermission = shouldShowUsersActionsCell(loggedUser);

      if (loggedUser && hasAdminPermission) {
        if (this.columns.length <= 4) {
          const foundActions = this.tableKeys.filter(
            (key) => key === 'actions',
          );

          if (foundActions.length === 0) {
            this.tableKeys.push('actions');
          }

          this.tableKeys
            .filter((key) => key === 'actions')
            .forEach((value, index) => {
              const filter = this.filterTypes[value] || {};
              this.columns.push({
                title: (cellData) => (
                  <ListingsTableInputFilter
                    filterType={filter.type}
                    filterOptions={filter.options}
                    tableData={tableData}
                    setTableData={this.setTableData}
                    showFilter={!(value === 'actions')}
                    cellData={cellData}
                    title={`uhe.table.${value}`}
                    dataKey={value}
                  />
                ),
                sorter: value === 'actions' ? false : { multiple: index },
                defaultSortOrder: value ? this.checkSortTableOrder() : false,
                align: index > 3 ? 'center' : 'left',
                minWidth: 200,
                dataIndex: value,
                render: (content) => this.cellRenderer(content, value),
              });
            });
        }
      }

      return (
        <Table
          bordered
          className="gx-table-responsive"
          columns={this.columns}
          dataSource={Users.dataAdapter(data)}
          pagination={pagination}
          loading={loading}
        />
      );
    }
  }

  render() {
    const { pagination, loggedUser } = this.props;
    const { passwordChangingUser } = this.state;
    pagination.onChange = this.onPageChange;
    return (
      <div className="dashboard configuration">
        <ChangePasswordModal
          user={passwordChangingUser}
          onSubmit={this.onSubmitChangePassword}
          onCancel={this.toggleChangePasswordModal}
          ref={this.changePasswordFormRef}
        />
        <div className="customers-filter-box">
          <Card
            className="filter-boxes gx-card"
            title={<IntlMessages id="uhe.title.filters" />}
          >
            <ListingsTopFilter
              filters={this.topFilters}
              loggedUser={loggedUser}
            />
          </Card>
        </div>
        <div>
          <div className="uhe-table-header">
            <ButtonsContainer>
              <AddButton
                visible={shouldBeAbleToAdd(loggedUser, Users.pageContext)}
                url="/configuration/users/new"
              />
              <ImportButton
                visible={shouldBeAbleToImport(loggedUser, Users.pageContext)}
                url="/configuration/users/bulk/upload"
              />
              <ExportButton
                visible={shouldBeAbleToExport(loggedUser, Users.pageContext)}
                url={UheHelper.getCsvUrl(this.filter, this.sorting, ENDPOINTS.ExportCsv.users)}
              />
            </ButtonsContainer>
            <p>
              <IntlMessages id="uhe.table.matchingResults" />
              <span>{pagination.total}</span>
            </p>
          </div>
          <div className="uhe-table">
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

Users.defaultProps = {
  data: [],
  loading: true,
  pagination: {
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  },
  lastLocation: {
    pathname: '/',
    search: '',
    hash: '',
    state: undefined,
    key: '',
  },
  shouldResetPaginationFlag: false,
};

Users.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  subtitle: PropTypes.object,
  intl: PropTypes.object,
  loggedUser: PropTypes.object,
  loading: PropTypes.bool,
  usersOnFetchData: PropTypes.func,
  setSubtitle: PropTypes.func,
  lastLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: undefined,
    key: PropTypes.string,
  }),
  shouldResetPaginationFlag: PropTypes.bool,
  resetPaginationSuccess: PropTypes.func.isRequired,
};

/**
 * mapStateToProps function
 * @param {Object} ConfigurationUsers object
 * @param {Object} subtitle object
 * @param {Object} common object
 * @returns {Object} object
 */
const mapStateToProps = ({ ConfigurationUsers, subtitle, common }) => {
  const { list, page } = ConfigurationUsers.table || { page: {}, list: [] };
  const { loading } = ConfigurationUsers;
  const { shouldResetPagination, error } = common;

  const pagination = {
    total: page.totalElements || 0,
    current: page.number + 1 || 0,
    pageSize: LISTING_TABLES_PAGE_SIZE,
    defaultCurrent: 1,
  };

  return {
    data: list,
    pagination,
    loading,
    subtitle,
    error,
    loggedUser: ConfigurationUsers.ownUser,
    shouldResetPaginationFlag: shouldResetPagination,
  };
};

/**
 * mapDispatchToProps function
 * @param {Function} dispatch function
 * @returns {Object} object
 */
const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id, page, sorting, filter) => dispatch(deleteUser({
    id, page, sorting, filter,
  })),
  setSubtitle: (langId) => dispatch(setSubtitle(langId)),
  usersOnFetchData: (page, sort, filter) => dispatch(usersOnFetchData(page, sort, filter)),
  fetchImpersonateToken: (id) => dispatch(fetchImpersonateToken(id)),
  resetUserPassword: (id) => dispatch(resetUserPassword(id)),
  changeUserPassword: (id) => dispatch(changeUserPassword(id)),
  resetPaginationSuccess: () => dispatch(shouldResetPaginationSuccess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withLastLocation(withRouter(Users))));
