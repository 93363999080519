import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import {Card} from "antd";
import { connect } from 'react-redux';
import UHEMarkerClusterer from "./Components/UHEMarkerClusterer";
import IntlMessages from "util/IntlMessages";
import { onFetchGmapData } from '@uhe_actions/monitoring/UHEActions';
import { MAP_FILTER_PREFIX } from '@constants/UHESettings';


class MapClustering extends Component {
  static getDerivedStateFromProps(nextProps, prevState){
    const { filter, onFetchGmapData } = nextProps;

    if (JSON.stringify(filter) === JSON.stringify(prevState.filter)) {
      return null;
    }

    nextProps.onFetchGmapData(filter);
    return { filter };
  }

  constructor(props) {
    super(props);

    this.state = {};

    const { onFetchGmapData, filter } = props;
    onFetchGmapData(filter);

    this.history = props.history;
    this.currentZoom = null;
    this.currentBounds = null;
    this.currentCenter = null;
    this.debounceTimeout = null;
    this.qParams = new URLSearchParams(props.history.location.search);
    this.getFilterParameters();

    this.mapOptions = {
      controlSize: 25,
      fullscreenControl: false,
      streetViewControl:false,
    };

    this.clusterStyles = [{
        url: '/small_healthy.png',
        width: 53,
        height: 53,
        anchorIcon: [26, 15],
        anchorText:[0, 0],
        textSize: 11,
      },
      {
        url: '/small_not_configured.png',
        width: 53,
        height: 53,
        anchorIcon: [26, 15],
        anchorText:[0, 0],
        textSize: 11,
      },
      {
        url: '/small_unhealthy.png',
        width: 53,
        height: 53,
        anchorIcon: [26, 15],
        anchorText:[0, 0],
        textSize: 11,
      },
      {
        url: '/medium_healthy.png',
        width: 56,
        height: 56,
        anchorIcon: [35, 20],
        anchorText:[0, 0],
        textSize: 11,
      },
      {
        url: '/medium_not_configured.png',
        width: 56,
        height: 56,
        anchorIcon: [35, 20],
        anchorText:[0, 0],
        textSize: 11,
      },
      {
        url: '/medium_unhealthy.png',
        width: 56,
        height: 56,
        anchorIcon: [35, 20],
        anchorText:[0, 0],
        textSize: 11,
      },
      {
        url: '/large_healthy.png',
        width: 66,
        height: 66,
        anchorIcon: [44, 25],
        anchorText:[0, 0],
        textSize: 11,
      },
      {
        url: '/large_not_configured.png',
        width: 66,
        height: 66,
        anchorIcon: [44, 25],
        anchorText:[0, 0],
        textSize: 11,
      },
      {
        url: '/large_unhealthy.png',
        width: 66,
        height: 66,
        anchorIcon: [44, 25],
        anchorText:[0, 0],
        textSize: 11,
      }
    ];

    this.onZoomBoundsChanged = this.onZoomBoundsChanged.bind(this);
    this.renderResetButton = this.renderResetButton.bind(this);
    this.clearMapFilters = this.clearMapFilters.bind(this);
    this.clusterRenderer = this.clusterRenderer.bind(this);
  }

  /**
   * Handles map zoom and boundaries change event
   *
   * @param {integer} zoom
   * @param {object} bounds
   * @param {object} center
   *
   * @returns {void}
   */
  onZoomBoundsChanged(zoom, bounds, center) {
    if (zoom && bounds && this.hasZoomBoundsChanged(zoom, bounds)) {
      this.currentZoom = zoom;
      this.currentBounds = bounds.toJSON();
      this.currentCenter = center;
      this.debounceAction(this.setFilterParameters, 2000);
    }
  }

  /**
   * Sets URL parameters for map boundary
   *
   * returns {void}
   */
  setFilterParameters() {
    this.qParams.set(`${MAP_FILTER_PREFIX}zoom`, this.currentZoom);
    this.qParams.set(`${MAP_FILTER_PREFIX}center_latitude`, this.currentCenter.lat());
    this.qParams.set(`${MAP_FILTER_PREFIX}center_longitude`, this.currentCenter.lng());
    this.qParams.set(`${MAP_FILTER_PREFIX}latitude_min`, this.currentBounds.south);
    this.qParams.set(`${MAP_FILTER_PREFIX}latitude_max`, this.currentBounds.north);
    this.qParams.set(`${MAP_FILTER_PREFIX}longitude_min`, this.currentBounds.west);
    this.qParams.set(`${MAP_FILTER_PREFIX}longitude_max`, this.currentBounds.east);

    this.history.push({ search: this.qParams.toString() });
  }

  /**
   * Gets map boundary values from URL
   *
   * @returns {void}
   */
  getFilterParameters() {
    if (this.qParams.get(`${MAP_FILTER_PREFIX}zoom`)) {
      this.currentZoom = parseInt(this.qParams.get(`${MAP_FILTER_PREFIX}zoom`));
    }

    if (this.qParams.get(`${MAP_FILTER_PREFIX}center_latitude`) && this.qParams.get(`${MAP_FILTER_PREFIX}center_longitude`)) {
      this.currentCenter = {
        lat: this.qParams.get(`${MAP_FILTER_PREFIX}center_latitude`) ? parseFloat(this.qParams.get(`${MAP_FILTER_PREFIX}center_latitude`)) : null,
        lng: this.qParams.get(`${MAP_FILTER_PREFIX}center_longitude`) ? parseFloat(this.qParams.get(`${MAP_FILTER_PREFIX}center_longitude`)) : null,
      };
    }
    
    if (this.qParams.get(`${MAP_FILTER_PREFIX}latitude_min`) && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_max`)
        && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_min`) && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_max`)) {
      this.currentBounds = {
        south: parseFloat(this.qParams.get(`${MAP_FILTER_PREFIX}latitude_min`)),
        north: parseFloat(this.qParams.get(`${MAP_FILTER_PREFIX}latitude_max`)),
        west: parseFloat(this.qParams.get(`${MAP_FILTER_PREFIX}longitude_min`)),
        east: parseFloat(this.qParams.get(`${MAP_FILTER_PREFIX}longitude_max`)),
      };
    }
  }

  /**
   * Detects zoom or map boundary change
   *
   * @param {integer} zoom
   * @param {object} bounds
   *
   * @returns {Boolean}
   */
  hasZoomBoundsChanged(zoom, bounds) {
    const zoomChanged = zoom !== this.currentZoom;
    const boundsChanged = bounds ? JSON.stringify(this.currentBounds) !== JSON.stringify(bounds.toJSON()) : false;
    return zoomChanged || boundsChanged;
  }

  /**
   * Debounces a callback with a defined delay
   *
   * @param {Function} callback
   * @param {integer}   wait
   *
   * @returns {void}
   */
  debounceAction (callback, wait) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => callback.apply(this, arguments), wait);
  }

  /**
   * Clears map zoom and boundary parameters from URL
   *
   * @returns {void}
   */
  clearMapFilters() {
    let sholdPush = false;

    if (this.qParams.get(`${MAP_FILTER_PREFIX}zoom`)) {
      this.qParams.delete(`${MAP_FILTER_PREFIX}zoom`);
      sholdPush = true
    }

    if (this.qParams.get(`${MAP_FILTER_PREFIX}center_latitude`) && this.qParams.get(`${MAP_FILTER_PREFIX}center_longitude`)) {
      this.qParams.delete(`${MAP_FILTER_PREFIX}center_latitude`);
      this.qParams.delete(`${MAP_FILTER_PREFIX}center_longitude`);
      sholdPush = true
    }

    if (this.qParams.get(`${MAP_FILTER_PREFIX}latitude_min`) && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_max`)
        && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_min`) && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_max`)) {
      this.qParams.delete(`${MAP_FILTER_PREFIX}longitude_min`);
      this.qParams.delete(`${MAP_FILTER_PREFIX}longitude_max`);
      this.qParams.delete(`${MAP_FILTER_PREFIX}latitude_min`);
      this.qParams.delete(`${MAP_FILTER_PREFIX}latitude_max`);
      sholdPush = true
    }

    if (sholdPush) {
      this.history.push({ search: this.qParams.toString() });
    }
  }

  /**
   * Renders the reset button element
   *
   * @returns {ReactElement|string}
   */
  renderResetButton() {
    const { showResetButton } = this.props;
    const statusFilter = this.isMapFilterActive();

    if (showResetButton) {
      return (
        <button className="ant-btn reset-btn ant-btn-sm" onClick={this.clearMapFilters} disabled={!statusFilter}>
          <IntlMessages id="uhe.map.clearFilter" />
        </button>
      );
    }

    return '';
  }

  /**
   * Returns a boolean value if any map filte is currently active
   *
   * @return {Boolean} [description]
   */
  isMapFilterActive() {
    return this.qParams.get(`${MAP_FILTER_PREFIX}zoom`) 
      || (this.qParams.get(`${MAP_FILTER_PREFIX}center_latitude`) && this.qParams.get(`${MAP_FILTER_PREFIX}center_longitude`))
      || (
          this.qParams.get(`${MAP_FILTER_PREFIX}latitude_min`) && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_max`)
          && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_min`) && this.qParams.get(`${MAP_FILTER_PREFIX}latitude_max`)
        );
  }
  
  /**
 * The default function for determining the label text and style
 * for a cluster icon.
 *
 * @param {Array.<google.maps.Marker>} markers The array of markers represented by the cluster.
 * @param {number} numStyles The number of marker styles available.
 * @returns {ClusterIconInfo} The information resource for the cluster.
 */
  clusterRenderer(markers, numStyles) {
    const title = "";
    const count = markers.length.toString();
    let index = 0;
    let dv = count;

    while (dv !== 0) {
      dv = parseInt(dv / 10, 10);
      index++;
    }

    index = Math.min(index, numStyles);
    let finalIndex;

    for (let i=0; i < markers.length; i = i+1) {
      if (markers[i].icon === '/marker_unhealthy.png') {
        if (index === 1) finalIndex = 3;
        if (index === 2) finalIndex = 6;
        if (index === 3) finalIndex = 9;
        break;
      }
      else if (markers[i].icon === '/marker_not_configured.png') {
        if (index === 1) finalIndex = 2;
        if (index === 2) finalIndex = 5;
        if (index === 3) finalIndex = 8;
      }
    }

    if (!finalIndex) {
      if (index == 1) finalIndex = 1;
      if (index == 2) finalIndex = 4;
      if (index == 3) finalIndex = 7;
    }
    
    return {
      text: count,
      index: finalIndex,
      title: title
    };
  }

  render() {
    const { loading } = this.props;
    
    return (
      <Card id="map-filter-holder" loading={loading} className="gx-card" title={<IntlMessages id="uhe.map.title"/>} extra={this.renderResetButton()}>
        <UHEMarkerClusterer
          onZoomBoundsChanged={this.onZoomBoundsChanged}
          zoom={this.currentZoom}
          mapCenter={this.currentCenter}
          mapOptions={this.mapOptions}
          clusterStyles={this.clusterStyles}
          clusterRenderer={this.clusterRenderer}
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ UheGmap = {} }) => {
  const { loading = true, data = [] } = UheGmap;

  return { loading, data };
};

export default connect(mapStateToProps, {
  onFetchGmapData,
})(withRouter(MapClustering));
