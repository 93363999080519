import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  saveMobilePatientSuccess,
  fetchMobilePatientSuccess,
  mobilePatientAccessDataSuccess,
  savePermissionsSuccess,
  mobilePatientsDataSuccess,
  uploadMobilePatientsDocumentSuccess,
  saveMobilePatientFail,
  fetchMDMTokenSuccess,

} from '@actions/uhe/configuration/mobilePatients/MobilePatientsActions';
import {
  CONFIGURATION_MOBILE_PATIENTS_DATA,
  DELETE_MOBILE_PATIENT_REQUEST,
  SAVE_MOBILE_PATIENT,
  SAVE_PERMISSIONS_REQUEST,
  FETCH_MOBILE_PATIENT,
  MOBILE_PATIENT_ACCESS_DATA,
  UPLOAD_MOBILE_PATIENT_DOCUMENT_REQUEST,
  FETCH_MDM_TOKEN,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { fetchUploadLogsRequest } from '@actions/uhe/configuration/users/UsersActions'

/**
 * @description Request mobile patients data
 * @param  {number} page
 * @param  {string} sorting
 * @param  {string} filter
 * @return {Object}
 */
const doFetchData = async (page, sorting, filter) => {
  const filterQueryString =
    filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString =
    sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  // eslint-disable-next-line no-return-await
  return await RestManager.request(
    `${ENDPOINTS.configuration.UsersTable}?is_mobile_user=1&page=${
      page || 0
    }${sortingQueryString}${filterQueryString}`,
  );
};

/**
 * @description Request filtering mobile patients table data
 * @param {number} page
 * @param {Array} sorting
 * @param {string} filter
 * @return {Objects}
 */
function* fetchTableData({ page, sorting, filter }) {
  try {
    const fetchedData = yield call(doFetchData, page, sorting, filter);
    yield put(mobilePatientsDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Upload mobile patient document request
 * @param {Object} file
 * @returns {Object}
 */
const uploadDocumentRequest = async (file) => {
  return await RestManager.formDataRequest(
    `${ENDPOINTS.users.uploadMobilePatients}`,
    file,
  )
}

/**
 * @description Handles Response and Request for uploading CSV file
 * @param {Object} payload
 * @returns {void}
 */
function* uploadDocument(payload) {
  try {
    const uploadDocument = yield call(uploadDocumentRequest, payload.payload.file);

    if (uploadDocument) {
      yield put(uploadMobilePatientsDocumentSuccess());
      yield put(fetchUploadLogsRequest())

      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchUploadLogsRequest());
    yield put(fetchError(error));
  }
}


/**
 * @description Delete request
 * @param {string} id
 * @return  {Promise}
 */
const deleteMobilePatient = async (id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.configuration.UsersTable}/${id}`,
    'DELETE'
  );
};

/**
 * @description Delete mobile patient by given id
 * @param {Object} data
 * @return {void}
 */
function* delMobilePatient(data) {
  try {
    const deletedmobilePatient = yield call(deleteMobilePatient, data.payload.id);
    if (deletedmobilePatient && deletedmobilePatient.status >= 200 && deletedmobilePatient.status < 300) {
      yield call(fetchTableData, {
        sorting: data.payload.sorting,
        page: data.payload.page,
        filter: data.payload.filter,
      });
      yield put(showMessage('apdDeleted'));
    } else {
      console.error('DELETE ERROR: ', deletedmobilePatient);
      yield put(fetchError(deletedmobilePatient));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

/**
 * @description Request for add new mobile patient
 * @param  {Object} bodyData
 * @return {Object}
 */
const addNewMobilePatient = async (bodyData) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.configuration.UsersTable}`,
    'POST',
    bodyData
  );
};

/**
 * @description Sends POST Request
 * @param {Object} mobilePatient
 * @param {number} id
 * @returns {Array}
 */
const saveMobilePatientById = async (mobilePatient, id) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.configuration.UsersTable}/${id}`,
    'POST',
    mobilePatient
  );
};

/**
 * @description Save video
 * @param {Object} data
 * @returns {void}
 */
function* saveMobilePatient(data) {
  try {
    if (data.payload.mobilePatient.id) {
      const toSave = { ...data.payload.mobilePatient, id: undefined };
      const mobilePatient = yield call(saveMobilePatientById, toSave, data.payload.mobilePatient.id);

      if (mobilePatient) {
        mobilePatient.id = data.payload.mobilePatient.id;
        mobilePatient.saved = true;
        yield put(fetchMobilePatientSuccess(mobilePatient));
        yield put(showMessage('save_success'));
      }
    } else {
      const mobilePatientId = yield call(addNewMobilePatient, data.payload.mobilePatient);
      if (mobilePatientId) {
        yield put(saveMobilePatientSuccess(mobilePatientId));
        yield put(showMessage('apdCreated'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(saveMobilePatientFail());
    if (error.code === 409) {
      const fetchedMobilePatient = yield call(doFetchMobilePatientById, data.payload.mobilePatient.id);
      if (fetchedMobilePatient) {
        yield put(fetchMobilePatientSuccess({ ...fetchedMobilePatient}));
      }
    }
  }
}

/**
 * @description Sends GET Request
 * @param {String} id
 * @returns {Array}
 */
const doFetchMobilePatientById = async (id) => {
  return await RestManager.request(`${ENDPOINTS.configuration.UsersTable}/${id}`);
};

/**
 * @description Fetching Facility by Id
 * @param {Object} id
 * @returns {void}
 */
function* fetchMobilePatientById({ id }) {
  try {
    const fetchedMobilePatient = yield call(doFetchMobilePatientById, id);
    if (fetchedMobilePatient) {
      yield put(fetchMobilePatientSuccess({ ...fetchedMobilePatient, id }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request Permission Data
 * @param {number} id
 * @return {Object}
 */
const doFetchaccessTableData = async (id) => {
  return await RestManager.request(
    `${ENDPOINTS.configuration.UsersTable}/${id}/permissions`
  );
};

/**
 * @description Request Permission Data
 * @param {Object} id
 * @return {void}
 */

function* accessTableData({ id }) {
  try {
    const fetchedData = yield call(doFetchaccessTableData, id);
    yield put(mobilePatientAccessDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Save request for Permission data
 * @param {number} id
 * @param {Object} data
 * @return {Object}
 */
const doSavePermissions = async (id, data) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.mobilePatients.configuration.UsersTable}/${id}/permissions`,
    'POST',
    data
  );
};

/**
 * @description Save permission
 * @param {Object} data
 */
function* saveMobilePatientPermissions(data) {
  try {
    const mobilePatient = yield call(doSavePermissions, data.payload.id, data.payload.body);
    if (mobilePatient) {
      yield put(savePermissionsSuccess(mobilePatient));
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sends POST Request
 * @param {String} id
 * @returns {Array}
 */

 const doFetchMdmtoken = async (id, bodyData) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.configuration.UsersTable}/${id}/mdm_token`,
    'POST',
    bodyData
  );
};

/**
 * Fetching BYOD mdm token by Id
 * @param {Object} id user
 * @returns {void}
 */
function* fetchMdmTokenById({ id }) {
  try {
    const fetchedMobilePatient = yield call(doFetchMdmtoken, id);
    if (fetchedMobilePatient) {
      yield put(fetchMDMTokenSuccess({ ...fetchedMobilePatient, id }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(CONFIGURATION_MOBILE_PATIENTS_DATA, fetchTableData);
  yield takeEvery(UPLOAD_MOBILE_PATIENT_DOCUMENT_REQUEST, uploadDocument);
  yield takeEvery(SAVE_MOBILE_PATIENT, saveMobilePatient);
  yield takeEvery(FETCH_MOBILE_PATIENT, fetchMobilePatientById);
  yield takeEvery(DELETE_MOBILE_PATIENT_REQUEST, delMobilePatient);
  yield takeEvery(MOBILE_PATIENT_ACCESS_DATA, accessTableData);
  yield takeEvery(SAVE_PERMISSIONS_REQUEST, saveMobilePatientPermissions);
  yield takeEvery(FETCH_MDM_TOKEN, fetchMdmTokenById);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
