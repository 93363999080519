import {
  CONFIGURATION_FACILITIES_FETCH_DATA,
  CONFIGURATION_FACILITIES_FETCH_SUCCESS,
  DELETE_FACILITY_REQUEST,
  DELETE_FACILITY_SUCCESS,

  FETCH_FACILITIES_REQUEST,
  FETCH_FACILITIES_SUCCESS,
  FETCH_FACILITY_REQUEST,
  FETCH_FACILITY_SUCCESS,
  CLEAR_FACILITY,
  SAVE_FACILITY_REQUEST,
  SAVE_FACILITY_SUCCESS,
  SET_REDIRECT_URL,
  CLEAR_FACILITY_STATE,
  SAVE_FACILITY_FAIL,
} from "@constants/UHEActionTypes";

/**
 * @param  {number} page
 * @param  {string} sorting
 * @return {Object}
 */
export const onFetchData = (page, sorting, filter, size) => {
  return {
    type: CONFIGURATION_FACILITIES_FETCH_DATA,
    page,
    sorting,
    filter,
    size,
  };
};

/**
 * @param  {Object} data
 * @return {Object}
 */
export const fetchDataSuccess = (data) => {
  return {
    type: CONFIGURATION_FACILITIES_FETCH_SUCCESS,
    data,
  }
};

export const deleteFacility = (data) => {
  return {
    type: DELETE_FACILITY_REQUEST,
    payload: data,
  };
};

/**
 * @description Handle FETCH_FACILITIES_REQUEST Action
 * @returns {Object}
 */
export const getFacilities = () => {
  return {
    type: FETCH_FACILITIES_REQUEST,
  };
};

/**
 * @description Handle FETCH_FACILITIES_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchFacilitiesSuccess = (payload) => {
  return {
    type: FETCH_FACILITIES_SUCCESS,
    payload,
  };
};

/**
 * @description Handle FETCH_FACILITY_REQUEST Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchFacility = (payload) => {
  return {
    type: FETCH_FACILITY_REQUEST,
    id: payload.id,
  };
};

/**
 * @description Handle FETCH_FACILITY_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchFacilitySuccess = (payload) => {
  return {
    type: FETCH_FACILITY_SUCCESS,
    payload,
  };
};

/**
 * @description Handle CLEAR_FACILITY Action
 * @returns {Object}
 */
export const clearSelectedFacility = () => {
  return {
    type: CLEAR_FACILITY,
  };
};

/**
 * @description Handle SAVE_FACILITY_REQUEST Action
 * @param {Object} data
 * @returns {Object}
 */
export const saveFacility = (data) => {
  return {
    type: SAVE_FACILITY_REQUEST,
    payload: data,
  };
};

/**
 * Fetch action when save request fail
 * @returns {boolean} after catch error
 */
export const saveFacilityFail = () => {
  return {
    type: SAVE_FACILITY_FAIL,
  };
};

/**
 * @description Handle SAVE_FACILITY_SUCCESS Action
 * @param {Object} payload
 * @returns {Object}
 */
export const saveFacilitySuccess = (payload) => {
  return {
    type: SAVE_FACILITY_SUCCESS,
    payload,
  };
};

export const setRedirectUrl = (payload) => {
  return {
    type: SET_REDIRECT_URL,
    payload,
  };
};

export const clearFacilityState = () => {
  return {
    type: CLEAR_FACILITY_STATE,
  };
};