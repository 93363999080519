import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, Input,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  fetchDeviceAction,
  saveUheBedCart,
  fetchCommandsStatus,
} from '@uhe_actions/configuration/bedsCarts/bedsCartsActions';
import {
  update_version,
  mac_refresh,
} from '@constants/UHEDeviceActions';
import moment from 'moment-timezone';
import {
  HARD_REBOOT,
  SOFT_REBOOT,
  REBOOT,
  REBOOT_OS,
} from '@constants/UHESettings';

/**
 * StatusReport Class Component
 * @returns {jsx} dasd
 */
class StatusReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  /**
   * Renders Left Part of the StatusReport
   * @returns {JSX.Element} Left Part of the StatusReport
   */
  deviceStatus = () => {
    const {
      bedCart, intl, hasBattery, command, commandsStatus,
    } = this.props;
    const {
      color_alert_button, color_camera, color_camera_controls, color_online, color_version, has_battery, endpointHardware,
    } = bedCart;
    let certificateData = [];
    if (endpointHardware) {
      const {
        user_cert_date, user_cert_sum, user_key_date, user_key_sum,
      } = endpointHardware;
      certificateData = [user_cert_date, user_cert_sum, user_key_date, user_key_sum];
    }

    if (bedCart.endpointStatus) {
      return (
        <div>

          <Row lg={24} md={24} className="status-items-row status-online">
            <Col className="margin-right-10" lg={17}>
              <IntlMessages id="configuration.bedsCarts.statusReport.online" />
              {' '}
              :
            </Col>
            <Col lg={3} md={3}>
              <div
                className={`status-icon-${color_online}`}
              />
            </Col>
          </Row>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col className="margin-right-10" lg={17}>
              <IntlMessages id="configuration.bedsCarts.statusReport.camera" />
              {' '}
              :
            </Col>
            <Col lg={3} md={3}>
              <div
                className={`status-icon-${color_camera}`}
              />
            </Col>
          </Row>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col className="margin-right-10" lg={17}>
              <IntlMessages id="configuration.bedsCarts.statusReport.cameraControls" />
              {' '}
              :
            </Col>
            <Col lg={3} md={3}>
              <div
                className={`status-icon-${color_camera_controls}`}
              />
            </Col>
          </Row>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col className="margin-right-10" lg={17}>
              <IntlMessages id="configuration.bedsCarts.statusReport.alertButton" />
              {' '}
              :
            </Col>
            <Col lg={3} md={3}>
              <div
                className={`status-icon-${color_alert_button}`}
              />
            </Col>
          </Row>
          <Row lg={24} md={24} className="status-items-row status-online">
            <Col className="margin-right-10" lg={17}>
              <IntlMessages id="configuration.bedsCarts.statusReport.version" />
              {' '}
              :
            </Col>
            <Col lg={3} md={3}>
              <div
                className={`status-icon-${color_version}`}
              />
            </Col>
          </Row>
          {has_battery
          && (
          <Row lg={24} md={24} className="status-items-row status-online battery">
            <Col className="margin-right-10" lg={17}>
              <IntlMessages
                id="configuration.bedsCarts.statusReport.batteryLevel"
              />
              {' '}
              :
            </Col>
            <Col lg={3} md={3}>
              {bedCart?.battery?.level
                ? (
                  <div>
                    {bedCart?.battery?.level.toFixed(2)}
                    %
                  </div>
)
                : <IntlMessages id="common.null" />}
            </Col>
          </Row>
          )}
          {certificateData.map((item, index) => (
            <Row lg={24} md={24} className="status-items-row status-online">
              <Col className="margin-top-10" lg={17}>
                <IntlMessages id={`configuration.bedsCarts.statusReport.certificateFields.${index}`} />
                {' '}
                :
              </Col>
              <Col lg={17}>
                {item}
              </Col>
            </Row>
          ))}

        </div>
      );
    }
    return (
      <div>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col className="margin-right-10" lg={17}>
            <IntlMessages id="configuration.bedsCarts.statusReport.online" />
            {' '}
            :
          </Col>
          <Col lg={3} md={3}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col className="margin-right-10" lg={17}>
            <IntlMessages id="configuration.bedsCarts.statusReport.camera" />
            {' '}
            :
          </Col>
          <Col lg={3} md={3}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col className="margin-right-10" lg={17}>
            <IntlMessages
              id="configuration.bedsCarts.statusReport.cameraControls"
            />
            {' '}
            :
          </Col>
          <Col lg={3} md={3}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col className="margin-right-10" lg={17}>
            <IntlMessages
              id="configuration.bedsCarts.statusReport.alertButton"
            />
            {' '}
            :
          </Col>
          <Col lg={3} md={3}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        <Row lg={24} md={24} className="status-items-row status-online">
          <Col className="margin-right-10" lg={17}>
            <IntlMessages
              id="configuration.bedsCarts.statusReport.version"
            />
            {' '}
            :
          </Col>
          <Col lg={3} md={3}>
            <div
              className={`status-icon-${color_alert_button}`}
              title={intl.formatMessage({
                id: 'common.offline',
              })}
            />
          </Col>
        </Row>
        {has_battery
          && (
          <Row lg={24} md={24} className="status-items-row status-online battery">
            <Col className="margin-right-10" lg={17}>
              <IntlMessages
                id="configuration.bedsCarts.statusReport.batteryLevel"
              />
              {' '}
              :
            </Col>
            <Col lg={3} md={3}>
              {bedCart?.battery?.level
                ? (
                  <div>
                    {bedCart?.battery?.level.toFixed(2)}
                    %
                  </div>
)
                : <IntlMessages id="common.null" />}
            </Col>
          </Row>
          )}
      </div>
    );
  };

  update() {
    const {
      bedCart, doFetchDeviceAction, saveUheBedCartEdit, match,
    } = this.props;

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: update_version,
      id: match.params.id,
    });
  }

  refresh() {
    const {
      bedCart, doFetchDeviceAction, saveUheBedCartEdit, match,
    } = this.props;

    saveUheBedCartEdit({
      body: bedCart,
      id: match.params.id,
    });

    doFetchDeviceAction({
      machine_name: bedCart.machine_name,
      command: mac_refresh,
      id: match.params.id,
    });
  }

  /**
   * Shows Date and Time at Which is Created CAR System Device
   * @returns {string} Converted Date
   */
  convertToCurrentTimezone() {
    const { bedCart, timeZone } = this.props;

    if (!bedCart.endpointStatus) {
      return <IntlMessages id="common.null" />;
    }

    const timeStamp = bedCart.endpointStatus.created_at;
    const date = timeZone
      ? moment(timeStamp).tz(timeZone).format('YYYY-MM-DD hh:mm:ss A z')
      : moment(timeStamp).format('YYYY-MM-DD hh:mm:ss A z');

    return date;
  }

  /**
   * Whether the hostname field should be disabled
   * @returns {boolean} true or false
   */
  shouldDisableHostname() {
    const { bedCart } = this.props;
    return bedCart?.color_online === 'green';
  }

  /**
   * Renders StatusReport Component
   * @returns {JSX.Element} StatusReport Component
   */
  render() {
    const {
      bedCart, match, latestVersion, updateHostname, command, commandsStatus,
    } = this.props;

    /**
   * Determines what message to render
   * @returns {string} message reference
   */
    const message = () => {
      let newCommand;
      if (command === HARD_REBOOT) {
        newCommand = REBOOT;
      } else {
        newCommand = command;
      }
      if (newCommand && commandsStatus[newCommand] === 'success') {
        return 'restartSuccess';
      }
      if (newCommand && (!commandsStatus[newCommand] || commandsStatus[newCommand] === 'pending')) {
        return newCommand;
      }
      return 'restartFail';
    };

    return (
      <div>
        {command
          && (
          <Col lg={24}>
            <Row lg={24} md={24} className="status-items-row status-online">
              <Col className="margin-right-10 restartMessage" lg={24}>
                <IntlMessages id={`configuration.bedsCarts.statusReport.${message()}`} />
              </Col>
            </Row>
          </Col>
          )}
        <Row className="main-row">
          <Col lg={8} md={24} sm={24} xs={24}>
            {this.deviceStatus()}
          </Col>
          <Col lg={16} md={24} sm={24} xs={24}>
            <Row className="status-items-row status-online">
              <Col className="margin-right-10" lg={8}>
                <IntlMessages id="configuration.bedsCarts.statusReport.deviceId" />
                {' '}
                :
              </Col>
              <Col lg={7}>
                <div>{match.params.id}</div>
              </Col>
            </Row>
            <Row className="status-items-row status-online">
              <Col className="margin-right-10" lg={8}>
                <IntlMessages id="configuration.bedsCarts.statusReport.InternalIp" />
                {' '}
                :
              </Col>
              <Col lg={7}>
                <div>{bedCart.internal_ip ? bedCart.internal_ip : <IntlMessages id="common.null" />}</div>
              </Col>
            </Row>
            <Row className="status-items-row status-online">
              <Col className="margin-right-10" lg={8}>
                <IntlMessages id="configuration.bedsCarts.statusReport.macAddress" />
                {' '}
                :
              </Col>
              <Col lg={13}>
                <div className="fixed-value-width">
                  {bedCart.endpointStatus
                    ? bedCart.endpointStatus.machine_id
                    : <IntlMessages id="common.null" />}
                </div>
                <Button onClick={this.refresh} className="save-icon header-btn act-btn"><IntlMessages id="common.refresh" /></Button>
              </Col>
              <Col lg={6} />
            </Row>
            <Row className="status-items-row status-online">
              <Col className="margin-right-10" lg={8}>
                <IntlMessages id="configuration.bedsCarts.statusReport.Hostname" />
                {' '}
                :
              </Col>
              <Col lg={7}>
                {bedCart.endpointHardware
                  ? (
                    <Input
                      autoComplete={Math.random()}
                      value={bedCart?.endpointHardware?.hostname}
                      disabled={!this.shouldDisableHostname()}
                      onChange={(e) => updateHostname(e, 'hostname')}
                    />
                  )
                  : <IntlMessages id="common.null" />}
              </Col>
            </Row>
            <Row className="status-items-row status-online">
              <Col className="margin-right-10" lg={8}>
                <IntlMessages id="configuration.bedsCarts.statusReport.systemVersion" />
                {' '}
                :
              </Col>
              <Col lg={7}>
                <div>{latestVersion}</div>
              </Col>
            </Row>
            <Row lg={24} md={24} className="status-items-row status-online">
              <Col className="margin-right-10" lg={8}>
                <IntlMessages id="configuration.bedsCarts.statusReport.OSVersion" />
                {' '}
                :
              </Col>
              <Col lg={7} md={7}>
                {bedCart.endpointHardware?.machine_release
                  ? <div>{bedCart.endpointHardware.machine_release}</div>
                  : <IntlMessages id="common.null" />}
              </Col>
            </Row>
            <Row className="status-items-row status-online">
              <Col className="margin-right-10" lg={8}>
                <IntlMessages id="configuration.bedsCarts.statusReport.deviceVersion" />
                {' '}
                :
              </Col>
              <Col lg={13}>
                <div className="fixed-value-width">
                  {bedCart.endpointStatus ? bedCart.endpointStatus.version : <IntlMessages id="common.null" />}
                </div>
                <Button onClick={this.update} className="save-icon header-btn act-btn"><IntlMessages id="configuration.users.updatePassword" /></Button>
              </Col>
              <Col lg={6} />
            </Row>
          </Col>
        </Row>
        <Col lg={24}>
          <Row className="status-items-row status-online" lg={24} align="bottom">
            <Col className="margin-right-10" lg={24} md={24}>
              <IntlMessages id="configuration.bedsCarts.statusReport.firstCameOnline" />
              {' '}
              :
            </Col>
            <Col lg={24}>
              <div>
                {this.convertToCurrentTimezone()}
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

StatusReport.defaultProps = {
  match: {},
  command: '',
};

StatusReport.propTypes = {
  bedCart: PropTypes.shape().isRequired,
  match: PropTypes.shape(),
  intl: PropTypes.shape().isRequired,
  latestVersion: PropTypes.number.isRequired,
  doFetchDeviceAction: PropTypes.func.isRequired,
  saveUheBedCartEdit: PropTypes.func.isRequired,
  hasBattery: PropTypes.bool.isRequired,
  command: PropTypes.shape(),
  updateHostname: PropTypes.func.isRequired,
};

/**
 * Maps State to the Props
 * @param {object} bedsCarts bedsCarts reducer
 * @returns {object} states
 */
const mapStateToProps = ({
  bedsCarts,
}) => ({
  command: bedsCarts.command,
  commandsStatus: bedsCarts.commandsStatus,
});

/**
 * Maps Actions to the Props
 * @param {func} dispatch dispatch function
 * @returns {object} Actions
 */
const mapDispatchToProps = (dispatch) => ({
  doFetchDeviceAction: (command, machine, param) => dispatch(fetchDeviceAction(command, machine, param)),
  saveUheBedCartEdit: (data) => dispatch(saveUheBedCart(data)),
  doFetchCommandsStatus: (id) => dispatch(fetchCommandsStatus(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(StatusReport)));
