import {
  all, call, fork, put, takeEvery, select,
} from 'redux-saga/effects';
import {
  activateUserSuccess,
  changeUserPasswordSuccess,
  clearLoading,
  fetchDataSuccess,
  fetchImpersonateTokenSuccess,
  fetchUploadLogsRequest,
  fetchUploadLogsSuccess,
  fetchUser,
  fetchUserAccessDataSuccess,
  fetchUserGrant,
  fetchUserGrantSuccess,
  fetchUserSuccess,
  onFetchOwnPofileSuccess,
  resetUserPasswordSuccess,
  savePermissionsSuccess,
  saveUserGrants as saveUserGrantsRequest,
  saveUserGrantsSuccess,
  saveUserSuccess,
  uploadUserDocumentSuccess,
  getUserProgramsListingSuccess,
  onFetchForCustomerAttributeSuccess,
  getExcludedFacilitiesSuccess,
} from '@actions/uhe/configuration/users/UsersActions';
import {
  ACTIVATE_USER_REQUEST,
  CHANGE_USER_PASSWORD,
  CONFIGURATION_USERS_FETCH_ACCESS_DATA,
  CONFIGURATION_USERS_FETCH_DATA,
  DELETE_USERS_REQUEST,
  FETCH_DETAILED_REPORT_REQUEST,
  FETCH_IMPERSONATE_TOKEN,
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_UPLOAD_LOGS_REQUEST,
  FETCH_USER_GRANT_REQUEST,
  FETCH_USER_REQUEST,
  RESET_USER_PASSWORD,
  SAVE_PERMISSIONS_REQUEST,
  SAVE_USER_REQUEST,
  SAVE_USERS_GRANTS_REQUEST,
  UPLOAD_USER_DOCUMENT_REQUEST,
  USER_PROGRAMS_LISTING_REQUEST,
  FETCH_FOR_CUSTOMER_ATTRIBUTE_REQUEST,
  EXCLUDED_FACILITIES_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import RestManager from '@util/RestManager';
import { ACCESS_TOKEN_KEY, IMPERSONATE_TOKEN } from '@constants/UHESettings';
import { whichPageIsRendered } from '@util/UheHelper';

/**
 * getDetailedReport
 * @param {string} id id
 * @returns {object} object
 */
const getDetailedReport = async (id) => {
  const isPrograms = whichPageIsRendered('programs');
  const detailedReport = isPrograms ? `${ENDPOINTS.programs.uploadLogDetailedReport}/${id}` : `${ENDPOINTS.users.uploadLogDetailedReport}/${id}`;
  try {
    const response = await RestManager.requestFile(detailedReport);
    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Depending on URL sends GET request either to /users/bulk/upload/log?users=false ||
 * /users/bulk/upload/log?users=true
 * @returns {object} data
 */
const getUploadLogs = async () => {
  const isUsers = window.location.pathname.indexOf('/users') !== -1;
  const isPrograms = window.location.pathname.indexOf('/programs') !== -1;
  /**
 * whichLogsToGet
 * @returns {string} endpoint to hit
 */
  const whichLogsToGet = () => {
    let uploadLogs;
    if (isUsers) {
      uploadLogs = ENDPOINTS.users.uploadLogUsers;
    } else if (isPrograms) {
      uploadLogs = ENDPOINTS.programs.uploadLogPrograms;
    } else {
      uploadLogs = ENDPOINTS.users.uploadLogMobileDevices;
    }
    return uploadLogs;
  };
  try {
    const response = await RestManager.request(whichLogsToGet());
    return response;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Request data
 * @param  {number} page page
 * @param  {string} sorting sorting
 * @param  {string} filter filter
 * @return {Object} data
 */
const doFetchData = async (page, sorting, filter) => {
  const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
  return await RestManager.request(
    `${ENDPOINTS.configuration.UsersTable}?page=${
      page || 0
    }${sortingQueryString}${filterQueryString}`,
  );
};

/**
 * Request Permission Data
 * @param {number} id id
 * @return {Object} response
 */
const doFetchEditData = async (id) => await RestManager.request(
  `${ENDPOINTS.configuration.DeleteUser}/${id}/permissions`,
);

/**
 * Save request for Permission data
 * @param {number} id id
 * @param {Object} data data
 * @return {Object} response
 */
const doSavePermissions = async (id, data) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.configuration.DeleteUser}/${id}/permissions`,
  'POST',
  data,
);

/**
 * Sends GET Request to /users/:id/impersonate
 * @param {number} id id
 * @returns {Object} response
 */
const getImpersonateToken = async (id) => await RestManager.request(
  `${ENDPOINTS.configuration.UsersTable}/${id}/impersonate`,
);

/**
 * Handles Response from /users/:id/impersonate
 * @param {Object} action action
 * @returns {void}
 */
function* doFetchImpersonateToken(action) {
  try {
    const token = yield call(getImpersonateToken, action.id);
    if (token) {
      yield put(fetchImpersonateTokenSuccess(token));
      localStorage.setItem(IMPERSONATE_TOKEN, token.auth_token);
      window.location.reload();
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * Save permission
 * @param {Object} data data
 * @returns {void}
 */
function* saveUserPermissions(data) {
  try {
    const user = yield call(
      doSavePermissions,
      data.payload.id,
      data.payload.body,
    );
    if (user) {
      yield put(savePermissionsSuccess(user));
      yield put(showMessage('save_success'));
    }
    const fetchedUser = yield call(doFetchUserById, data.payload.id);
    yield put(fetchUserSuccess(fetchedUser));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 *  Delete request
 * @param {string} id id
 * @return  {Promise} promise
 */
const deleteUserAction = async (id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.configuration.DeleteUser}/${id}`,
  'DELETE',
);

/**
 * Request filtering users table data
 * @param {number} page page
 * @param {Array} sorting sorting
 * @param {string} filter filter
 * @return {Objects} actions
 */
function* fetchTableData({ page, sorting, filter }) {
  try {
    const fetchedData = yield call(doFetchData, page, sorting, filter);
    yield put(fetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchEditTableData
 * @param {string} id id
 * @returns {object} action
 */
function* fetchEditTableData({ id }) {
  try {
    const fetchedData = yield call(doFetchEditData, id);
    yield put(fetchUserAccessDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sends POST Request
 * @param {string} user user
 * @param {string} id id
 * @returns {Array} response
 */
const saveUserById = async (user, id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.users.saveUser}/${id}`,
  'POST',
  user,
);

/**
 * Delete user by given id
 * @param {string} data data
 * @return {void}
 */
function* delUsers(data) {
  try {
    const deletedUser = yield call(deleteUserAction, data.payload.id);
    if (deletedUser && deletedUser.status >= 200 && deletedUser.status < 300) {
      yield call(fetchTableData, {
        sorting: data.payload.sorting,
        page: data.payload.page,
        filter: data.payload.filter,
      });
      yield put(showMessage('userDeleted'));
    } else {
      console.error('DELETE ERROR: ', deletedUser);
      yield put(fetchError(deletedUser));
    }
  } catch (error) {
    console.error('DELETE ERROR: ', error);
    yield put(fetchError(error));
  }
}

/**
 * Request for add new user
 * @param  {Object} bodyData bodyData
 * @return {Object} response
 */
const addNew = async (bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.users.saveUser}`,
  'POST',
  bodyData,
);

/**
 * Save current user programs
 * @param {number} userId Selected User's id
 * @param {Array} updatedPrograms Updated programs array
 * @returns {Promise<*>} Promise
 */
const saveUserProgramsById = async (userId, bodyData) => await RestManager
  .requestWithoutQueryParams(
    `${ENDPOINTS.userPrograms.assignablePrograms(+userId)}`,
    'POST',
    bodyData,
  );

/**
 * Save user
 * @param {Object} data data
 * @returns {void}
 */
function* saveUser(data) {
  try {
    if (data.payload.user.id) {
      if (data.payload.shouldSaveUserDetails) {
        const trimmedData = Object.entries(data.payload.user)
          .filter(([key]) => !(key.startsWith('is_') || key === 'id'))
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        if (!data.payload.shouldEditTechAuthorized) {
          delete trimmedData['tech_authorized'];
        }
        const user = yield call(saveUserById, trimmedData, data.payload.user.id);
        const userPrograms = yield call(saveUserProgramsById(data.payload.user.id, data.payload.userPrograms));
        if (user && userPrograms) {
          user.id = data.payload.user.id;
          user.saved = true;

          yield put(fetchUserSuccess(user));
          yield put(showMessage('save_success'));
        }
      }
      if (data.payload.hasChangedOrganization) {
        yield put(fetchUserGrant({ id: data.payload.user.id }));
      } else {
        yield put(saveUserGrantsRequest(data.payload.grants));
      }
    } else {
      const userId = yield call(addNew, data.payload.user);

      if (userId) {
        if (data.payload.user.thirdpartyauth === true) {
          yield put(saveUserSuccess(userId));
          yield put(showMessage('userCreated'));
          yield put(showMessage('auth_success'));
        } else {
          yield put(saveUserSuccess(userId));
          yield put(showMessage('userCreated'));
          const fetchedData = yield call(doFetchEditData, userId.id);
          yield put(fetchUserAccessDataSuccess(fetchedData));
        }
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}

/**
 * Sends GET Request
 * @param {string} id id
 * @returns {Array} response
 */
const doFetchUserById = async (id) => await RestManager.request(`${ENDPOINTS.users.fetchAll}/${id}`);

/**
 * Sends GET Request
 * @param {string} id id
 * @returns {Array} response
 */
const doFetchOwnProfile = async (id) => await RestManager.request(`${ENDPOINTS.users.ownProfile}`);

/**
 * Sends GET Request
 * @param {string} id id
 * @returns {Array} response
 */
const doFetchOwnProfileFeatures = async (id) => await RestManager.request(`${ENDPOINTS.users.ownProfile}/features`);

/**
 * Fetching Facility by Id
 * @param {Object} id id
 * @returns {void}
 */
function* fetchUserById({ id }) {
  try {
    const fetchedUser = yield call(doFetchUserById, id);
    if (fetchedUser) {
      yield put(fetchUserSuccess({ ...fetchedUser, id }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Fetching own user profile
 * @param {Object} id id
 * @returns {void}
 */
function* fetchOwnProfile() {
  try {
    const profile = yield call(doFetchOwnProfile);
    const features = yield call(doFetchOwnProfileFeatures);
    if (profile) {
      yield put(onFetchOwnPofileSuccess({ ...profile, features }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sends POST Request to /authentication/invitation/accept
 * @param {Object} activation activation
 * @returns {Object} response
 */
const activateUserRequest = async (activation) => {
  const response = await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.users.activateUser}`,
    'POST',
    activation,
    [],
    true,
    false,
  );
  return response;
};

/**
 * Handles Activate User POST Request
 * @param {Object} userData userData
 * @returns {void}
 */
function* activateUser(userData) {
  try {
    const activationData = yield call(activateUserRequest, userData.activation);
    if (activationData) {
      yield put(showMessage('save_success'));
      yield put(activateUserSuccess(activationData));
      userData.activation.redirectOnAuth(ACCESS_TOKEN_KEY);
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Upload user document request
 * @param {Object} file file
 * @returns {Object} response
 */
const uploadUserDocumentRequest = async (file) => await RestManager.formDataRequest(
  `${ENDPOINTS.users.uploadUser}`,
  file,
);

/**
 * Handles Response and Request for uploading CSV file
 * @param {Object} payload payload
 * @returns {void}
 */
function* onUploadUserDocument(payload) {
  try {
    const uploadUserDocument = yield call(
      uploadUserDocumentRequest,
      payload.payload.file,
    );

    if (uploadUserDocument) {
      yield put(uploadUserDocumentSuccess());
      yield put(fetchUploadLogsRequest());
      yield put(showMessage('save_success'));
    }
  } catch (error) {
    yield put(fetchUploadLogsRequest());
    yield put(fetchError(error));
  }
}

/**
 * Request reset user's own password
 * @param {string} id id
 * @param  {Object} bodyData bodyData
 * @return {Object} response
 */
const reset = async (id, bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.password.resetPassword}/${id}/password/reset`,
  'POST',
  bodyData,
);

/**
 * Reset password
 * @param {Object} data data
 * @returns {void}
 */
function* resetPassword(data) {
  try {
    const password = yield call(reset, data.payload.id);
    if (password) {
      yield put(resetUserPasswordSuccess(password));
      yield put(showMessage('reset_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
    const fetchedData = yield call(doFetchData);
    yield put(fetchDataSuccess(fetchedData));
  }
}

/**
 * Request reset user's own password
 * @param {string} id id
 * @param  {Object} bodyData bodyData
 * @return {Object} response
 */
const change = async (id, bodyData) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.password.resetPassword}/${id}/password/change`,
  'POST',
  bodyData,
);

/**
 * Reset password
 * @param {Object} data data
 * @returns {void}
 */
function* changePassword(data) {
  try {
    const password = yield call(change, data.payload.id, data.payload.body);
    if (password) {
      yield put(changeUserPasswordSuccess(password));
      yield put(showMessage('change_success'));
    }
  } catch (error) {
    yield put(fetchError(error));
    const fetchedData = yield call(doFetchData);
    yield put(fetchDataSuccess(fetchedData));
  }
}

/**
 * Sends GET Request
 * @param {Number} id id
 * @returns {Array} response
 */
const doFetchUserGrant = async (id) => await RestManager.request(`${ENDPOINTS.users.saveUser}/${id}/grants`);

/**
 * fetchUserGrantData
 * @param {object} data data
 * @returns {object} action
 */
function* fetchUserGrantData(data) {
  try {
    const fetchedData = yield call(doFetchUserGrant, data.id);
    yield put(fetchUserGrantSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Handles Upload Log Data
 * @returns {void}
 */
function* fetchUploadLogs() {
  try {
    const fetchedUploadLogs = yield call(getUploadLogs);
    if (fetchedUploadLogs) {
      yield put(fetchUploadLogsSuccess(fetchedUploadLogs));
    } else {
      yield put(fetchUploadLogsRequest());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sends POST Request
 * @param {Object} user user
 * @param {Number} id id
 * @returns {Array} response
 */
const saveUserGrantsById = async (user, id) => await RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.users.saveUser}/${id}/grants`,
  'POST',
  user,
);

/**
 * Save user grants
 * @param {Object} data data
 * @returns {void}
 */
function* saveUserGrants(data) {
  try {
    if (data.payload.id) {
      const toSave = {
        ...data.payload.updBody,
        id: undefined,
      };
      const user = yield call(saveUserGrantsById, toSave, data.payload.id);
      if (user) {
        yield put(saveUserGrantsSuccess(user));
        yield put(fetchUser({ id: data.payload.id }));
        yield put(showMessage('userDetailsUpdated'));
      }
    }
  } catch (error) {
    yield put(fetchError(error));
    yield put(clearLoading());
  }
}
/**
 * Handles Detailed Report File Download
 * @param {object} action action
 * @param {object} action.payload action payload
 * @returns {void}
 */
function* fetchDetailedReport({ payload }) {
  try {
    const fetchedDetailedReport = yield call(getDetailedReport, payload.id);
    if (fetchedDetailedReport) {
      const url = URL.createObjectURL(fetchedDetailedReport);
      const anchorTag = document.createElement('a');
      document.body.appendChild(anchorTag);
      anchorTag.style = 'display: none';
      anchorTag.href = url;
      anchorTag.download = `detailed_report_${payload.name.replace('.csv', '.txt')}`;
      anchorTag.click();
      document.body.removeChild(anchorTag);
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * User Programs Listing Request
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @param {Number} size pageSize
 * @param {string} userId Current user's Id
 * @returns {object} Response
 */
const userProgramsListRequest = async (page, sorting, filter, size, userId) => {
  const filterQueryString = filter?.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting?.length ? `&sort=${sorting.join('&sort=')}` : '';

  const request = await RestManager.request(`${ENDPOINTS.userPrograms.assignablePrograms(userId)}?page=${page || 0}${sortingQueryString}${filterQueryString}&size=${size}`);
  return request;
};

/**
 * getUserProgramsList Saga Worker
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @param {Number} size pageSize
 * @param {string} userId Current user's Id
 * @returns {void}
 */
function* getUserProgramsList({
  page, sorting, filter, size, userId,
}) {
  try {
    const response = yield call(userProgramsListRequest, page, sorting, filter, size, userId);
    yield put(getUserProgramsListingSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sends GET Request
 * @returns {boolean} true||false
 */
const getCustomerAttribute = async () => await RestManager.request(`${ENDPOINTS.users.customerAttribute}`);

/**
  * Fetching Customer attribute
  * @returns {void}
  */
function* fetchCustomerAttr() {
  try {
    const fetchedCustomerAttr = yield call(getCustomerAttribute);
    yield put(onFetchForCustomerAttributeSuccess(fetchedCustomerAttr));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Excluded facilities Listing Request
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @param {Number} size pageSize
 * @param {string} programId Current program Id
 * @returns {object} Response
 */
const excludedFacilitiesListRequest = async (page, sorting, filter, size, programId) => {
  const filterQueryString = filter?.length ? `&${filter.join('&')}` : '';
  const sortingQueryString = sorting?.length ? `&sort=${sorting.join('&sort=')}` : '';

  const request = await RestManager.request(`${ENDPOINTS.programs.endpoint}/${programId}/facilities?page=${page || 0}${sortingQueryString}${filterQueryString}&size=${size}`);
  return request;
};

/**
 * getExcludedFacilitiesList Saga Worker
 * @param {number} page Page Number
 * @param {string} sorting Sorting String
 * @param {string} filter Filter String
 * @param {Number} size pageSize
 * @param {string} programId Current program's Id
 * @returns {void}
 */
function* getExcludedFacilitiesList({
  page, sorting, filter, size, programId,
}) {
  try {
    const response = yield call(excludedFacilitiesListRequest, page, sorting, filter, size, programId);
    yield put(getExcludedFacilitiesSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_OWN_PROFILE_REQUEST, fetchOwnProfile);
  yield takeEvery(CONFIGURATION_USERS_FETCH_DATA, fetchTableData);
  yield takeEvery(DELETE_USERS_REQUEST, delUsers);
  yield takeEvery(SAVE_USER_REQUEST, saveUser);
  yield takeEvery(FETCH_USER_REQUEST, fetchUserById);
  yield takeEvery(CONFIGURATION_USERS_FETCH_ACCESS_DATA, fetchEditTableData);
  yield takeEvery(SAVE_PERMISSIONS_REQUEST, saveUserPermissions);
  yield takeEvery(FETCH_IMPERSONATE_TOKEN, doFetchImpersonateToken);
  yield takeEvery(ACTIVATE_USER_REQUEST, activateUser);
  yield takeEvery(UPLOAD_USER_DOCUMENT_REQUEST, onUploadUserDocument);
  yield takeEvery(RESET_USER_PASSWORD, resetPassword);
  yield takeEvery(CHANGE_USER_PASSWORD, changePassword);
  yield takeEvery(FETCH_USER_GRANT_REQUEST, fetchUserGrantData);
  yield takeEvery(SAVE_USERS_GRANTS_REQUEST, saveUserGrants);
  yield takeEvery(FETCH_UPLOAD_LOGS_REQUEST, fetchUploadLogs);
  yield takeEvery(FETCH_DETAILED_REPORT_REQUEST, fetchDetailedReport);
  yield takeEvery(USER_PROGRAMS_LISTING_REQUEST, getUserProgramsList);
  yield takeEvery(FETCH_FOR_CUSTOMER_ATTRIBUTE_REQUEST, fetchCustomerAttr);
  yield takeEvery(EXCLUDED_FACILITIES_REQUEST, getExcludedFacilitiesList);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
