import React from 'react';
import {
  Table,
} from 'antd';
import PropTypes from 'prop-types';

/**
 * UserProgramsTable Component
 * @param {Object} props props
 * @returns {JSX.Element} JSX
 */
function UserProgramsTable({
  programsLoading,
  getProgramsDataSource,
  getProgramsColumns,
  rowSelection,
}) {
  return (
    <Table
      dataSource={getProgramsDataSource}
      columns={getProgramsColumns}
      className="userPrograms"
      loading={programsLoading}
      rowSelection={rowSelection}
    />
  );
}

UserProgramsTable.propTypes = {
  programsLoading: PropTypes.bool.isRequired,
  getProgramsDataSource: PropTypes.func.isRequired,
  getProgramsColumns: PropTypes.func.isRequired,
  rowSelection: PropTypes.shape().isRequired,
};

export default UserProgramsTable;
