import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  onNavStyleChange,
  toggleCollapsedSideNav
} from 'appRedux/actions/Setting';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from '@constants/ThemeSetting';

const SidebarLogo = () => {
  const dispatch = useDispatch();
  const { width, themeType, navCollapsed } = useSelector(
    ({ settings }) => settings
  );
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">

      <Link to="uhe-units" className="gx-site-logo gx-mr-5">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ? (
          <img alt="Logo" src={require('assets/images/c-logo.png')} />
        ) : themeType === THEME_TYPE_LITE ? (
          <img alt="Logo" src={require('assets/images/c-logo.png')} />
        ) : (
          <img alt="Logo" src={require('assets/images/c-logo.png')} />
        )}
      </Link>
    </div>
  );
};

export default SidebarLogo;
