import React, { useEffect, useState } from 'react';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import RestManager from '@util/RestManager';
import { Col, Collapse, Row } from 'antd';
import SubsectionTables from '@routes/System/Settings/SubsectionTables';

const { Panel } = Collapse;

/**
 * Subsection Functional Component
 * @param {object} props component props
 * @returns {JSX} render Subsection
 */
const Subsection = (props) => {
  const {
    id, renderDeleteVideoButton, downloadFile, renderDeleteDocumentButton, loading,
  } = props;
  const [test, setTest] = useState('');
  const [searchType, setSearchType] = useState(null);

  const alwaysFullWidth = {
    lg: 24, md: 24, sm: 24, xs: 24,
  };
  useEffect(() => {
    /**
     * Handle fetch
     * @param {number} parentId parent_id
     * @returns {object} data
     */
    const fetchData = async (parentId) => {
      const request = await RestManager.request(
        `${ENDPOINTS.support.subsectionSupport}${parentId}`,
      );
      const requestBody = await { ...request, parent_id: parentId };
      setTest(await requestBody);
    };
    fetchData(id);
  }, []);
  return (
    <>
      {test
    && (
    <Collapse className="section-titles" accordion defaultActiveKey={[test.list[0].name.indexOf]}>
      <Panel className="section-titles" header={test.list[0].name} key={test.list[0].name.indexOf}>
        <Row {...alwaysFullWidth} className="gx-d-flex gx-align-items-center" key={test.list[0].id} gutter={16}>
          <Col {...alwaysFullWidth}>
            <SubsectionTables
              key={test.list[0].name.indexOf}
              defaultActiveKey={[test.list[0].name.indexOf]}
              id={test.list[0].id}
              renderDeleteVideoButton={renderDeleteVideoButton}
              downloadFile={downloadFile}
              renderDeleteDocumentButton={renderDeleteDocumentButton}
              loading={loading}
              searchType={searchType}
              setSearchType={setSearchType}
            />
          </Col>
        </Row>
      </Panel>
    </Collapse>
    )}
    </>
  );
};

export default Subsection;
