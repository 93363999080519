import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ColorPicker from '@components/ColorPicker/ColorPicker';
import IntlMessages from 'util/IntlMessages';
import {
  Card, Col, Row, Upload,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import RestManager from '@util/RestManager';

/**
 * Render Branding card for edit/add
 * @returns {JSX}
 */
class Branding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fileChange = this.fileChange.bind(this);
    this.beforeUpload = this.beforeUpload.bind(this);
  }

  componentDidMount() {
    this.setState({
      imageUrl: this.props.branding.attachment ? `${this.props.iconUrl}/${this.props.match.params.id}/branding/icon?token=${RestManager.getAuthToken()}` : null,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.branding !== this.props.branding ||
      prevProps.iconUrl !== this.props.iconUrl
    ) {
      this.setState({
        imageUrl: this.props.branding.attachment ? `${this.props.iconUrl}/${this.props.match.params.id}/branding/icon?token=${RestManager.getAuthToken()}` : null,
      });
    }
  }

  componentWillUnmount() {
    this.props.branding.attachment = null;
  }

  /**
   * @description Render color pickers section
   *
   * @returns {ReactElement}
   */
  renderColorPickers() {
    const { branding, onChangeHandler, disabled } = this.props;

    return (
      <div>
        <Row className="baseLineAlignedItems" gutter={16}>
          <Col lg={11} md={12} sm={24} xs={24}>
            <div><IntlMessages id="configuration.customer.header_color" /></div>
          </Col>
          <Col lg={12} md={12} sm={22} xs={22} className="colorPickerWrapper">
            <Row className="baseLineAlignedItems" gutter={16}>
              <Col lg={14} md={12} sm={24} xs={24}>
                {!branding.banner_color ? <IntlMessages id="configuration.customer.please_select" /> : branding.banner_color}
              </Col>
              <Col lg={8} md={9} sm={24} xs={24}>
                <ColorPicker
                  color={branding.banner_color || ''}
                  onChange={(color) => onChangeHandler('banner_color', { val: color })}
                  type="chrome"
                  small
                  disabled={disabled}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="baseLineAlignedItems" gutter={16}>
          <Col lg={11} md={12} sm={24} xs={24}>
            <div><IntlMessages id="configuration.customer.header_text_color" /></div>
          </Col>
          <Col lg={12} md={12} sm={22} xs={22} className="colorPickerWrapper">
            <Row className="baseLineAlignedItems" gutter={16}>
              <Col lg={14} md={12} sm={24} xs={24}>
                {!branding.font_color ? <IntlMessages id="configuration.customer.please_select" /> : branding.font_color}
              </Col>
              <Col lg={8} md={10} sm={24} xs={24}>
                <ColorPicker
                  color={branding.font_color || ''}
                  onChange={(color) => onChangeHandler('font_color', { val: color })}
                  type="chrome"
                  small
                  disabled={disabled}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Handle file change
   *
   * @param  {File} file
   * @return {bool}
   */
  beforeUpload(file) {
    this.fileChange(file);
    return false;
  }

  /**
   * Prepare Data URL to show to the user
   *
   * @param  {File}   img
   * @param  {Function} callback
   * @return {void}
   */
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  /**
   * Update the form on file change
   *
   * @param  {File} file
   * @return {void}
   */
  fileChange(file) {
    this.getBase64(file, imageUrl =>
      this.setState({
        imageUrl,
        loading: false,
      }),
    );
    const { onChangeHandler } = this.props;
    onChangeHandler('icon', { val: file })
  };

  /**
   * @description Render upload logo section
   *
   * @returns {ReactElement}
   */
  renderImageSection() {
    const { loading, imageUrl } = this.state;
    const { disabled } = this.props;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div>
        <Row className="baseLineAlignedItems" gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div><IntlMessages id="configuration.customer.image" /></div>
          </Col>
        </Row>
        <Row className="baseLineAlignedItems" gutter={16}>
          <Col lg={10} md={24} sm={24} xs={24}>
            <div className="uploadImgLabel"><IntlMessages id="configuration.customer.recommended_height" /></div>
            <div className="uploadImgLabel"><IntlMessages id="configuration.customer.jpg_png_only" /></div>
          </Col>
          <Col lg={14} md={24} sm={24} xs={24} className="">
            <Row className="baseLineAlignedItems" gutter={16}>
              <Col lg={2} md={2} sm={24} xs={24}> </Col>
              <Col lg={19} md={19} sm={24} xs={24}>
                <Upload
                  name="logo"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  accept=".jpg,jpeg,.png"
                  beforeUpload={this.beforeUpload}
                  disabled={disabled}
                >
                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { readyMap, isNew } = this.props;

    return (
      <Card
        className="gx-card customer-edit-branding-card"
        title={<IntlMessages id="configuration.customer.branding" />}
        loading={!isNew && !readyMap.customer}
      >
        {this.renderColorPickers()}
        {this.renderImageSection()}
      </Card>
    );
  }
}

Branding.defaultProps = {
  isNew: true,
  readyMap: {},
};

Branding.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  readyMap: PropTypes.shape({ customer: PropTypes.bool }).isRequired,
  isNew: PropTypes.bool,
};

export default withRouter(Branding);
