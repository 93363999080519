import {
  SYSTEM_DEVELOPER_FETCH_DATA,
  SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS,
} from "@constants/UHEActionTypes";

const INIT_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  },
};

/**
 * @param  {Object} state
 * @param  {string} action
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SYSTEM_DEVELOPER_FETCH_DATA:
      return Object.assign({}, state, { loading: true });
    case SYSTEM_DEVELOPER_FETCH_DATA_SUCCESS:
      const newState = { table: action.data, loading: false };
      return Object.assign({}, state, newState);
    default:
      return state;
  }
};
