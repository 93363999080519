import React from 'react';
import { Link } from 'react-router-dom';

const OrganizationCell = ({ content }) => {
  const { id, name } = content;

if(name == null) {
  return (
    <div className="uhe-table-td">
      <Link to={`/configuration/organizations/edit/${id}`} title={name}>
        <span>{name}</span>
      </Link>
    </div>
  );
} else {
    return (
    <div className="uhe-table-td">
      <Link to={`/configuration/organizations/edit/${id}`} title={name}>
        <span>{name}</span>
      </Link>
      <Link to={`/configuration/customers?topf_organization=${id}`} className="search-icon">
        <i className="icon icon-search-new" />
      </Link>
    </div>
  );
}
};

export default OrganizationCell;
