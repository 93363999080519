import React from 'react';
import { injectIntl } from 'react-intl';

const VersionCell = ({ content, latestVersion, intl }) => {
  let iconClass = 'icon-uncheck-squire';
  let title = intl.formatMessage({ id: 'uhe.table.versionUpToDate' });
  if (content && content < latestVersion) {
    iconClass = 'icon icon-circle';
    title = intl.formatMessage({ id: 'uhe.table.versionOutdated' });
  } else if (content && content > latestVersion) {
    iconClass = 'icon icon-triangle';
    title = intl.formatMessage({ id: 'uhe.table.versionAhead' });
  }
  return (
    <div className="uhe-table-td" title={title}>
      <span>{content}</span>
      <span className="version">{content && <i className={`icon ${iconClass}`} />}</span>
    </div>
  );
};

export default injectIntl(VersionCell);
