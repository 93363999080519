import {
  FETCH_SIGNIN_REPORTS_SUCCESSS,
  FETCH_SIGNIN_REPORTS_REQUEST,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  loading: true,
  table: {
    list: [],
    page: {},
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SIGNIN_REPORTS_REQUEST: {
      return { ...state, loading: true };
    }

    case FETCH_SIGNIN_REPORTS_SUCCESSS: {
      const newState = { table: action.data, loading: false };
      return Object.assign({}, state, newState);
    }

    default:
      return state;
  }
};
