import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import {
  Card, Col, Input, Row,
} from 'antd';

/**
 * @description Render Epic Interconnect API Settings card for edit/add
 * @param {object} customer
 * @param {function} onChangeHandler
 * @param {{ customer: boolean }} readyMap Container for state of loading elements
 * @param {boolean} isNew Switch which tells if the component is new
 * @returns {object}
 */
const EpicInterConnectApiSettings = ({
  customer, onChangeHandler, disabled, readyMap, isNew,
}) => {
  const sectionName = 'interconnect';
  return (
    <Card
      className="gx-card customer-edit-interconnect-card"
      title={<IntlMessages id="configuration.customer.epic_interconnect_api_settings" />}
      loading={!isNew && !readyMap.customer}
    >
      <Row className="baseLineAlignedItems" gutter={16}>
        <Col lg={8} md={8} sm={24} xs={24}>
          <div><IntlMessages id="configuration.customer.server" /></div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Input
            autoComplete={Math.random()}
            id="interconnectServer"
            name="name"
            type="text"
            defaultValue={customer.interconnect.server}
            value={customer.interconnect.server}
            onChange={(event) => onChangeHandler(event, 'server', sectionName)}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="baseLineAlignedItems" gutter={16}>
        <Col lg={8} md={8} sm={24} xs={24}>
          <div><IntlMessages id="configuration.customer.username" /></div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Input
            autoComplete={Math.random()}
            id="interconnectUsername"
            name="name"
            type="text"
            defaultValue={customer.interconnect.username}
            value={customer.interconnect.username}
            onChange={(event) => onChangeHandler(event, 'username', sectionName)}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="baseLineAlignedItems" gutter={16}>
        <Col lg={8} md={8} sm={24} xs={24}>
          <div><IntlMessages id="configuration.customer.password" /></div>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <Input
            autoComplete={Math.random()}
            id="interconnectPassword"
            name="name"
            type="text"
            defaultValue={customer.interconnect.password}
            value={customer.interconnect.password}
            onChange={(event) => onChangeHandler(event, 'password', sectionName)}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Card>
  );
};

EpicInterConnectApiSettings.defaultProps = {
  isNew: true,
};

EpicInterConnectApiSettings.propTypes = {
  customer: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  readyMap: PropTypes.shape({ customer: PropTypes.bool }).isRequired,
  isNew: PropTypes.bool,
};

export default EpicInterConnectApiSettings;
