import ReactivePermissionData, { LevelType } from './Models/ReactivePermissionData';
import ReactivePermission from './Models/ReactivePermission';
import ApiUserPermission from './Models/ApiUserPermission';

const toKey = (...args) => args.join('~');

const UserPermissionsUtils = {
  /**
   * @param {{}} apiData
   * @returns {[Object.<number, ReactivePermissionData>, {}]}
   */
  fromApiToReact(apiData = {}) {
    function getNode(id, type, parentNode) {
      const currentChilds = parentNode.nested;
      let foundElement = null;
      if (currentChilds) {
        foundElement = currentChilds.find((element) => element[type] === id);
      }

      return foundElement;
    }

    function generatePermissionsForNode(foundElement, parentElement) {
      if (!foundElement) {
        // PERMISSIONS FROM parentElement and artificial flag is true
        return new ReactivePermission({
          allowed: parentElement.allowed,
          granted: parentElement.granted,
          artificial: true,
        });
      }
      // RETURNS PERMISSIONS FROM foundElement and artificial flag is false
      return new ReactivePermission({
        allowed: foundElement.allowed,
        granted: parentElement.granted || foundElement.granted,
        artificial: false,
        inherited: parentElement.granted,
      });
    }

    function getPermissions(organizationId, customerId, facilityId, unitId, deviceId, deviceType) {
      const permissions = {};
      for (const [currentRoleName, currentRole] of Object.entries(apiData)) {
        const currentChilds = currentRole.nested;
        const foundElement = currentChilds
          ?.find((element) => element.organization === organizationId);

        let parentNode = currentRole;

        if (!customerId) {
          permissions[currentRoleName] = generatePermissionsForNode(foundElement, parentNode);
        } else {
          parentNode = foundElement || parentNode;
          const foundCustomer = getNode(customerId, 'customer', parentNode);
          if (!facilityId) {
            permissions[currentRoleName] = generatePermissionsForNode(foundCustomer, parentNode);
          } else {
            parentNode = foundCustomer || parentNode;
            const foundFacility = getNode(facilityId, 'facility', parentNode);
            if (!unitId) {
              permissions[currentRoleName] = generatePermissionsForNode(foundFacility, parentNode);
            } else {
              parentNode = foundFacility || parentNode;
              const foundUnit = getNode(unitId, 'unit', parentNode);
              if (!deviceId) {
                permissions[currentRoleName] = generatePermissionsForNode(foundUnit, parentNode);
              } else {
                parentNode = foundUnit || parentNode;

                let foundDevice = null;
                if (parentNode.nested) {
                  for (let i = 0; i < parentNode.nested.length; i++) {
                    foundDevice = getNode(deviceId, deviceType, parentNode.nested[i]);
                    if (foundDevice) {
                      break;
                    }
                  }
                }
                permissions[currentRoleName] = generatePermissionsForNode(foundDevice, parentNode);
              }
            }
          }
        }
      }

      return permissions;
    }

    return Object.keys(apiData)
      .reduce((acc, roleName) => {
        acc[1][roleName] = {
          allowed: apiData[roleName].allowed,
          granted: apiData[roleName].granted,
        };
        // eslint-disable-next-line no-unused-expressions
        apiData[roleName].nested?.forEach((organization) => {
          const organizationKey = toKey(LevelType.organization, organization.organization);
          if (!acc[0][organizationKey]) {
            acc[0][organizationKey] = new ReactivePermissionData({
              permissions: getPermissions(organization.organization),
              id: organization.organization,
              name: organization.name,
              children: {},
              discriminator: LevelType.organization,
            });
          }

          // eslint-disable-next-line no-unused-expressions
          organization.nested?.forEach((customer) => {
            const customerKey = toKey(LevelType.customer, customer.customer);
            if (!acc[0][organizationKey]
              .children[customerKey]) {
              acc[0][organizationKey]
                .children[customerKey] = new ReactivePermissionData({
                  permissions: getPermissions(organization.organization, customer.customer),
                  id: customer.customer,
                  name: customer.name,
                  children: {},
                  discriminator: LevelType.customer,
                });
            }

            // eslint-disable-next-line no-unused-expressions
            customer.nested?.forEach((facility) => {
              const facilityKey = toKey(LevelType.facility, facility.facility);
              if (!acc[0][organizationKey]
                .children[customerKey]
                .children[facilityKey]) {
                acc[0][organizationKey]
                  .children[customerKey]
                  .children[facilityKey] = new ReactivePermissionData({
                    permissions: getPermissions(
                      organization.organization,
                      customer.customer,
                      facility.facility,
                    ),
                    id: facility.facility,
                    name: facility.name,
                    children: {},
                    discriminator: LevelType.facility,
                  });
              }

              // eslint-disable-next-line no-unused-expressions
              facility.nested?.forEach((unit) => {
                const unitKey = toKey(LevelType.unit, unit.unit);
                if (!acc[0][organizationKey]
                  .children[customerKey]
                  .children[facilityKey]
                  .children[unitKey]) {
                  acc[0][organizationKey]
                    .children[customerKey]
                    .children[facilityKey]
                    .children[unitKey] = new ReactivePermissionData({
                      permissions: getPermissions(
                        organization.organization,
                        customer.customer,
                        facility.facility,
                        unit.unit,
                      ),
                      id: unit.unit,
                      name: unit.name,
                      children: {},
                      discriminator: LevelType.unit,
                    });
                }

                // eslint-disable-next-line no-unused-expressions
                unit.nested?.forEach((parent) => {
                  // eslint-disable-next-line no-unused-expressions
                  parent.nested?.forEach((device) => {
                    const id = device.device || device.user;
                    const deviceKey = toKey(LevelType.device, id, parent.type);
                    if (!acc[0][organizationKey]
                      .children[customerKey]
                      .children[facilityKey]
                      .children[unitKey]
                      .children[deviceKey]) {
                      acc[0][organizationKey]
                        .children[customerKey]
                        .children[facilityKey]
                        .children[unitKey]
                        .children[deviceKey] = new ReactivePermissionData({
                          permissions: getPermissions(
                            organization.organization,
                            customer.customer,
                            facility.facility,
                            unit.unit,
                            id,
                            device.device ? 'device' : 'user',
                          ),
                          id,
                          name: device.name,
                          type: parent.type,
                          discriminator: LevelType.device,
                        });
                    }
                  });
                });
              });
            });
          });
        });
        return acc;
      }, [{}, {}]);
  },
  /**
   * @param {Object.<string, ReactivePermissionData>} reactData
   * @param {{}} globalGrantsData
   * @returns {{}}
   */
  fromReactToApi(reactData = {}, globalGrantsData = {}) {
    const result = {};
    const roleNames = Object.keys(globalGrantsData);
    for (const organization of Object.values(reactData)) {
      for (const roleName of roleNames) {
        if (!result[roleName]) {
          result[roleName] = {};
        }

        const role = result[roleName];
        const permissionLevelOrganization = organization.permissions[roleName];
        role.allowed = globalGrantsData[roleName].allowed;
        role.granted = globalGrantsData[roleName].granted;

        if (permissionLevelOrganization.artificial) {
          continue;
        }

        if (!role.nested) {
          role.nested = [];
        }

        role.nested.push(new ApiUserPermission({
          name: organization.name,
          organization: organization.id,
          allowed: permissionLevelOrganization.allowed,
          granted: permissionLevelOrganization.granted,
          nested: Object.values(organization.children)
            .reduce((customers, customer) => {
              const permissionLevelCustomer = customer.permissions[roleName];
              if (permissionLevelCustomer.artificial) {
                return customers;
              }

              customers.push(new ApiUserPermission({
                name: customer.name,
                customer: customer.id,
                allowed: permissionLevelCustomer.allowed,
                granted: permissionLevelCustomer.granted,
                nested: Object.values(customer.children)
                  .reduce((facilities, facility) => {
                    const permissionLevelFacility = facility.permissions[roleName];
                    if (permissionLevelFacility.artificial) {
                      return facilities;
                    }

                    facilities.push(new ApiUserPermission({
                      name: facility.name,
                      facility: facility.id,
                      allowed: permissionLevelFacility.allowed,
                      granted: permissionLevelFacility.granted,
                      nested: Object.values(facility.children)
                        .reduce((units, unit) => {
                          const permissionLevelUnit = unit.permissions[roleName];
                          if (permissionLevelUnit.artificial) {
                            return units;
                          }

                          units.push(new ApiUserPermission({
                            name: unit.name,
                            unit: unit.id,
                            allowed: permissionLevelUnit.allowed,
                            granted: permissionLevelUnit.granted,
                            nested: [
                              new ApiUserPermission({
                                name: 'Devices',
                                allowed: false,
                                granted: false,
                                type: 'DEV',
                                nested: Object.values(unit.children)
                                  .filter((x) => x.type === 'DEV')
                                  .reduce((devices, device) => {
                                    const permissionsLevelDevice = device.permissions[roleName];
                                    if (permissionsLevelDevice.artificial) {
                                      return devices;
                                    }

                                    devices.push(new ApiUserPermission({
                                      name: device.name,
                                      device: device.id,
                                      allowed: permissionsLevelDevice.allowed,
                                      granted: permissionsLevelDevice.granted,
                                    }));

                                    return devices;
                                  }, []),
                              }),
                              new ApiUserPermission({
                                name: 'Mobile Devices',
                                allowed: false,
                                granted: false,
                                type: 'USR',
                                nested: Object.values(unit.children)
                                  .filter((x) => x.type === 'USR')
                                  .reduce((users, user) => {
                                    const permissionsLevelUser = user.permissions[roleName];
                                    if (permissionsLevelUser.artificial) {
                                      return users;
                                    }

                                    users.push(new ApiUserPermission({
                                      name: user.name,
                                      user: user.id,
                                      allowed: permissionsLevelUser.allowed,
                                      granted: permissionsLevelUser.granted,
                                    }));

                                    return users;
                                  }, []),
                              }),
                            ],
                          }));
                          return units;
                        }, []),
                    }));
                    return facilities;
                  }, []),
              }));
              return customers;
            }, []),
        }));
      }
    }

    return result;
  },
};

export default UserPermissionsUtils;
